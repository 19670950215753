package daw.component

import daw.html.toFixed
import kotlinx.html.canvas
import kotlinx.html.js.onMouseDownFunction
import kotlinx.html.js.onMouseMoveFunction
import kotlinx.html.js.onMouseOutFunction
import kotlinx.html.js.onMouseUpFunction
import kotlinx.html.js.onScrollFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent
import kotlin.browser.window
import kotlin.math.PI
import kotlin.math.max
import kotlin.math.min

/**
 * Created by rien on 10-9-16.
 */

class SliderComponent(
    var value: Double = 0.0,
    val railColor: String = "#4b4b4b",
    val sliderColor: String = "#fb4b4b",
    val backgroundColor: String = "#1a1a1a",
    val label: String = "",
    val minValue: Float = 0f,
    val maxValue: Float = 5f,
    val step: Float = 0.05f,
    val width: Int = 100,
    val height: Int = 20,
    val knobRadius: Int = 4,
    val callback: (value: Double) -> Unit = {}
) : Komponent() {
  var activated = false

  override fun render(consumer: KompConsumer) = consumer.canvas(classes = "notextselect") {
    attributes["data-dt"] = "volumeknob"
    attributes["data-label"] = label
    attributes["data-min-value"] = "$minValue"
    attributes["data-max-value"] = "$maxValue"
    attributes["data-step"] = "$step"
    attributes["data-value"] = "$value"

    attributes["data-rail-color"] = railColor
    attributes["data-slider-color"] = sliderColor
    attributes["data-background-color"] = backgroundColor

    attributes["data-knob-radius"] = "$knobRadius"
    attributes["data-activated"] = "false"

    width = "${this@SliderComponent.width}px"
    height = "${this@SliderComponent.height}px"

    onScrollFunction = {
      if (it is WheelEvent) {
        val volumeKnob = it.target

        if (volumeKnob is HTMLCanvasElement) {
          val delta = it.deltaY / 250.0

          value -= delta

          value = min(value, maxValue.toDouble())
          value = max(value, minValue.toDouble())

          this@SliderComponent.callback(value)

          render()

          it.preventDefault()
        }
      }
    }

    onMouseDownFunction = {
      if (it is MouseEvent) {
        activated = true
        setValueByMouseCoords(it, minValue, maxValue, knobRadius, this@SliderComponent.callback)
      }
    }

    onMouseMoveFunction = {
      if (it is MouseEvent) {
        if (activated && it.buttons == 1.toShort()) {
          setValueByMouseCoords(it, minValue, maxValue, knobRadius, this@SliderComponent.callback)
        }
      }
    }

    onMouseUpFunction = {
      if (it is MouseEvent) {
        activated = false
      }
    }

    onMouseOutFunction = {
      if (it is MouseEvent) {
        activated = false
      }
    }

    window.setTimeout({
      this@SliderComponent.render()
    })
  }

  private fun setValueByMouseCoords(it: MouseEvent,
                                    minValue: Float = 0f,
                                    maxValue: Float = 5f,
                                    knobRadius: Int = 4,
                                    callback: (value: Double) -> Unit) {
    val slider = it.target

    if (slider is HTMLCanvasElement) {
      var newValue = ((it.offsetX - knobRadius) / (slider.width - (knobRadius * 2.0)) * (maxValue - minValue)) + minValue

      newValue = min(newValue, maxValue.toDouble())
      newValue = max(newValue, minValue.toDouble())

      value = newValue

      callback(newValue)

      render()

      it.preventDefault()
    }
  }

  fun render() {
    val slider = this.element ?: throw IllegalStateException("Element is null in SliderComponent")

    if (slider is HTMLCanvasElement && slider.getAttribute("data-dt") != "volumeknob") {
      throw IllegalArgumentException("Given element is not a volume knob!")
    }

    val canvas = slider as HTMLCanvasElement

    val minValue = (slider.getAttribute("data-min-value")!!).toDouble()
    val maxValue = (slider.getAttribute("data-max-value")!!).toDouble()

    val knobRadius = (slider.getAttribute("data-knob-radius")!!).toDouble()

    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    val position = (value - minValue) / (maxValue - minValue)

    val width = canvas.width.toDouble()
    val height = canvas.height.toDouble()

    val backgroundColor = slider.getAttribute("data-background-color")
    val railColor = slider.getAttribute("data-rail-color")
    val sliderColor = slider.getAttribute("data-slider-color")

    val label = slider.getAttribute("data-label")

    ctx.fillStyle = backgroundColor
    ctx.fillRect(0.0, 0.0, width, height)

    ctx.fillStyle = railColor
    ctx.fillRect(0.0, height / 2.0 - knobRadius / 2.0, width, knobRadius)


    ctx.beginPath()
    ctx.strokeStyle = backgroundColor
    ctx.lineWidth = knobRadius
    ctx.arc(knobRadius, height / 2.0, knobRadius, PI * 0.5, PI * 1.5)
    ctx.arc(width - knobRadius, height / 2.0, knobRadius, PI * 1.5, PI * 0.5)
    ctx.stroke()

    ctx.beginPath()
    ctx.strokeStyle = railColor
    ctx.fillStyle = sliderColor
    ctx.lineWidth = 5.0
    ctx.arc((width - knobRadius * 2.0) * position + knobRadius, height / 2.0, knobRadius, PI * 0, PI * 2.0)
    ctx.fill()

    var text = value.toFloat().toFixed(2) //formatFloat(value.toFloat(), 2)

    if (label != null && label != "") {
      text = label + " " + text
    }

    ctx.font = "bold 14px Inconsolata"
    ctx.fillStyle = sliderColor
    val textWidth = ctx.measureText(text)
    ctx.fillText(text, (width / 2) - textWidth.width / 2.0, 10.0)
  }

  fun updateValue(panning: Float) {
    this.value = panning.toDouble()

    render()
  }
}

