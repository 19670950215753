package daw.note

/**
 * User: rnentjes
 * Date: 18-11-15
 * Time: 11:12
 */

fun getNoteKeyMap(octave: Int): Map<Int, Note> {
  val noteKeyMap = HashMap<Int, Note>()

  when (octave) {
    0 -> {
      noteKeyMap.put(90, Note.C0)
      noteKeyMap.put(83, Note.C0s)
      noteKeyMap.put(88, Note.D0)
      noteKeyMap.put(68, Note.D0s)
      noteKeyMap.put(67, Note.E0)
      noteKeyMap.put(86, Note.F0)
      noteKeyMap.put(71, Note.F0s)
      noteKeyMap.put(66, Note.G0)
      noteKeyMap.put(72, Note.G0s)
      noteKeyMap.put(78, Note.A0)
      noteKeyMap.put(74, Note.A0s)
      noteKeyMap.put(77, Note.B0)

      noteKeyMap.put(81, Note.C1)
      noteKeyMap.put(50, Note.C1s)
      noteKeyMap.put(87, Note.D1)
      noteKeyMap.put(51, Note.D1s)
      noteKeyMap.put(69, Note.E1)
      noteKeyMap.put(82, Note.F1)
      noteKeyMap.put(53, Note.F1s)
      noteKeyMap.put(84, Note.G1)
      noteKeyMap.put(54, Note.G1s)
      noteKeyMap.put(89, Note.A1)
      noteKeyMap.put(55, Note.A1s)
      noteKeyMap.put(85, Note.B1)

      noteKeyMap.put(73, Note.C2)
      noteKeyMap.put(57, Note.C2s)
      noteKeyMap.put(79, Note.D2)
      noteKeyMap.put(48, Note.D2s)
      noteKeyMap.put(80, Note.E2)
    }
    1 -> {
      noteKeyMap.put(90, Note.C1)
      noteKeyMap.put(83, Note.C1s)
      noteKeyMap.put(88, Note.D1)
      noteKeyMap.put(68, Note.D1s)
      noteKeyMap.put(67, Note.E1)
      noteKeyMap.put(86, Note.F1)
      noteKeyMap.put(71, Note.F1s)
      noteKeyMap.put(66, Note.G1)
      noteKeyMap.put(72, Note.G1s)
      noteKeyMap.put(78, Note.A1)
      noteKeyMap.put(74, Note.A1s)
      noteKeyMap.put(77, Note.B1)

      noteKeyMap.put(81, Note.C2)
      noteKeyMap.put(50, Note.C2s)
      noteKeyMap.put(87, Note.D2)
      noteKeyMap.put(51, Note.D2s)
      noteKeyMap.put(69, Note.E2)
      noteKeyMap.put(82, Note.F2)
      noteKeyMap.put(53, Note.F2s)
      noteKeyMap.put(84, Note.G2)
      noteKeyMap.put(54, Note.G2s)
      noteKeyMap.put(89, Note.A2)
      noteKeyMap.put(55, Note.A2s)
      noteKeyMap.put(85, Note.B2)

      noteKeyMap.put(73, Note.C3)
      noteKeyMap.put(57, Note.C3s)
      noteKeyMap.put(79, Note.D3)
      noteKeyMap.put(48, Note.D3s)
      noteKeyMap.put(80, Note.E3)
    }
    2 -> {
      noteKeyMap.put(90, Note.C2)
      noteKeyMap.put(83, Note.C2s)
      noteKeyMap.put(88, Note.D2)
      noteKeyMap.put(68, Note.D2s)
      noteKeyMap.put(67, Note.E2)
      noteKeyMap.put(86, Note.F2)
      noteKeyMap.put(71, Note.F2s)
      noteKeyMap.put(66, Note.G2)
      noteKeyMap.put(72, Note.G2s)
      noteKeyMap.put(78, Note.A2)
      noteKeyMap.put(74, Note.A2s)
      noteKeyMap.put(77, Note.B2)

      noteKeyMap.put(81, Note.C3)
      noteKeyMap.put(50, Note.C3s)
      noteKeyMap.put(87, Note.D3)
      noteKeyMap.put(51, Note.D3s)
      noteKeyMap.put(69, Note.E3)
      noteKeyMap.put(82, Note.F3)
      noteKeyMap.put(53, Note.F3s)
      noteKeyMap.put(84, Note.G3)
      noteKeyMap.put(54, Note.G3s)
      noteKeyMap.put(89, Note.A3)
      noteKeyMap.put(55, Note.A3s)
      noteKeyMap.put(85, Note.B3)

      noteKeyMap.put(73, Note.C4)
      noteKeyMap.put(57, Note.C4s)
      noteKeyMap.put(79, Note.D4)
      noteKeyMap.put(48, Note.D4s)
      noteKeyMap.put(80, Note.E4)
    }
    3 -> {
      noteKeyMap.put(90, Note.C3)
      noteKeyMap.put(83, Note.C3s)
      noteKeyMap.put(88, Note.D3)
      noteKeyMap.put(68, Note.D3s)
      noteKeyMap.put(67, Note.E3)
      noteKeyMap.put(86, Note.F3)
      noteKeyMap.put(71, Note.F3s)
      noteKeyMap.put(66, Note.G3)
      noteKeyMap.put(72, Note.G3s)
      noteKeyMap.put(78, Note.A3)
      noteKeyMap.put(74, Note.A3s)
      noteKeyMap.put(77, Note.B3)

      noteKeyMap.put(81, Note.C4)
      noteKeyMap.put(50, Note.C4s)
      noteKeyMap.put(87, Note.D4)
      noteKeyMap.put(51, Note.D4s)
      noteKeyMap.put(69, Note.E4)
      noteKeyMap.put(82, Note.F4)
      noteKeyMap.put(53, Note.F4s)
      noteKeyMap.put(84, Note.G4)
      noteKeyMap.put(54, Note.G4s)
      noteKeyMap.put(89, Note.A4)
      noteKeyMap.put(55, Note.A4s)
      noteKeyMap.put(85, Note.B4)

      noteKeyMap.put(73, Note.C5)
      noteKeyMap.put(57, Note.C5s)
      noteKeyMap.put(79, Note.D5)
      noteKeyMap.put(48, Note.D5s)
      noteKeyMap.put(80, Note.E5)
    }
    4 -> {
      noteKeyMap.put(90, Note.C4)
      noteKeyMap.put(83, Note.C4s)
      noteKeyMap.put(88, Note.D4)
      noteKeyMap.put(68, Note.D4s)
      noteKeyMap.put(67, Note.E4)
      noteKeyMap.put(86, Note.F4)
      noteKeyMap.put(71, Note.F4s)
      noteKeyMap.put(66, Note.G4)
      noteKeyMap.put(72, Note.G4s)
      noteKeyMap.put(78, Note.A4)
      noteKeyMap.put(74, Note.A4s)
      noteKeyMap.put(77, Note.B4)

      noteKeyMap.put(81, Note.C5)
      noteKeyMap.put(50, Note.C5s)
      noteKeyMap.put(87, Note.D5)
      noteKeyMap.put(51, Note.D5s)
      noteKeyMap.put(69, Note.E5)
      noteKeyMap.put(82, Note.F5)
      noteKeyMap.put(53, Note.F5s)
      noteKeyMap.put(84, Note.G5)
      noteKeyMap.put(54, Note.G5s)
      noteKeyMap.put(89, Note.A5)
      noteKeyMap.put(55, Note.A5s)
      noteKeyMap.put(85, Note.B5)

      noteKeyMap.put(73, Note.C6)
      noteKeyMap.put(57, Note.C6s)
      noteKeyMap.put(79, Note.D6)
      noteKeyMap.put(48, Note.D6s)
      noteKeyMap.put(80, Note.E6)
    }
    5 -> {
      noteKeyMap.put(90, Note.C5)
      noteKeyMap.put(83, Note.C5s)
      noteKeyMap.put(88, Note.D5)
      noteKeyMap.put(68, Note.D5s)
      noteKeyMap.put(67, Note.E5)
      noteKeyMap.put(86, Note.F5)
      noteKeyMap.put(71, Note.F5s)
      noteKeyMap.put(66, Note.G5)
      noteKeyMap.put(72, Note.G5s)
      noteKeyMap.put(78, Note.A5)
      noteKeyMap.put(74, Note.A5s)
      noteKeyMap.put(77, Note.B5)

      noteKeyMap.put(81, Note.C6)
      noteKeyMap.put(50, Note.C6s)
      noteKeyMap.put(87, Note.D6)
      noteKeyMap.put(51, Note.D6s)
      noteKeyMap.put(69, Note.E6)
      noteKeyMap.put(82, Note.F6)
      noteKeyMap.put(53, Note.F6s)
      noteKeyMap.put(84, Note.G6)
      noteKeyMap.put(54, Note.G6s)
      noteKeyMap.put(89, Note.A6)
      noteKeyMap.put(55, Note.A6s)
      noteKeyMap.put(85, Note.B6)

      noteKeyMap.put(73, Note.C7)
      noteKeyMap.put(57, Note.C7s)
      noteKeyMap.put(79, Note.D7)
      noteKeyMap.put(48, Note.D7s)
      noteKeyMap.put(80, Note.E7)
    }
    6 -> {
      noteKeyMap.put(90, Note.C6)
      noteKeyMap.put(83, Note.C6s)
      noteKeyMap.put(88, Note.D6)
      noteKeyMap.put(68, Note.D6s)
      noteKeyMap.put(67, Note.E6)
      noteKeyMap.put(86, Note.F6)
      noteKeyMap.put(71, Note.F6s)
      noteKeyMap.put(66, Note.G6)
      noteKeyMap.put(72, Note.G6s)
      noteKeyMap.put(78, Note.A6)
      noteKeyMap.put(74, Note.A6s)
      noteKeyMap.put(77, Note.B6)

      noteKeyMap.put(81, Note.C7)
      noteKeyMap.put(50, Note.C7s)
      noteKeyMap.put(87, Note.D7)
      noteKeyMap.put(51, Note.D7s)
      noteKeyMap.put(69, Note.E7)
      noteKeyMap.put(82, Note.F7)
      noteKeyMap.put(53, Note.F7s)
      noteKeyMap.put(84, Note.G7)
      noteKeyMap.put(54, Note.G7s)
      noteKeyMap.put(89, Note.A7)
      noteKeyMap.put(55, Note.A7s)
      noteKeyMap.put(85, Note.B7)

      noteKeyMap.put(73, Note.C8)
      noteKeyMap.put(57, Note.C8s)
      noteKeyMap.put(79, Note.D8)
      noteKeyMap.put(48, Note.D8s)
      noteKeyMap.put(80, Note.E8)
    }
    7 -> {
      noteKeyMap.put(90, Note.C7)
      noteKeyMap.put(83, Note.C7s)
      noteKeyMap.put(88, Note.D7)
      noteKeyMap.put(68, Note.D7s)
      noteKeyMap.put(67, Note.E7)
      noteKeyMap.put(86, Note.F7)
      noteKeyMap.put(71, Note.F7s)
      noteKeyMap.put(66, Note.G7)
      noteKeyMap.put(72, Note.G7s)
      noteKeyMap.put(78, Note.A7)
      noteKeyMap.put(74, Note.A7s)
      noteKeyMap.put(77, Note.B7)

      noteKeyMap.put(81, Note.C8)
      noteKeyMap.put(50, Note.C8s)
      noteKeyMap.put(87, Note.D8)
      noteKeyMap.put(51, Note.D8s)
      noteKeyMap.put(69, Note.E8)
      noteKeyMap.put(82, Note.F8)
      noteKeyMap.put(53, Note.F8s)
      noteKeyMap.put(84, Note.G8)
      noteKeyMap.put(54, Note.G8s)
      noteKeyMap.put(89, Note.A8)
      noteKeyMap.put(55, Note.A8s)
      noteKeyMap.put(85, Note.B8)
    }
    8 -> {
      noteKeyMap.put(90, Note.C8)
      noteKeyMap.put(83, Note.C8s)
      noteKeyMap.put(88, Note.D8)
      noteKeyMap.put(68, Note.D8s)
      noteKeyMap.put(67, Note.E8)
      noteKeyMap.put(86, Note.F8)
      noteKeyMap.put(71, Note.F8s)
      noteKeyMap.put(66, Note.G8)
      noteKeyMap.put(72, Note.G8s)
      noteKeyMap.put(78, Note.A8)
      noteKeyMap.put(74, Note.A8s)
      noteKeyMap.put(77, Note.B8)
    }
    else -> throw IllegalArgumentException("Unknown octave $octave");
  }

  noteKeyMap.put(35, Note.END)
  noteKeyMap.put(33, Note.UP)
  noteKeyMap.put(46, Note.NONE)
  noteKeyMap.put(32, Note.NONE)

  return noteKeyMap
}
