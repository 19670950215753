package daw.html

import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent
import kotlin.math.PI
import kotlin.math.max
import kotlin.math.min

/**
 * Created by rien on 10-9-16.
 */

object Slider {

  fun create(value: Float = 1f,
             railColor: String = "#4b4b4b",
             sliderColor: String = "#fb4b4b",
             backgroundColor: String = "#1a1a1a",
             label: String = "",
             minValue: Float = 0f,
             maxValue: Float = 5f,
             step: Float = 0.05f,
             width: Int = 100,
             height: Int = 20,
             knobRadius: Int = 4,
             callback: (value: Double) -> Unit = {}
  ): Element {
    val canvas = create("canvas") as HTMLCanvasElement

    canvas.cls("notextselect")

    canvas.attr("data-dt", "volumeknob")
    canvas.attr("data-label", label)
    canvas.attr("data-min-value", "$minValue")
    canvas.attr("data-max-value", "$maxValue")
    canvas.attr("data-step", "$step")
    canvas.attr("data-value", "$value")

    canvas.attr("data-rail-color", railColor)
    canvas.attr("data-slider-color", sliderColor)
    canvas.attr("data-background-color", backgroundColor)

    canvas.attr("data-knob-radius", "$knobRadius")
    canvas.attr("data-activated", "false")

    canvas.width = width
    canvas.height = height

    canvas.onscroll = {
      if (it is WheelEvent) {
        val volumeKnob = it.target

        if (volumeKnob is HTMLCanvasElement) {
          val delta = it.deltaY / 250.0

          var value = getValue(volumeKnob) - delta

          value = min(value, maxValue.toDouble())
          value = max(value, minValue.toDouble())

          volumeKnob.setAttribute("data-value", "$value")

          callback(value)

          render(volumeKnob)

          it.preventDefault()
        }
      }
    }

    canvas.onmousedown = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "true")
        setValueByMouseCoords(it, minValue, maxValue, knobRadius, callback)
      }
    }

    canvas.onmousemove = {
      if (it is MouseEvent) {
        if (canvas.getAttribute("data-activated") == "true" && it.buttons == 1.toShort()) {
          setValueByMouseCoords(it, minValue, maxValue, knobRadius, callback)
        }
      }
    }

    canvas.onmouseup = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "false")
      }
    }

    canvas.onmouseleave = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "false")
      }
    }

    render(canvas)

    return canvas
  }

  private fun setValueByMouseCoords(it: MouseEvent,
                                    minValue: Float = 0f,
                                    maxValue: Float = 5f,
                                    knobRadius: Int = 4,
                                    callback: (value: Double) -> Unit) {
    val slider = it.target

    if (slider is HTMLCanvasElement) {
      var newValue = ((it.offsetX - knobRadius) / (slider.width - (knobRadius * 2.0)) * (maxValue - minValue)) + minValue

      newValue = min(newValue, maxValue.toDouble())
      newValue = max(newValue, minValue.toDouble())

      slider.attr("data-value", "$newValue")

      callback(newValue.toDouble())

      render(slider)

      it.preventDefault()
    }
  }

  fun getValue(slider: Element): Double {
    return (slider.getAttribute("data-value") ?: throw IllegalStateException("data-value attribute not found on volume knob!")).toDouble()
  }

  fun render(slider: Element) {
    if (slider.getAttribute("data-dt") != "volumeknob") {
      throw IllegalArgumentException("Given element is not a volume knob!")
    }

    val canvas = slider as HTMLCanvasElement

    val minValue = (slider.getAttribute("data-min-value")!!).toDouble()
    val maxValue = (slider.getAttribute("data-max-value")!!).toDouble()

    val knobRadius = (slider.getAttribute("data-knob-radius")!!).toDouble()

    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    val value = getValue(slider)
    val position = (value - minValue) / (maxValue - minValue)

    val width = canvas.width.toDouble()
    val height = canvas.height.toDouble()

    val backgroundColor = slider.getAttribute("data-background-color")
    val railColor = slider.getAttribute("data-rail-color")
    val sliderColor = slider.getAttribute("data-slider-color")

    val label = slider.getAttribute("data-label")

    ctx.fillStyle = backgroundColor
    ctx.fillRect(0.0, 0.0, width, height)

    ctx.fillStyle = railColor
    ctx.fillRect(0.0, height / 2.0 - knobRadius / 2.0, width, knobRadius)


    ctx.beginPath()
    ctx.strokeStyle = backgroundColor
    ctx.lineWidth = knobRadius
    ctx.arc(knobRadius, height / 2.0, knobRadius, PI * 0.5, PI * 1.5)
    ctx.arc(width - knobRadius, height / 2.0, knobRadius, PI * 1.5, PI * 0.5)
    ctx.stroke()

    ctx.beginPath()
    ctx.strokeStyle = railColor
    ctx.fillStyle = sliderColor
    ctx.lineWidth = 5.0
    ctx.arc((width - knobRadius * 2.0) * position + knobRadius, height / 2.0, knobRadius, PI * 0, PI * 2.0)
    ctx.fill()

    var text = "${value.toFloat().toFixed(2)}" //formatFloat(value.toFloat(), 2)

    if (label != null && label != "") {
      text = label + " " + text
    }

    ctx.font = "bold 14px Inconsolata"
    ctx.fillStyle = sliderColor
    val textWidth = ctx.measureText(text)
    ctx.fillText(text, (width / 2) - textWidth.width / 2.0, 10.0)
  }
}
