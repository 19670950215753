package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * Created by rnentjes on 2-2-16.
 */

class SetPitch(var semiNotes: Float) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.setPitchSlide(semiNotes)
    player.cycleOffset = 0.0
  }

}

class PitchSlide(var semiNotes: Int, var lines: Int) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.setPitchSlide(semiNotes / lines.toFloat())
    //player.cycleOffset = 0.0
  }

  override fun toString(): String {
    return "PitchSlide(lines=$lines, semiNotes=$semiNotes)"
  }

}
