package daw.view.effect

import daw.component.Checkbox
import daw.component.FractionInput
import daw.input.TrackListInput
import daw.song.effects.SetVolume
import daw.view.PatternView
import daw.view.cell
import daw.view.row
import kotlinx.html.div
import kotlinx.html.style
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include

/**
 * Created by rnentjes on 6-3-17.
 */

class VolumeComponent : Komponent() {
  val title = EffectTitle("Set volume:") {
    updateVolumeAction()

    refresh()
  }
  var setVolume: SetVolume = SetVolume(1f, false)

  fun updateVolume(active: Boolean, volume: Float, retrigger: Boolean) {
    this.title.active = active
    this.setVolume.volume = volume
    this.setVolume.retrigger = retrigger
  }

  fun updateVolumeAction() {
    val effects = TrackListInput.getEffects()

    if (this.title.active) {
      effects?.setVolume = effects?.setVolume ?: SetVolume(1f)
      effects?.setVolume?.volume = setVolume.volume
      effects?.setVolume?.retrigger = setVolume.retrigger
    } else {
      effects?.setVolume = null
    }

    PatternView.updateEntry()
    TrackListInput.setSelectedTrackDirty()
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes = "bigtable") {

    include(this@VolumeComponent.title)

    if (this@VolumeComponent.title.active) {
      div(classes = "row") {
        div(classes = "cell") {
          +"Volume:"
        }
        div(classes = "cell")
        div(classes = "cell") {
          style = "float: right;"

          include(FractionInput(
              "effect_volume",
              value = setVolume.volume,
              decimals = 2,
              step = 0.05f,
              min = 0f,
              max = 5f,
              changeHandler = {
                setVolume.volume = it
                updateVolumeAction()
              }
          ))
        }
      }

      div(classes = "row") {
        div(classes = "cell") {
          +"Retrigger note:"
        }
        div(classes = "cell")
        div(classes = "cell") {
          style = "float: right;"

          include(Checkbox(
              "effect_volume_retrigger",
              checked = setVolume.retrigger) {
            setVolume.retrigger = it.checked
            updateVolumeAction()
          }
          )
        }
      }
    }

    row {
      cell {
        + " "
      }
    }
  }

}
