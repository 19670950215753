package daw.processor

import daw.AudioHandler
import kotlin.math.PI
import kotlin.math.sin

class ReverbProcessor(
    val delayInput: () -> Float = { 0f },
    val decayInput: () -> Float = { 0f },
    val speedInput: () -> Float = { 0f },
    val depthInput: () -> Float = { 0f }
) : PostProcessor {
  var samples: Int = 0
  var writer: Int = 0
  val bufferLength = AudioHandler.sampleRate * 2
  val leftBuffer: FloatArray = FloatArray(bufferLength)
  val rightBuffer: FloatArray = FloatArray(bufferLength)

  override fun process(left: FloatArray, right: FloatArray, count: Int) {
    var reader: Int = 0
    val samplesPerMilli = AudioHandler.sampleRate / 1000.0
    val speed = speedInput()
    val depth = depthInput()
    val decay = decayInput()
    val delay = delayInput()

    for (index in 0 until count) {
      val speedOffset = sin(speed * samples * PI / (AudioHandler.sampleRate / 2))
      reader = writer - (delay * samplesPerMilli + speedOffset * depth * samplesPerMilli).toInt()

      if (reader < 0) {
        reader += bufferLength
      }

      left[index] += leftBuffer[reader] * decay
      right[index] += rightBuffer[reader] * decay

      leftBuffer[writer] = left[index]
      rightBuffer[writer] = right[index]

      samples++
      writer++
      if (writer >= bufferLength) {
        writer -= bufferLength
      }
    }
  }

}
