package daw

import kotlin.browser.window

/**
 * Created by rnentjes on 19-1-16.
 */

object Settings {
  val defaultCanvasWidth = 400
  val defaultCanvasHeight = 240

  var displayTracksDuringPlayback = true

  val testClientId = "755b05d88a78f2501435"
  val prodClientId = "03dd0daa46242a9318e7"

  val testGoogleClientId = "632460913691-47ts8ns1aa1liceauipf0efamp5dvard.apps.googleusercontent.com"
  val prodGoogleClientId = "632460913691-9uf2efbgrt8lle528cd7mfr824c0mgj0.apps.googleusercontent.com"

  val testGoogleRedirectUri = "http://www.mto.com:1234/authorize/google"
  val prodGoogleRedirectUri = "https://www.music-tracker-online.com/authorize/google"

  val testFacebookAppId = "1448592405205463"
  val prodFacebookAppId = "1448553511876019"

  val testFacebookRedirectUri = "http://www.mto.com:1234/authorize/facebook"
  val prodFacebookRedirectUri = "https://www.music-tracker-online.com/authorize/facebook"

  fun getClientId(): String {
    if (window.location.hostname == "www.mto.com") {
      return testClientId
    } else {
      return prodClientId
    }
  }

  fun getGoogleClientId(): String {
    if (window.location.hostname == "www.mto.com") {
      return testGoogleClientId
    } else {
      return prodGoogleClientId
    }
  }

  fun getGoogleRedirectUri(): String {
    if (window.location.hostname == "www.mto.com") {
      return testGoogleRedirectUri
    } else {
      return prodGoogleRedirectUri
    }
  }

  fun getFacebookAppId(): String {
    if (window.location.hostname == "www.mto.com") {
      return testFacebookAppId
    } else {
      return prodFacebookAppId
    }
  }

  fun getFacebookRedirectUri(): String {
    if (window.location.hostname == "www.mto.com") {
      return testFacebookRedirectUri
    } else {
      return prodFacebookRedirectUri
    }
  }

}