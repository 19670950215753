package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * Created by rnentjes on 2-2-16.
 */

class PatternBreak(var newEntry: Int, var nextPattern: Boolean = true) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    songPlayer.jumpToEntry(newEntry, nextPattern)
  }

  override fun toString(): String {
    return "PatternBreak(newEntry=$newEntry, nextPattern=$nextPattern)"
  }

}
