package daw.note

import daw.html.Select

/**
 * User: rnentjes
 * Date: 14-11-15
 * Time: 11:50
 */

enum class Note(val description: String, val freq: Float) {
  C0("C-0", 16.35f),
  C0s("C#0", 17.32f),
  D0("D-0", 18.35f),
  D0s("D#0", 19.45f),
  E0("E-0", 20.60f),
  F0("F-0", 21.83f),
  F0s("F#0", 23.12f),
  G0("G-0", 24.50f),
  G0s("G#0", 25.96f),
  A0("A-0", 27.50f),
  A0s("A#0", 29.14f),
  B0("B-0", 30.87f),
  C1("C-1", 32.70f),
  C1s("C#1", 34.65f),
  D1("D-1", 36.71f),
  D1s("D#1", 38.89f),
  E1("E-1", 41.20f),
  F1("F-1", 43.65f),
  F1s("F#1", 46.25f),
  G1("G-1", 49.00f),
  G1s("G#1", 51.91f),
  A1("A-1", 55.00f),
  A1s("A#1", 58.27f),
  B1("B-1", 61.74f),
  C2("C-2", 65.41f),
  C2s("C#2", 69.30f),
  D2("D-2", 73.42f),
  D2s("D#2", 77.78f),
  E2("E-2", 82.41f),
  F2("F-2", 87.31f),
  F2s("F#2", 92.50f),
  G2("G-2", 98.00f),
  G2s("G#2", 103.83f),
  A2("A-2", 110.00f),
  A2s("A#2", 116.54f),
  B2("B-2", 123.47f),
  C3("C-3", 130.81f),
  C3s("C#3", 138.59f),
  D3("D-3", 146.83f),
  D3s("D#3", 155.56f),
  E3("E-3", 164.81f),
  F3("F-3", 174.61f),
  F3s("F#3", 185.00f),
  G3("G-3", 196.00f),
  G3s("G#3", 207.65f),
  A3("A-3", 220.00f),
  A3s("A#3", 233.08f),
  B3("B-3", 246.94f),
  C4("C-4", 261.63f),
  C4s("C#4", 277.18f),
  D4("D-4", 293.66f),
  D4s("D#4", 311.13f),
  E4("E-4", 329.63f),
  F4("F-4", 349.23f),
  F4s("F#4", 369.99f),
  G4("G-4", 392.00f),
  G4s("G#4", 415.30f),
  A4("A-4", 440.00f),
  A4s("A#4", 466.16f),
  B4("B-4", 493.88f),
  C5("C-5", 523.25f),
  C5s("C#5", 554.37f),
  D5("D-5", 587.33f),
  D5s("D#5", 622.25f),
  E5("E-5", 659.26f),
  F5("F-5", 698.46f),
  F5s("F#5", 739.99f),
  G5("G-5", 783.99f),
  G5s("G#5", 830.61f),
  A5("A-5", 880.00f),
  A5s("A#5", 932.33f),
  B5("B-5", 987.77f),
  C6("C-6", 1046.50f),
  C6s("C#6", 1108.73f),
  D6("D-6", 1174.66f),
  D6s("D#6", 1244.51f),
  E6("E-6", 1318.51f),
  F6("F-6", 1396.91f),
  F6s("F#6", 1479.98f),
  G6("G-6", 1567.98f),
  G6s("G#6", 1661.22f),
  A6("A-6", 1760.00f),
  A6s("A#6", 1864.66f),
  B6("B-6", 1975.53f),
  C7("C-7", 2093.00f),
  C7s("C#7", 2217.46f),
  D7("D-7", 2349.32f),
  D7s("D#7", 2489.02f),
  E7("E-7", 2637.02f),
  F7("F-7", 2793.83f),
  F7s("F#7", 2959.96f),
  G7("G-7", 3135.96f),
  G7s("G#7", 3322.44f),
  A7("A-7", 3520.00f),
  A7s("A#7", 3729.31f),
  B7("B-7", 3951.07f),
  C8("C-8", 4186.01f),
  C8s("C#8", 4434.92f),
  D8("D-8", 4698.64f),
  D8s("D#8", 4978.03f),
  E8("E-8", 2637.02f * 2f),
  F8("F-8", 2793.83f * 2f),
  F8s("F#8", 2959.96f * 2f),
  G8("G-8", 3135.96f * 2f),
  G8s("G#8", 3322.44f * 2f),
  A8("A-8", 3520.00f * 2f),
  A8s("A#8", 3729.31f * 2f),
  B8("B-8", 3951.07f * 2f),
  NONE("---", 1f),
  END("XXX", 1f),
  UP("^^^", 1f),
  ;

}

fun NoteSelect(): Array<Select> {
  val result = Array(Note.values().size, { Select("", "") })

  for (note in Note.values()) {
    result[note.ordinal] = Select(note.name, note.description)
  }

  return result
}