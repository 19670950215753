package daw.view

import daw.html.*
import nl.astraeus.komp.Komponent
import org.w3c.dom.HTMLElement
import kotlin.browser.document

/**
 * User: rnentjes
 * Date: 22-12-15
 * Time: 17:11
 */

object SongView {
  val component = SongComponent()

  fun renderSongInfo() {
    if (!hasElem("songinfo")) {
      main().with(div().id("songinfo"))
    }

    clear("songinfo")

    Komponent.create(document.getElementById("songinfo")!! as HTMLElement, component)
  }

  fun updateSongInfo() {
    component.refresh()
  }

}
