package daw.song

/**
 * User: rnentjes
 * Date: 29-6-16
 * Time: 15:21
 */

class UserData(val name: String, val email: String, val password: String)

class SongInfo(val title: String, val songId: String, val instruments: Int, val length: String, val lastUpdate: String)
