package daw.html

import daw.note.Note
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.events.MouseEvent

/**
 * Created by rien on 10-9-16.
 */

object NoteKeyboard {

  private val sharpToNoteMap = mapOf(
      1 to 1,
      2 to 3,
      4 to 6,
      5 to 8,
      6 to 10
  )

  private val noteToNoteMap = mapOf(
      0 to 0,
      1 to 2,
      2 to 4,
      3 to 5,
      4 to 7,
      5 to 9,
      6 to 11
  )

  private var noteNumberToNote = arrayOf(
      mapOf(
          0 to Note.C0,
          1 to Note.C0s,
          2 to Note.D0,
          3 to Note.D0s,
          4 to Note.E0,
          5 to Note.F0,
          6 to Note.F0s,
          7 to Note.G0,
          8 to Note.G0s,
          9 to Note.A0,
          10 to Note.A0s,
          11 to Note.B0
      ),
      mapOf(
          0 to Note.C1,
          1 to Note.C2s,
          2 to Note.D1,
          3 to Note.D1s,
          4 to Note.E1,
          5 to Note.F1,
          6 to Note.F1s,
          7 to Note.G1,
          8 to Note.G1s,
          9 to Note.A1,
          10 to Note.A1s,
          11 to Note.B1
      ),
      mapOf(
          0 to Note.C2,
          1 to Note.C2s,
          2 to Note.D2,
          3 to Note.D2s,
          4 to Note.E2,
          5 to Note.F2,
          6 to Note.F2s,
          7 to Note.G2,
          8 to Note.G2s,
          9 to Note.A2,
          10 to Note.A2s,
          11 to Note.B2
      ),
      mapOf(
          0 to Note.C3,
          1 to Note.C3s,
          2 to Note.D3,
          3 to Note.D3s,
          4 to Note.E3,
          5 to Note.F3,
          6 to Note.F3s,
          7 to Note.G3,
          8 to Note.G3s,
          9 to Note.A3,
          10 to Note.A3s,
          11 to Note.B3
      ),
      mapOf(
          0 to Note.C4,
          1 to Note.C4s,
          2 to Note.D4,
          3 to Note.D4s,
          4 to Note.E4,
          5 to Note.F4,
          6 to Note.F4s,
          7 to Note.G4,
          8 to Note.G4s,
          9 to Note.A4,
          10 to Note.A4s,
          11 to Note.B4
      ),
      mapOf(
          0 to Note.C5,
          1 to Note.C5s,
          2 to Note.D5,
          3 to Note.D5s,
          4 to Note.E5,
          5 to Note.F5,
          6 to Note.F5s,
          7 to Note.G5,
          8 to Note.G5s,
          9 to Note.A5,
          10 to Note.A5s,
          11 to Note.B5
      ),
      mapOf(
          0 to Note.C6,
          1 to Note.C6s,
          2 to Note.D6,
          3 to Note.D6s,
          4 to Note.E6,
          5 to Note.F6,
          6 to Note.F6s,
          7 to Note.G6,
          8 to Note.G6s,
          9 to Note.A6,
          10 to Note.A6s,
          11 to Note.B6
      ),
      mapOf(
          0 to Note.C7,
          1 to Note.C7s,
          2 to Note.D7,
          3 to Note.D7s,
          4 to Note.E7,
          5 to Note.F7,
          6 to Note.F7s,
          7 to Note.G7,
          8 to Note.G7s,
          9 to Note.A7,
          10 to Note.A7s,
          11 to Note.B7
      ),
      mapOf(
          0 to Note.C8,
          1 to Note.C8s,
          2 to Note.D8,
          3 to Note.D8s,
          4 to Note.E8,
          5 to Note.F8,
          6 to Note.F8s,
          7 to Note.G8,
          8 to Note.G8s,
          9 to Note.A8,
          10 to Note.A8s,
          11 to Note.B8
      )
  )

  fun create(width: Int = 270,
             height: Int = 40,
             textColor: String = "hsl(27, 50%, 60%)",
             startOctave: Int = 3,
             noteDownCallback: (note: Note) -> Unit = {},
             noteUpCallback: (note: Note) -> Unit = {}
  ): Element {
    var octave = startOctave
    val canvas = create("canvas") as HTMLCanvasElement

    canvas.cls("notextselect")

    canvas.attr("data-dt", "notekeyboard")

    canvas.attr("data-activated", "false")
    canvas.attr("data-notes", "")

    canvas.attr("data-text-color", textColor)
    canvas.attr("data-current-description", noteNumberToNote[octave][0]!!.description)

    canvas.width = width
    canvas.height = height

    canvas.onmousedown = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "true")

        val noteNumber = getNoteLocation(it)

        if (noteNumber >= 0 && noteNumber < 12) {
          noteDownCallback(noteNumberToNote[octave][noteNumber]!!)
        }

        canvas.attr("data-current-note", "$noteNumber")
        render(canvas)
      }
    }

    canvas.onmousemove = {
      if (it is MouseEvent) {
        if (canvas.getAttribute("data-activated") == "true" && it.buttons == 1.toShort()) {
          val noteNumber = getNoteLocation(it)

          val currentNoteStr = canvas.getAttribute("data-current-note")

          if (currentNoteStr != null) {
            val currentNote = currentNoteStr.toInt()

            if (currentNote != noteNumber && noteNumber >= 0 && noteNumber < 12) {
              noteUpCallback(noteNumberToNote[octave][currentNote]!!)
              noteDownCallback(noteNumberToNote[octave][noteNumber]!!)
            }

            canvas.attr("data-current-note", "$noteNumber")
            render(canvas)
          }
        }
      }
    }

    fun octaveUp() {
      if (octave < 8) {
        octave++
        canvas.attr("data-current-description", noteNumberToNote[octave][0]!!.description)
      }
    }

    fun octaveDown() {
      if (octave > 0) {
        octave--
        canvas.attr("data-current-description", noteNumberToNote[octave][0]!!.description)
      }
    }

    fun mouseUp() {
      canvas.attr("data-activated", "false")

      val currentNoteStr = canvas.getAttribute("data-current-note")

      if (currentNoteStr != null) {
        val currentNote = currentNoteStr.toInt()

        if (currentNote in 0..11) {
          noteUpCallback(noteNumberToNote[octave][currentNote]!!)
        } else if (currentNote == -1) {
          octaveDown()
        } else if (currentNote == 12) {
          octaveUp()
        }
      }

      canvas.removeAttribute("data-current-note")

      render(canvas)
    }

    canvas.onmouseleave = {
      if (it is MouseEvent) {
        mouseUp()
      }
    }

    canvas.onmouseup = {
      if (it is MouseEvent) {
        mouseUp()
      }
    }

    render(canvas)

    return canvas
  }

  fun render(noteKeyboard: Element) {
    if (noteKeyboard.getAttribute("data-dt") != "notekeyboard") {
      throw IllegalArgumentException("Given element is not a note keyboard!")
    }

    val canvas = noteKeyboard as HTMLCanvasElement
    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    var currentNote = -2

    val currentNoteStr = canvas.getAttribute("data-current-note")
    val currentNoteDescription = canvas.getAttribute("data-current-description") ?: "???"

    if (currentNoteStr != null) {
      currentNote = currentNoteStr.toInt()
    }

    val width = canvas.width.toDouble()
    val height = canvas.height.toDouble()

    val noteWidth = width / 9.0

    val textColor = noteKeyboard.getAttribute("data-text-color")

    for (index in 0..8) {
      if (noteToNoteMap[index] == currentNote) {
        ctx.fillStyle = "#d0d0d0"
      } else {
        ctx.fillStyle = "#f0f0f0"
      }
      ctx.fillRect(noteWidth * (index + 1), 0.0, noteWidth, height)
    }

    ctx.fillStyle = "#808080"

    for (index in 0..8) {
      ctx.fillRect(noteWidth * (index + 1), 0.0, 1.0, height)
    }

    for (index in arrayOf(1, 2, 4, 5, 6)) {
      if (sharpToNoteMap[index] == currentNote) {
        ctx.fillStyle = "#303030"
      } else {
        ctx.fillStyle = "#101010"
      }
      ctx.fillRect(noteWidth * (index + 1) - (noteWidth / 4), 0.0, noteWidth * 0.5, height * 0.66)
    }

    for (index in arrayOf(0, 8)) {
      if ((index == 0 && currentNote == -1) || (index == 8 && currentNote == 12)) {
        ctx.fillStyle = "#404040"
      } else {
        ctx.fillStyle = "#303030"
      }
      ctx.fillRect(noteWidth * index, 0.0, noteWidth, height)
    }

    ctx.font = "bold 18px Inconsolata"
    ctx.fillStyle = textColor
    var textWidth = ctx.measureText("<")
    ctx.fillText("<", (noteWidth / 2.0) - textWidth.width / 2.0, height / 2 + 10.0)
    ctx.fillText(">", width - (noteWidth / 2.0) - textWidth.width / 2.0, height / 2 + 10.0)

    ctx.font = "bold 16px Inconsolata"

    textWidth = ctx.measureText(currentNoteDescription)
    ctx.fillText(currentNoteDescription, noteWidth + noteWidth / 2.0 - textWidth.width / 2.0, height - 5.0)

  }

  /*
   * returns -1 to 12 for  < C - B >
   * -2 means not found
   */
  fun getNoteLocation(mouseEvent: MouseEvent): Int {
    val target = mouseEvent.target

    if (target is HTMLCanvasElement) {
      val width = target.width.toDouble()
      val height = target.height.toDouble()
      val noteWidth = width / 9.0

      val x = mouseEvent.offsetX
      val y = mouseEvent.offsetY

      if ((x / noteWidth - 1).toInt() > 6) {
        return 12
      }
      if (x < noteWidth) {
        return -1
      }

      if (x > 0 && x < width && y > 0 && y < height) {
        if (y < height * 0.66) {
          for (noteIndex in arrayOf(1, 2, 4, 5, 6)) {
            val noteStartX = (noteIndex + 1) * noteWidth - (noteWidth / 4.0)
            val noteStopX = noteStartX + (noteWidth / 2.0)

            if (x > noteStartX && x < noteStopX) {
              return sharpToNoteMap[noteIndex]!!
            }
          }
        }

        return noteToNoteMap[(x / noteWidth - 1).toInt()]!!
      }
    }

    return -2
  }
}
