package daw.view

import daw.Daw
import daw.component.KnobComponent
import daw.html.cls
import daw.html.formatFloat
import daw.html.txt
import daw.input.TrackListInput
import daw.instrument.InstrumentType
import daw.note.Note
import daw.song.Effects
import daw.song.InstrumentTrack
import daw.song.Track
import daw.ws.WebsocketConnection
import kotlinx.html.DIV
import kotlinx.html.classes
import kotlinx.html.div
import kotlinx.html.i
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.span
import kotlinx.html.style
import kotlinx.html.title
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.KompElement
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import kotlin.dom.addClass
import kotlin.dom.removeClass
import kotlin.math.log10
import kotlin.math.pow

class MuteButton(
    val ip: InstrumentTrack
): Komponent() {

  override fun render(consumer: KompConsumer): KompElement =consumer.div(classes = "row") {
      div(classes = "cell") {
        div(classes = "button") {
          if (ip.instrument.muted) {
            style = "background-color: hsl(0, 50%, 50%); color: hsl(27, 50%, 25%);"
            + "Unmute"
          } else {
            style = "background-color:  hsl(90, 50%, 50%); color: hsl(27, 50%, 25%);"
            + "Mute"
          }
          onClickFunction = {
            ip.instrument.muted = !ip.instrument.muted
            update()
          }
        }
      }
    }

}

class InstrumentPatternView(
    val ip: InstrumentTrack,
    val track: Track?,
    val maxPattern: String
) : Komponent() {
  val instrument = ip.instrument

  override fun render(consumer: KompConsumer): KompElement {
    var txtLength = 10 + ip.numberOfChannels * 4

    if (ip.instrument.type == InstrumentType.DRUMS) {
      txtLength = 5 + ip.numberOfChannels * 2
    }

    val patternNumber = track?.description() ?: "--"
    val patId = TrackListInput.getPatId(instrument.number)

    return consumer.div(classes = "instrumenttrack") {
      id = "instrumenttrack_${instrument.number}"

      val color = calculateColor(ip.instrument.number, Daw.song.instruments.size)
      style = if (ip.show) {
        "display: inline-block; border-top: 3px solid $color;"
      } else {
        "display: none; border-top: 3px solid $color;"
      }

      val channels = ip.numberOfChannels
      var tabindex = ip.instrument.number * ip.song.entriesPerPattern * 10

      instrumentName(txtLength)
      include(MuteButton(ip))

      div(classes = "row") {
        div(classes = "cell") {
          div(classes = "cell") {
            include(KnobComponent(
                label = "Volume",
                backgroundColor = "#292929",
                ringColor = "#343434",
                volumeColor = "#bf7940",
                width = 55,
                height = 70,
                value = ip.instrument.volume,
                minValue = 0f,
                maxValue = 5f,
                step = 0.05f,
                callback = { value ->
                  ip.instrument.volume = value.toFloat()
                  WebsocketConnection.addDirtyInstrument(ip.instrument)
                }
            ))
          }
          div(classes = "cell") {
            include(KnobComponent(
                label = "Pan",
                backgroundColor = "#292929",
                ringColor = "#343434",
                volumeColor = "#bf7940",
                width = 55,
                height = 70,
                value = ip.instrument.panning,
                minValue = -1f,
                maxValue = 1f,
                step = 0.05f,
                callback = { value ->
                  ip.instrument.panning = value.toFloat()
                  WebsocketConnection.addDirtyInstrument(ip.instrument)
                }
            ))
          }
        }
      }

      patternNumber(patId, patternNumber)


      div(classes = "row") {
        div(classes = "row") {
          pbutton("▲", "Decrease pattern number for selected entry") {
            TrackListInput.trackUp(instrument.number)
          }
          pbutton("▼", "Decrease pattern number for selected entry") {
            TrackListInput.trackDown(instrument.number)
          }
          pbutton("⎘", "Copy this pattern and continue with copy") {
            TrackListInput.copyTrack(instrument.number)
          }
        }
      }

      for (y in 0 until ip.song.entriesPerPattern) {
        var evenOdd = "odd"
        var txt = ""

        if ((y / ip.song.linesPerBeat) % 2 == 0) {
          evenOdd = "even"
        }

        if (y < 9) {
          txt += "0"
        }
        txt += (y + 1).toString()

        div(classes = "row $evenOdd") {
          div(classes = "cell") {
            div(classes = "cell tcell") {
              +txt

            }
            if (ip.instrument.type == InstrumentType.DRUMS) {
              drumRow(channels, y, track)
            } else {
              val fx = track?.effects?.get(y)
              for (c in 0 until channels) {
                tabindex++

                div(classes = "cell tcell") {
                  id = TrackListInput.getId(instrument.number, y, c)
                  attributes["tabindex"] = tabindex.toString()

                  +(track?.notes?.get(c)?.get(y)?.description ?: "   ")

                  onClickFunction = {
                    TrackListInput.selectPosition(instrument.number, y, c)
                  }
                }
              }

              div(classes = "cell tcell") {
                volumeAndSlide(y, fx)
                panning(y, fx)
                pitch(y, fx)
                arpeggio(y, fx)
              }
            }
          }
        }
      }
    }
  }

  private fun DIV.arpeggio(y: Int, fx: Effects?) {
    span {
      id = TrackListInput.getId(instrument.number, y, 14)
      style = "color: #2f3ecc"

      if (fx?.arpeggio != null) {
        +"\uD834\uDD83 "
      } else {
        +"  "
      }
    }
  }

  private fun DIV.pitch(y: Int, fx: Effects?) {
    span {
      id = TrackListInput.getId(instrument.number, y, 13)
      style = "color: #ccca3d"

      if (fx?.pitchSlide != null) {
        when {
          fx.pitchSlide?.semiNotes ?: 0 < 0 -> {
            +"▲ "
          }
          fx.pitchSlide?.semiNotes ?: 0 > 0 -> {
            +"▼ "
          }
          else -> {
            +"▪ "
          }
        }
      } else {
        +"  "
      }
    }
  }

  private fun DIV.panning(y: Int, fx: Effects?) {
    span {
      id = TrackListInput.getId(instrument.number, y, 12)
      style = "color: #42cc41"

      if (fx?.panning != null) {
        when {
          fx.panning?.panning ?: 0f < 0f -> {
            +"◀ "
          }
          fx.panning?.panning ?: 0f > 0f -> {
            +"▶ "
          }
          else -> {
            +"▪ "
          }
        }
      } else {
        +"  "
      }
    }
  }

  private fun DIV.volumeAndSlide(y: Int, fx: Effects?) {
    span {
      id = TrackListInput.getId(instrument.number, y, 11)
      style = "color: #cc3e2f"

      if (fx?.setVolume != null) {
        +"▶ "
      } else {
        if (fx?.volumeSlide != null) {
          when {
            fx.volumeSlide?.amount ?: 0f > 0f -> {
              +"▲ "
            }
            fx.volumeSlide?.amount ?: 0f < 0f -> {
              +"▼ "
            }
            else -> {
              +"▪ "
            }
          }
        } else {
          +"  "
        }
      }
    }
  }

  private fun DIV.drumRow(channels: Int, y: Int, track: Track?) {
    for (c in 0 until channels) {
      div(classes = "cell tcell") {
        id = TrackListInput.getId(instrument.number, y, c)

        i(classes = "fa-square") {
          if (track != null) {
            classes += if (track.notes[c][y] == Note.C3) {
              "fas"
            } else {
              "far"
            }

            onClickFunction = {
              val target = it.target as? HTMLElement
              if (track.notes[c][y] == Note.C3) {
                track.notes[c][y] = Note.NONE
                target?.removeClass("fas")
                target?.addClass("far")
              } else {
                track.notes[c][y] = Note.C3
                target?.removeClass("far")
                target?.addClass("fas")
              }

              WebsocketConnection.addDirtyTrack(track)
            }
          }
        }
      }
    }
  }

  private fun DIV.patternNumber(patId: String, patternNumber: String?) {
    div(classes = "row") {
      div(classes = "row") {
        div(classes = "cell info") {
          id = patId

          +"$patternNumber/$maxPattern"
        }
      }
    }
  }

  private fun DIV.instrumentName(txtLength: Int) {
    div(classes = "row") {
      div(classes = "row") {
        div(classes = "cell info_overflow") {
          title = instrument.description()

          +instrument.description(txtLength)
        }
      }
    }
  }

  private fun DIV.pbutton(txt: String, title: String, click: (Event) -> Unit) {
    div(classes = "cell pbutton") {
      this@div.title = title

      +txt

      onClickFunction = click
    }
  }

}
