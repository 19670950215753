package daw.view.menu

import daw.model.DawModel
import daw.view.Modal
import daw.view.emptyRow
import daw.ws.WebsocketConnection
import kotlinx.html.div
import kotlinx.html.h1
import kotlinx.html.js.onClickFunction
import kotlinx.html.label
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.events.Event
import kotlin.browser.document
import kotlin.browser.window

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 12:58
 */

class SongsView : Komponent() {

  private fun openSong(songId: String): (Event) -> Unit = {
    WebsocketConnection.checkWebsocketConnection()
    document.location!!.href = "${window.location.protocol}//${window.location.host}${window.location.pathname}?$songId"
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes = "songs") {
    h1 {
      +"MY SONGS"
    }

    div(classes = "row") {
      div(classes = "cell") {
        label { +"Id" }
      }
      div(classes = "cell") {
        label { +"Instruments" }
      }
      div(classes = "cell") {
        label { +"Song" }
      }
      div(classes = "cell") {
        label { +"Last update" }
      }
    }

    DawModel.songList?.also {
      for (song in it) {
        val onclick = openSong(song.songId)
        div(classes = "row") {
          div(classes = "cell") {
            label {
              +song.songId
              onClickFunction = onclick
            }
          }
          div(classes = "cell") {
            label {
              +song.instruments
              onClickFunction = onclick
            }
          }
          div(classes = "cell") {
            label {
              +song.title
              onClickFunction = onclick
            }
          }
          div(classes = "cell") {
            label {
              +song.lastUpdate
              onClickFunction = onclick
            }
          }
        }
      }
    }

    emptyRow()

    div(classes = "row") {
      div(classes = "cell") {
        div(classes = "button") {
          +"Close"

          onClickFunction = {
            Modal.hide()
          }
        }
      }
    }
  }

}
