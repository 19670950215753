package daw.html

/**
 * User: rnentjes
 * Date: 28-3-16
 * Time: 14:12
 */

fun Float.toFixed(digits: Int = 0): String {
  val digDyn: dynamic = this

  return digDyn.toFixed(digits) as String
}

fun formatFloat(value: Float, decimals: Int): String {
  val valueString = value.toString()

  var result = ""
  var foundDot = false
  var foundDecimals = 0

  for (index in valueString.indices) {
    if (foundDot) {
      foundDecimals++
    }

    if (decimals == 0 && valueString[index] == '.') {
      return result
    }

    if (foundDecimals > decimals) {
      return result
    } else {
      result = result + valueString[index]
    }

    if (!foundDot) {
      foundDot = valueString[index] == '.'
    }
  }

  if (!foundDot && decimals > 0) {
    result += "."
  }

  while (decimals > 0 && foundDecimals < decimals) {
    result += "0"
    foundDecimals++
  }

  return result
}
