package daw.worker

import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.Worker
import org.w3c.dom.events.Event

/**
 * User: rnentjes
 * Date: 25-2-18
 * Time: 16:17
 */

object WorkerHandler {
  var worker: Worker? = null

  init {
    worker = Worker("js/start_worker.js")
    worker?.onmessage = ::receiveMessage
  }

  fun start() {
    val array = Array<ArrayBuffer>(2, { ArrayBuffer(1) })
    worker?.postMessage(array, array)
    worker?.postMessage("String data x")

    println("Array length after sending: ${array[0].byteLength}")
  }

  private fun receiveMessage(message: Event) {
    console.log("Received from worker:", message)
  }

}
