package daw.view

import kotlinx.html.DIV
import kotlinx.html.FlowOrPhrasingContent
import kotlinx.html.attributesMapOf
import kotlinx.html.div
import kotlinx.html.style
import kotlinx.html.visit

/**
 * User: rnentjes
 * Date: 27-12-18
 * Time: 14:19
 */

fun FlowOrPhrasingContent.row(block: DIV.() -> Unit) {
  return DIV(attributesMapOf("class", "row"), consumer).visit(block)
}

fun FlowOrPhrasingContent.cell(block: DIV.() -> Unit) {
  return DIV(attributesMapOf("class", "cell"), consumer).visit(block)
}

fun FlowOrPhrasingContent.emptyRow() {
  return DIV(attributesMapOf("class", "row"), consumer).visit {
    div(classes = "cell") {
      style = "white-space: pre;"

      + " "
    }
  }
}
