package daw.player

import daw.AudioHandler
import daw.Daw
import daw.instrument.BasicType
import daw.instrument.BasicWaveForms
import daw.instrument.Envelope
import daw.instrument.EnvelopePlayer
import daw.instrument.EnvelopeType
import daw.instrument.InstrumentType
import daw.instrument.ManualEnvelope
import daw.instrument.NoneEnvelope
import daw.instrument.Sample
import daw.note.Note
import kotlin.js.Math.random
import kotlin.math.sin

fun createChannelPlayer(ip: InstrumentPlayer, channel: Int = 0, channels: Int = 1): InstrumentChannelPlayer {
  val envelope: Envelope
  val player: InstrumentChannelPlayer
  val instrument = ip.instrument.instrument

  envelope = when (instrument.envelopeType) {
    EnvelopeType.NONE -> {
      NoneEnvelope()
    }
    EnvelopeType.ADSR -> {
      //envelope = ADSREnvelope(data.attackLevel, data.decayLevel, data.attackTime, data.decayTime, data.releaseTime)
      ManualEnvelope(instrument)
    }
    EnvelopeType.MANUAL -> {
      ManualEnvelope(instrument)
    }
  }

  when (instrument.type) {
    InstrumentType.BASIC -> {
      when (instrument.basicType) {
        BasicType.SQUARE -> player = WaveInstrumentChannelPlayer(ip, BasicWaveForms.square, envelope, channel, channels)
        BasicType.SINUS -> {
          player = WaveInstrumentChannelPlayer(ip, BasicWaveForms.sinus, envelope, channel, channels)
        }
        BasicType.TRIANGLE -> {
          player = WaveInstrumentChannelPlayer(ip, BasicWaveForms.triangle, envelope, channel, channels)
        }
        BasicType.SAWTOOTH -> {
          player = WaveInstrumentChannelPlayer(ip, BasicWaveForms.sawtooth, envelope, channel, channels)
        }
        BasicType.NOISE -> {
          player = NoiseInstrumentChannelPlayer(ip, envelope, channel, channels)
        }
        BasicType.MANUAL -> {
          if (instrument.samples.isNotEmpty()) {
            player = WaveInstrumentChannelPlayer(ip, Daw.song.getSample(instrument.samples[0]), envelope, channel, channels)
          } else {
            player = WaveInstrumentChannelPlayer(ip, Sample(), envelope, channel, channels)
          }
        }
      }
    }
    InstrumentType.WAVEFORM -> {
      if (instrument.samples.isNotEmpty()) {
        player = WaveInstrumentChannelPlayer(ip, Daw.song.getSample(instrument.samples[0]), envelope, channel, channels)
      } else {
        player = WaveInstrumentChannelPlayer(ip, Sample(), envelope, channel, channels)
      }
    }
    InstrumentType.SAMPLE -> {
      player = SampleInstrumentChannelPlayer(ip, envelope, channel, channels)
    }
    InstrumentType.DRUMS -> {
      player = SampleInstrumentChannelPlayer(ip, envelope, channel, channels)
    }
  }

  return player
}

/*
fun createInstrumentPlayer(ip: InstrumentPlayer, channels: Int = 1): InstrumentChannelPlayer {
  val instrument = ip.instrument.instrument

  val envelope = when (instrument.envelopeType) {
    EnvelopeType.NONE -> {
      NoneEnvelope()
    }
    EnvelopeType.ADSR -> {
      //envelope = ADSREnvelope(data.attackLevel, data.decayLevel, data.attackTime, data.decayTime, data.releaseTime)
      ManualEnvelope(instrument)
    }
    EnvelopeType.MANUAL -> {
      ManualEnvelope(instrument)
    }
  }

  val players: Array<InstrumentChannelPlayer> = Array(channels) {
    WaveInstrumentChannelPlayer(ip, BasicWaveForms.square, envelope, 0, channels)
  }

  for (channel in 0 until channels) {
    when (instrument.type) {
      InstrumentType.BASIC -> {
        when (instrument.basicType) {
          BasicType.SQUARE -> players[channel] = WaveInstrumentChannelPlayer(ip, BasicWaveForms.square, envelope, channel, channels)
          BasicType.SINUS -> {
            players[channel] = WaveInstrumentChannelPlayer(ip, BasicWaveForms.sinus, envelope, channel, channels)
          }
          BasicType.TRIANGLE -> {
            players[channel] = WaveInstrumentChannelPlayer(ip, BasicWaveForms.triangle, envelope, channel, channels)
          }
          BasicType.SAWTOOTH -> {
            players[channel] = WaveInstrumentChannelPlayer(ip, BasicWaveForms.sawtooth, envelope, channel, channels)
          }
          BasicType.NOISE -> {
            players[channel] = NoiseInstrumentChannelPlayer(ip, envelope, channel, channels)
          }
          BasicType.MANUAL -> {
            if (instrument.samples.isNotEmpty()) {
              players[channel] = WaveInstrumentChannelPlayer(ip, Daw.song.getSample(instrument.samples[0]), envelope, channel, channels)
            } else {
              players[channel] = WaveInstrumentChannelPlayer(ip, Sample(), envelope, channel, channels)
            }
          }
        }
      }
      InstrumentType.WAVEFORM -> {
        if (instrument.samples.isNotEmpty()) {
          players[channel] = WaveInstrumentChannelPlayer(ip, Daw.song.getSample(instrument.samples[0]), envelope, channel, channels)
        } else {
          players[channel] = WaveInstrumentChannelPlayer(ip, Sample(), envelope, channel, channels)
        }
      }
      InstrumentType.SAMPLE -> {
        players[channel] = SampleInstrumentChannelPlayer(ip, envelope, channel, channels)
      }
      InstrumentType.DRUMS -> {
        players[channel] = SampleInstrumentChannelPlayer(ip, envelope, channel, channels)
      }
    }
  }

  return player
}
*/

abstract class InstrumentChannelPlayer(
    val player: InstrumentPlayer,
    val envelope: EnvelopePlayer,
    var channel: Int,
    val channels: Int,
    val singleCycle: Boolean = true
) : Player {
  var stopped = false
  var cycleOffset = 0.0
  var sample = 0
  var delta = 0f
  var volume = envelope.getVolume(0f)
  var antiTickSamples = (0.01f * AudioHandler.sampleRate.toFloat()).toInt()
  val effectState = player.effectState

  open fun noteStart(note: Note) {
    if (!stopped || note == Note.UP) {
      // println("Note start $note -> $this")
      when (note) {
        Note.NONE -> return
        Note.UP   -> envelope.noteUp(getTime())
        Note.END  -> envelope.mute()
        else      -> {
          sample = 0
          delta = calculateDelta(note)
          volume = envelope.getVolume(0f)

          effectState.reset()
          envelope.unMute()
          envelope.noteStart()
        }
      }
    }
  }

  open fun calculateDelta(note: Note): Float {
    val samplesPerCycle = AudioHandler.sampleRate.toFloat() / note.freq

    return 1f / samplesPerCycle
  }

  override fun stop() {
    stopped = true

    noteStart(Note.UP)
  }

  abstract fun fillBuffer(
      left: FloatArray,
      right: FloatArray,
      offset: Int,
      count: Int,
      first: Boolean = true,
      samplesTillNextNote: Int = 999999
  )

  fun getTime(): Float {
    return sample / AudioHandler.sampleRate.toFloat()
  }

  open fun nextSample() {
    sample++

    cycleOffset += delta

    while (singleCycle && cycleOffset > 1f) {
      cycleOffset -= 1f
    }

    effectState.nextSample(channel, cycleOffset)

    delta *= effectState.currentInstrumentPitchSlide
    if (effectState.currentVibratoDepth > 0) {
      delta += (sin(effectState.currentVibratoSpeed * sample / effectState.samplesPerSecond2pi.toDouble()) * effectState.currentVibratoDepth).toFloat()
    }
  }
}

class WaveInstrumentChannelPlayer(player: InstrumentPlayer, val sample2: Sample, envelope: Envelope, channel: Int, channels: Int) :
    InstrumentChannelPlayer(player, EnvelopePlayer(envelope), channel, channels) {

  override fun fillBuffer(left: FloatArray, right: FloatArray, count: Int) {
    fillBuffer(left, right, 0, count, true)
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, offset: Int, count: Int, first: Boolean, samplesTillNextNote: Int) {
    if (player.instrument.instrument.muted) {
      if (first) {
        for (index in offset until offset + count) {
          left[index] = 0f
          right[index] = 0f
        }
      }
    } else {

      var leftValue: Float
      var rightValue: Float
      var samplesLeft = samplesTillNextNote
      var antiTickVolume = 1f
      val arpeggio = effectState.arpeggio

      for (index in offset until offset + count) {
        if (sample2.buffer.byteLength > 0) {
          //val dataView = sample2.dataView()
          val sampleOffset = (cycleOffset * sample2.samples()).toInt()

          leftValue = sample2.leftSampleValue(sampleOffset) / Short.MAX_VALUE.toFloat() //dataView.getInt16(sampleOffset * 4, true) / Short.MAX_VALUE.toFloat()
          rightValue = sample2.rightSampleValue(sampleOffset) / Short.MAX_VALUE.toFloat() //dataView.getInt16(sampleOffset * 4 + 2, true) / Short.MAX_VALUE.toFloat()
        } else {
          leftValue = 0f
          rightValue = 0f
        }

        volume = envelope.getVolume((sample / AudioHandler.sampleRate.toFloat())) * Daw.song.channelBaseVolume

        if (first) {
          left[index] = 0f
          right[index] = 0f
        }

        /*
      if (samplesLeft < 0) {
        antiTickVolume = 0f
      } else if (samplesLeft < antiTickSamples) {
        antiTickVolume = (samplesLeft / antiTickSamples.toFloat())
      }
*/

        val channelVolume = arpeggio?.getChannelVolume(player, channel, cycleOffset) ?: 1f

        left[index] += leftValue * volume * channelVolume * effectState.currentInstrumentVolume * antiTickVolume * effectState.currentInstrumentPanningVolumeLeft
        right[index] += rightValue * volume * channelVolume * effectState.currentInstrumentVolume * antiTickVolume * effectState.currentInstrumentPanningVolumeRight

        nextSample()

        samplesLeft--
      }
    }
  }

}

class NoiseInstrumentChannelPlayer(player: InstrumentPlayer, envelope: Envelope, channel: Int, channels: Int) : InstrumentChannelPlayer(
    player,
    EnvelopePlayer(envelope),
    channel,
    channels
) {

  override fun fillBuffer(left: FloatArray, right: FloatArray, count: Int) {
    fillBuffer(left, right, 0, count, true)
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, offset: Int, count: Int, first: Boolean, samplesTillNextNote: Int) {
    if (player.instrument.instrument.muted) {
      if (first) {
        for (index in offset until offset + count) {
          left[index] = 0f
          right[index] = 0f
        }
      }
    } else {
      var leftValue: Float
      var rightValue: Float
      val arpeggio = effectState.arpeggio

      for (index in offset until offset + count) {
        val value = random().toFloat()

        leftValue = value
        rightValue = value

        volume = envelope.getVolume((sample / AudioHandler.sampleRate.toFloat())) * Daw.song.channelBaseVolume

        if (first) {
          left[index] = 0f
          right[index] = 0f
        }

        var channelVolume = 1f
        if (arpeggio != null) {
          channelVolume = arpeggio.getChannelVolume(player, channel, cycleOffset)
        }

        left[index] += leftValue * volume * channelVolume * effectState.currentInstrumentVolume * effectState.currentInstrumentPanningVolumeLeft
        right[index] += rightValue * volume * channelVolume * effectState.currentInstrumentVolume * effectState.currentInstrumentPanningVolumeRight

        nextSample()
      }
    }
  }

}

class SampleInstrumentChannelPlayer(
    player: InstrumentPlayer,
    envelope: Envelope,
    channel: Int = 0,
    channels: Int
) : InstrumentChannelPlayer(player, EnvelopePlayer(envelope), channel, channels, singleCycle = false) {
  val instrument = player.instrument.instrument
  var instrumentSample = Sample()
  var numberOfSamples = instrumentSample.samples()
  var repeatSample = 0
  var restartSample = 0

  init {
    if (instrument.samples.size > channel && instrument.samples[channel] > -1) {
      instrumentSample = Daw.song.getSample(instrument.samples[channel])
      numberOfSamples = instrumentSample.samples()
      repeatSample = (instrumentSample.repeatStart + instrumentSample.repeatLength)
      restartSample = (1.0 / instrumentSample.samples().toDouble() * instrumentSample.repeatStart).toInt()
    }
  }

  override fun noteStart(note: Note) {
    super.noteStart(note)

    if (note != Note.NONE && !stopped) {
      cycleOffset = 0.0
    }
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, count: Int) {
    fillBuffer(left, right, 0, count, true)
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, offset: Int, count: Int, first: Boolean, samplesTillNextNote: Int) {
    if (player.instrument.instrument.muted) {
      if (first) {
        for (index in offset until offset + count) {
          left[index] = 0f
          right[index] = 0f
        }
      }
    } else {
      var leftValue: Float
      var rightValue: Float
      val arpeggio = effectState.arpeggio

      if ((envelope.muted || cycleOffset > 1f) && first) {
        for (index in offset until offset + count) {
          left[index] = 0f
          right[index] = 0f
        }
        return
      }

      for (index in offset until (offset + count)) {
        if (first) {
          left[index] = 0f
          right[index] = 0f
        }

        var sampleOffset = (cycleOffset * numberOfSamples).toInt()
        if (sampleOffset < numberOfSamples && instrumentSample.buffer.byteLength > 0) {

          if (instrumentSample.repeatStart > 0 && sampleOffset >= repeatSample - 1) {
            cycleOffset = 1.0 / instrumentSample.samples().toDouble() * instrumentSample.repeatStart

            sampleOffset = (cycleOffset * instrumentSample.samples()).toInt()
          }

          leftValue = instrumentSample.leftSampleValue(sampleOffset) / Short.MAX_VALUE.toFloat() //dataView.getInt16(sampleOffset * 4, true) / Short.MAX_VALUE.toFloat()
          rightValue = instrumentSample.rightSampleValue(sampleOffset) / Short.MAX_VALUE.toFloat() //dataView.getInt16(sampleOffset * 4 + 2, true) / Short.MAX_VALUE.toFloat()
        } else {
          leftValue = 0f
          rightValue = 0f
        }

        volume = envelope.getVolume((sample / AudioHandler.sampleRate.toFloat())) * Daw.song.channelBaseVolume

        val channelVolume = arpeggio?.getChannelVolume(player, channel, cycleOffset) ?: 1f

        left[index] += leftValue * volume * channelVolume * effectState.currentInstrumentVolume * effectState.currentInstrumentPanningVolumeLeft
        right[index] += rightValue * volume * channelVolume * effectState.currentInstrumentVolume * effectState.currentInstrumentPanningVolumeRight

        nextSample()
      }
    }
  }

  fun retrigger(offset: Int) {
    cycleOffset = 1.0 / instrumentSample.samples().toDouble() * offset
  }

  override fun calculateDelta(note: Note): Float {
    // length in seconds of the original sound
    val length = instrumentSample.samples() / instrumentSample.sampleRate.toFloat()
    var delta = 1f / (length * AudioHandler.sampleRate)

    delta = delta * note.freq / instrumentSample.sampleNote.freq

    return delta
  }

}
