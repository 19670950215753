package daw.view.instrument

import daw.AudioHandler
import daw.Daw
import daw.html.Knob
import daw.html.NoteKeyboard
import daw.html.Select
import daw.html.Slider
import daw.html.attr
import daw.html.bottomcell
import daw.html.cell
import daw.html.clear
import daw.html.cls
import daw.html.div
import daw.html.elem
import daw.html.hasElem
import daw.html.html
import daw.html.id
import daw.html.label
import daw.html.main
import daw.html.row
import daw.html.select
import daw.html.textinput
import daw.html.topcell
import daw.html.txt
import daw.html.with
import daw.input.Input
import daw.input.InstrumentController
import daw.instrument.BasicType
import daw.instrument.BasicTypeSelect
import daw.instrument.BasicWaveForms
import daw.instrument.Instrument
import daw.instrument.InstrumentType
import daw.instrument.InstrumentTypeSelect
import daw.instrument.Sample
import daw.note.Note
import daw.note.NoteSelect
import daw.player.InstrumentPlayer
import daw.song.InstrumentTrack
import daw.view.View
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.Event
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get
import kotlin.browser.document
import kotlin.dom.addClass
import kotlin.dom.removeClass

/**
 * User: rnentjes
 * Date: 22-12-15
 * Time: 17:23
 */

object InstrumentView {
  var player = InstrumentPlayer(InstrumentTrack(Instrument(Daw.song.songId, -1)), 1)

  fun renderInstrumentModal(instrNumber: Int = -1) {
    var instrument: Instrument = Instrument(Daw.song.songId, -1)

    if (instrNumber >= 0) {
      instrument = Daw.song.instruments[instrNumber].instrument.copy()
    }

    //Input.setFocus(InstrumentController)
    Input.clearFocus()
    renderInstrumentModal(instrument)
  }

  fun renderInstrumentModal(instrument: Instrument) {
    //InstrumentController.setInstrument(instrument)
    player = InstrumentPlayer(InstrumentTrack(instrument), 1)

    AudioHandler.play(player)

    if (!hasElem("modal")) {
      main().with(div().id("modal").cls("modal"))
    }

    clear("modal")

    val modal = elem("modal")
    modal.addClass("show")

    val dialog = div().cls("modal-dialog")

    val nameInput = textinput("instrument_name", instrument.description())

    nameInput.onblur = {
      val input = elem("instrument_name") as HTMLInputElement

      println("SET NAME: ${input.value}")

      if (input.value == "") {
        instrument.named = false
        input.value = instrument.description()
      } else {
        instrument.name = input.value
        instrument.named = true
      }
    }

    dialog.with(row(cell(nameInput)))
    dialog.with(row(document.createElement("hr")))

    val slider = Slider.create(
        label = "Panning:",
        sliderColor = "hsl(27, 50%, 60%)",
        railColor = "hsl(27, 50%, 20%)",
        backgroundColor = "hsl(27, 0%, 12%)",
        width = 100,
        height = 40,
        knobRadius = 6,
        minValue = -1f,
        maxValue = 1f,
        step = 0.05f,
        value = instrument.panning) { value ->
      instrument.panning = value.toFloat()
    }

    val topleft = topcell()
    val topright = topcell()

    if (instrument.type != InstrumentType.DRUMS) {
      topright.with(NoteKeyboard.create(
          width = 270, height = 100,
          noteDownCallback = { note ->
            player.playNote(note)
          },
          noteUpCallback = { note ->
            player.noteUp(note)
          }
      ))
    }

    val column2 = cell()

    slider.attr("style", "vertical-align: top;")

    topleft.with(row(renderVolumeEntry(instrument), slider))
    topleft.with(row(renderTypeSelect(instrument)))

    dialog.with(row(column2))

    column2.with(row(topleft, topright))

    column2.with(row(cell().txt(" ")))
    val editor: Element

    when (instrument.type) {
      InstrumentType.BASIC -> {
        editor = renderBasicTypeSelect(instrument)
        column2.with(row(bottomcell(editor), bottomcell(EnvelopeEditorView.create(instrument))))
      }
      InstrumentType.WAVEFORM -> {
        editor = renderWaveformSelect(instrument)
        column2.with(row(bottomcell(editor), bottomcell(EnvelopeEditorView.create(instrument))))
      }
      InstrumentType.SAMPLE -> {
        editor = renderWaveformSelect(instrument, true)
        column2.with(row(bottomcell(editor), bottomcell(EnvelopeEditorView.create(instrument))))
      }
      InstrumentType.DRUMS -> {
        DrumsView.renderDrumsSamplesSelect(column2, instrument)
      }
    }

    val save = div().cls("button").txt("Save") as HTMLDivElement
    val cancel = div().cls("button").txt("Cancel") as HTMLDivElement

    save.onclick = {
      InstrumentController.save(instrument)

      modal.removeClass("show")
    }

    cancel.onclick = {
      modal.removeClass("show")
    }

    dialog.with(row(cell().txt(" ")))
    dialog.with(div(row(cell(save), cell(cancel))))

    modal.with(dialog)
    modal.with(div().cls("modal-layer"))
  }

  fun renderVolumeEntry(instrument: Instrument): Element {
    val result = Knob.create(
        volumeColor = "hsl(27, 50%, 60%)",
        ringColor = "hsl(27, 50%, 20%)",
        backgroundColor = "hsl(27, 0%, 12%)",
        label = "Volume",
        value = instrument.volume) { value ->
      instrument.volume = value.toFloat()
    }

    return result
  }

  fun renderTypeSelect(instrument: Instrument): Element {
    val label = label("instrument_type", "Instrument type:")
    val select = select("instrument_type", InstrumentTypeSelect(), instrument.type.name)

    select.onchange = {
      val sel = it.currentTarget

      if (sel is HTMLSelectElement) {
        instrument.type = InstrumentType.valueOf(sel.value)
        renderInstrumentModal(instrument)
      } else {
        throw IllegalStateException("Wrong type! $sel")
      }
    }

    return cell(row(cell(label), cell(select)))
  }

  fun renderBasicTypeSelect(instrument: Instrument): Element {
    val result = div()

    val label = label("instrument_basic_type", "Chip waveform:")
    val select = select("instrument_basic_type", BasicTypeSelect(), instrument.basicType.name)

    select.onchange = {
      val sel = it.currentTarget

      if (sel is HTMLSelectElement) {
        instrument.basicType = BasicType.valueOf(sel.value)
        renderInstrumentModal(instrument)
      } else {
        throw IllegalStateException("Wrong type! $sel")
      }
    }

    val wave: Sample

    when (instrument.basicType) {
      BasicType.SINUS -> {
        wave = BasicWaveForms.sinus
      }
      BasicType.SQUARE -> {
        wave = BasicWaveForms.square
      }
      BasicType.TRIANGLE -> {
        wave = BasicWaveForms.triangle
      }
      BasicType.SAWTOOTH -> {
        wave = BasicWaveForms.sawtooth
      }
      BasicType.NOISE -> {
        wave = BasicWaveForms.noise
      }
      BasicType.MANUAL -> {
        if (instrument.samples.isNotEmpty()) {
          wave = Daw.song.getSample(instrument.samples[0])
        } else {
          wave = Daw.song.getNewSample()
          wave.levels = 8
          wave.setSamples(8)

          instrument.samples = Array(1, { wave.sampleNumber })

          player = InstrumentPlayer(InstrumentTrack(instrument), 1)

          AudioHandler.play(player)
        }
      }
    }

    result.with(row(cell(row(cell(label), cell(select)))))
    if (instrument.basicType == BasicType.MANUAL) {
      val l1 = label("instrument_number_of_samples", "Number of samples:")
      val l2 = label("instrument_number_of_values", "Number of levels:")

      val sel1 = select("instrument_number_of_samples", arrayOf(
          Select("2", "2"),
          Select("4", "4"),
          Select("8", "8"),
          Select("16", "16"),
          Select("32", "32"),
          Select("64", "64"),
          Select("128", "128"),
          Select("256", "256")
      ), "${wave.samples()}")

      val sel2 = select("instrument_number_of_levels", arrayOf(
          Select("1", "1"),
          Select("2", "2"),
          Select("4", "4"),
          Select("8", "8"),
          Select("16", "16"),
          Select("32", "32"),
          Select("64", "64"),
          Select("128", "128")
      ), "${wave.levels}")

      sel1.onchange = {
        val sel = it.currentTarget

        if (sel is HTMLSelectElement) {
          wave.setSamples(sel.value.toInt())
          renderInstrumentModal(instrument)
        } else {
          throw IllegalStateException("Wrong type! $sel")
        }
      }

      sel2.onchange = {
        val sel = it.currentTarget

        if (sel is HTMLSelectElement) {
          wave.levels = sel.value.toInt()
          renderInstrumentModal(instrument)
        } else {
          throw IllegalStateException("Wrong type! $sel")
        }
      }

      result.with(row(cell(row(cell(l1), cell(sel1)))))
      result.with(row(cell(row(cell(l2), cell(sel2)))))

      result.with(row(cell(WaveEditorView.create(wave))))
    } else {
      result.with(row(cell(WaveView.create(wave))))
    }

    return result
  }

  fun renderWaveformSelect(instrument: Instrument, showNote: Boolean = false): Element {
    val result = div()
    val label = label("instrument_waveform", "Select file...").cls("button")
    val fileInput = document.createElement("input") as HTMLInputElement
    var sample = Sample()

    if (instrument.samples.isNotEmpty()) {
      sample = Daw.song.getSample(instrument.samples[0])
    }

    fileInput.cls("inputfile")
    fileInput.attr("name", "instrument_waveform")
    fileInput.attr("id", "instrument_waveform")
    fileInput.attr("value", sample.sampleName)
    fileInput.attr("type", "file")

    fileInput.onchange = fileinputSelectHandler(instrument, fileInput)

    if (showNote) {
      val selectNoteLabel = label("instrument_note", "Sampled note:")
      val selectNote = select("instrument_note", NoteSelect(), "${sample.sampleNote}")

      result.with(row(cell(row(selectNoteLabel, selectNote))))

      selectNote.onchange = {
        val sel = it.currentTarget as HTMLSelectElement

        sample.sampleNote = Note.valueOf(sel.value)
        ""
      }
    }

    result.with(row(cell(row(cell().txt("Waveform:"), cell().html(sample.sampleName)))))
    result.with(row(cell(row(cell(label), cell(fileInput)))))
    result.with(row(cell(WaveView.create(sample))))

    return result
  }

  private fun fileinputSelectHandler(instrument: Instrument, sel: HTMLInputElement): (Event) -> Unit {
    return {
      val list: FileList? = sel.files
      if (list != null && list.length == 1) {
        val file = list[0]
        if (file != null) {
          val reader = FileReader()

          reader.onload = {
            if (reader.result is ArrayBuffer) {
              val buffer = reader.result

              if (file.type == "audio/wav") {
                val dataView = DataView(buffer)

                if (dataView.byteLength < 44) {
                  throw IllegalStateException("This is not a WAVE file!")
                }

                // assert pcm, 16 bit mono
                val channels = dataView.getInt16(22, true)
                val sampleRate = dataView.getInt32(24, true)
                val bytesPerSample = dataView.getInt16(32, true).toInt()
                val dataLength = dataView.getInt32(40, true)

                println("Found $channels channels, bytes per sample: ${bytesPerSample}, sample rate: ${sampleRate} data length: ${dataLength}")

                val sample = Daw.song.getNewSample()
                instrument.samples = Array(1, { sample.sampleNumber })

                sample.buffer = buffer.slice(44, dataLength + 44)
                sample.sampleRate = sampleRate
                sample.sampleNote = Note.C3
                sample.sampleName = file.name
                sample.bytesPerSample = bytesPerSample

                if (channels == 1.toShort()) {
                  sample.stereo = false
                } else if (channels == 2.toShort()) {
                  sample.stereo = true
                } else {
                  throw IllegalStateException("More than 2 sample samples not supported!")
                }

                renderInstrumentModal(instrument)
              }
            }
          }

          reader.readAsArrayBuffer(file)
        }
      } else {
        View.showError("ERROR!")
      }
    }
  }
}
