package daw.view.effect

import daw.component.SimpleComponent
import daw.component.SliderComponent
import daw.input.TrackListInput
import daw.song.effects.SetPanning
import daw.view.PatternView
import kotlinx.html.div
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include

/**
 * Created by rnentjes on 6-3-17.
 */

class PanningComponent : Komponent() {
  val title = EffectTitle("Panning:") {
    updatePanningAction()

    refresh()
  }

  var panning: SetPanning = SetPanning(0f)

  fun updatePanning(active: Boolean, panning: Float) {
    this.title.active = active
    this.panning.panning = panning
  }

  fun updatePanningAction() {
    val effects = TrackListInput.getEffects()

    if (title.active) {
      effects?.panning = effects?.panning ?: SetPanning(0f)
      effects?.panning?.panning = panning.panning
    } else {
      effects?.panning = null
    }

    PatternView.updateEntry()
    TrackListInput.setSelectedTrackDirty()
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes = "bigtable") {

    include(this@PanningComponent.title)

    if (this@PanningComponent.title.active) {
      include(SliderComponent(
          sliderColor = "hsl(27, 50%, 60%)",
          railColor = "hsl(27, 50%, 20%)",
          backgroundColor = "hsl(27, 0%, 15%)",
          width = 240,
          height = 50,
          knobRadius = 6,
          minValue = -1f,
          maxValue = 1f,
          step = 0.05f,
          value = panning.panning.toDouble()) { value ->
        panning.panning = value.toFloat()
        updatePanningAction()
      })
    }

    div(classes = "row") {
      div(classes = "cell") {
        + " "
      }
    }
  }
}

class PanningData(
    val id: String,
    var panning: SetPanning,
    active: Boolean = false
) : ActiveHolder(active) {
  override fun activeSwitched() {
    updatePanningAction(this)

    redraw()
  }

  fun updatePanning(active: Boolean, panning: Float) {
    this.active = active
    this.panning.panning = panning

    redraw()
  }

  fun redraw() {
    SimpleComponent.redraw(id)
  }
}

/*
fun panningView(data: PanningData): Element {

    return effect(data.id, "Panning: ", data, {
        div(mapOf("class" to "row", "style" to "float: center;"), {
            val slider = Slider.create(
              //label = "Panning:",
              sliderColor = "hsl(27, 50%, 60%)",
              railColor = "hsl(27, 50%, 20%)",
              backgroundColor = "hsl(27, 0%, 15%)",
              width = 240,
              height = 50,
              knobRadius = 6,
              minValue = -1f,
              maxValue = 1f,
              step = 0.05f,
              value = data.panning.panning) {
                value ->
                data.panning.panning = value.toFloat()
                updatePanningAction(data)
            }

            slider as HTMLElement
        })
    })
}
*/

fun updatePanningAction(data: PanningData) {
  val effects = TrackListInput.getEffects()

  if (data.active) {
    effects?.panning = effects?.panning ?: SetPanning(data.panning.panning)
    effects?.panning?.panning = data.panning.panning
  } else {
    effects?.panning = null
  }

  PatternView.updateEntry()
  TrackListInput.setSelectedTrackDirty()
}
