package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * Created by rnentjes on 2-2-16.
 */

class Vibrato(var speed: Float, var depth: Float) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.currentVibratoSpeed = speed
    player.effectState.currentVibratoDepth = depth
  }

}
