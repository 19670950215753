package daw.song

import daw.AudioHandler
import daw.Daw
import daw.input.TrackListInput
import daw.instrument.Instrument
import daw.player.InstrumentChannelPlayer
import daw.instrument.InstrumentData
import daw.instrument.InstrumentType
import daw.instrument.Sample
import daw.player.createChannelPlayer
import daw.instrument.instrumentFromInstrumentData
import daw.note.Note
import daw.player.InstrumentPlayer
import daw.processor.ReverbProcessor
import daw.player.SongPlayer
import daw.processor.ChorusProcessor
import daw.processor.HighPassProcessor
import daw.processor.LowPassProcessor
import daw.view.PatternView
import daw.view.PatternsView
import daw.view.SongView
import daw.view.View
import daw.ws.WebsocketConnection
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.w3c.files.BlobPropertyBag
import kotlin.js.Date
import kotlin.math.max

/**
 * Created by rnentjes on 1-12-15.
 */

class InstrumentPatternsData(val songId: String, val instrumentIndex: Int, val channels: Int, val hide: Boolean = false, val color: String = "#ff0000") {
  var patterns = Array(0) { 0 }

  fun setTrackList(trackList: ArrayList<Int>) {
    this.patterns = trackList.toTypedArray()
  }
}

// combine instrument with patterns
class InstrumentTrack(val song: Song, var instrument: Instrument) {
  var show = true
  var numberOfChannels = 1
  val patternList = ArrayList<Int>()
  val availablePatterns = ArrayList<Track>()
  var color = "#ff0000"

  constructor(instrument: Instrument) : this(Song("", "", "", 120), instrument)

  fun addAvailableTrack() {
    val track = Track(song, this, availablePatterns.size)
    availablePatterns.add(track)
    WebsocketConnection.addDirtyTrack(track)
  }

  fun getTrackAtPosition(position: Int): Track? {
    if (position < patternList.size) {
      val number = patternList[position]

      if (number >= 0 && number < availablePatterns.size) {
        return availablePatterns[number]
      }
    }

    return null
  }

  fun save() {
    val data = InstrumentPatternsData(song.songId, instrument.number, numberOfChannels, !show, color)

    data.patterns = patternList.toTypedArray()

    WebsocketConnection.savePatterns(data)
  }

  fun maxPattern(): String {
    var result = ""

    if (availablePatterns.size < 10) {
      result += "0"
    }

    result += availablePatterns.size.toString()

    return result
  }

  fun setChannels(channels: Int, force: Boolean = false) {
    if (force) {
      for (index in 0 until availablePatterns.size) {
        availablePatterns[index].setChannels(channels)
        WebsocketConnection.addDirtyTrack(availablePatterns[index])
      }
      Daw.song.numberOfChannels += (channels - this.numberOfChannels)
      this.numberOfChannels = channels

      WebsocketConnection.addDirtyInstrumentPatterns(this)

      TrackListInput.deselect()
      PatternView.renderTracks(true)
    } else {
      View.confirm("You might lose pattern data. Are you sure?", {
        if (this.numberOfChannels != channels) {
          try {
            Daw.block()

            for (index in 0..availablePatterns.size - 1) {
              availablePatterns[index].setChannels(channels)
              WebsocketConnection.addDirtyTrack(availablePatterns[index])
            }
            Daw.song.numberOfChannels += (channels - this.numberOfChannels)
            this.numberOfChannels = channels

            WebsocketConnection.addDirtyInstrumentPatterns(this)

            TrackListInput.deselect()
            PatternView.renderTracks(true)
          } finally {
            Daw.unblock()
          }
        }
      }, this.numberOfChannels < channels, {
        PatternsView.renderPatternList()
      }
      )
    }
  }

  fun findTrack(trackData: TrackData): Track? {
    for (track in availablePatterns) {
      if (track.equals(trackData)) {
        return track
      }
    }

    return null
  }

  fun copyTrack(track: Track): Int {
    addAvailableTrack()

    val newTrack = availablePatterns[availablePatterns.size - 1]

    for (channel in 0 until track.notes.size) {
      for (entry in 0 until track.notes[channel].size) {
        newTrack.notes[channel][entry] = track.notes[channel][entry]
      }
    }

    for (entry in 0 until track.effects.size) {
      newTrack.effects[entry] = decodeEffects(track.effects[entry].encode())
    }

    return newTrack.number
  }

  fun getSelectedPattern(): String {
    val pattern = patternList[Daw.song.selectedPattern] + 1
    var result = "$pattern"

    if (pattern == 0) {
      result = ""
    } else if (pattern < 10) {
      result = "0$result"
    }

    return result
  }

  fun updateEntriesPerPattern(epp: Int) {
    for (track in availablePatterns) {
      track.updateEntriesPerPattern(epp)
    }
  }

  fun hasNotes(): Boolean {
    for (track in availablePatterns) {
      if (track.hasNotes()) {
        return true
      }
    }

    return false
  }
}

class EntryData(samplesPerEntry: Int) {
  val left = FloatArray(samplesPerEntry)
  val right = FloatArray(samplesPerEntry)
  var dirty = true;
  var maxVolume = 0f

  // todo: store state per instrument
  var startHash = 0
  var endHash = 0
}

class PatternData(val sampleRate: Int, val samplesPerEntry: Int, val entriesPerPattern: Int) {
  val data = Array(entriesPerPattern, { EntryData(samplesPerEntry) })
  var position = 0

  fun getFirstHash(): Int = data[0].startHash

  fun calculate(volumePerChannel: Float, tracks: List<Track>, lastPatternHash: Int = 0): Int {
    var lastHash = lastPatternHash
    var channels = 0
    for (pattern in tracks) {
      channels += pattern.instrumentPatterns.numberOfChannels
    }

    val instruments = Array(channels, { createChannelPlayer(InstrumentPlayer(InstrumentTrack(Song("", "", "", 120), Instrument("", -1)), 1)) })
    // todo: set correct instruments

    for (index in 0..data.size - 1) {
      val entry = data[index]

      if (entry.dirty || entry.startHash != lastHash) {
        // calculate
        var first = true
        for (instrument in instruments) {
          instrument.fillBuffer(entry.left, entry.right, 0, samplesPerEntry, first)
          first = false
        }

        entry.dirty = false
        entry.startHash = lastHash
        entry.endHash = calculateHash(instruments)

        lastHash = entry.endHash
      } else {
        // skip
        lastHash = entry.endHash
      }
    }

    return lastHash
  }

  fun calculateHash(instruments: Array<InstrumentChannelPlayer>): Int {
    return 0
  }

}

// Song data:
// song, song data
// song, instr, pattern index, pattern number
// song, instr, pattern number, pattern data
// song, instr, instrument data

class SongData(var songId: String, var name: String, var user: String, var bpm: Int) {
  var copiedFrom: String? = null
  var linesPerBeat: Int = 4
  var beatsPerTrack: Int = 8
  var entriesPerPattern = 32
  var numberOfPatterns = 15
  var octave = 3
  var created = Date()
  var updated = Date()
  var id: Int = -1
  var token: String = ""
  var reverbDelay: Float = 0f
  var reverbDecay: Float = 0f
}

class Song(var songId: String, var name: String, var user: String, var bpm: Int) {
  var copiedFrom: String? = null
  var entriesPerPattern = 32
  var linesPerBeat = 4
  var beatsPerTrack = 8

  var numberOfPatterns = 1
  var instruments = ArrayList<InstrumentTrack>()
  var masterVolume = 1f
    set(value) {
      field = value
      channelBaseVolume = 1f / numberOfChannels.toFloat() * masterVolume
    }
  var numberOfChannels = 0
    set(value) {
      field = value
      channelBaseVolume = 1f / numberOfChannels.toFloat() * masterVolume
      //println("Total number of channels = $numberOfChannels -> $channelBaseVolume")
    }
  var channelBaseVolume = 1f / numberOfChannels.toFloat() * masterVolume
  var length = "00:00"
  var reverbDelay = 0f
  set(value) {
    field = value
    WebsocketConnection.setSongIsDirty()
  }
  var reverbDecay = 0f
    set(value) {
      field = value
      WebsocketConnection.setSongIsDirty()
    }
  var reverbSpeed = 0f
  var reverbDepth = 0f
  var lowPass = 20000f

  //val calculatedPatterns = Array(15, { PatternData(44100, 5000, 32) })
  var samplesPerEntry = 0;
  var samples = ArrayList<Sample>()

  var selectedPattern: Int = 0

  fun updateSamplesPerEntry() {
    if (linesPerBeat == 0 && beatsPerTrack == 0 && entriesPerPattern > 0) {
      //println("Update lines and beats $linesPerBeat - $beatsPerTrack - $entriesPerPattern")
      linesPerBeat = 4
      beatsPerTrack = entriesPerPattern / linesPerBeat

      //println("-> lines and beats $linesPerBeat - $beatsPerTrack - $entriesPerPattern")
    }

    val bps = bpm / 60f
    val linesPerSecond = bps * linesPerBeat
    samplesPerEntry = (AudioHandler.sampleRate / linesPerSecond).toInt()

    //println("Playing with spe: ${samplesPerEntry}")

    var patterns = 0
    for (ip in instruments) {
      patterns = max(patterns, ip.patternList.size)
    }
    val lis = patterns * beatsPerTrack / bps

    //println("Length in seconds: $lis patterns=$patterns bps=$bps bpt=$beatsPerTrack")

    val minutes = (lis / 60).toInt()
    val seconds = (lis % 60).toInt()
    var mstr = "00"
    var sstr = "00"

    if (minutes < 10) {
      mstr = "0$minutes"
    } else {
      mstr = "$minutes"
    }

    if (seconds < 10) {
      sstr = "0$seconds"
    } else {
      sstr = "$seconds"
    }

    length = "$mstr:$sstr"

    setEntriesPerPattern(linesPerBeat * beatsPerTrack)
    SongView.updateSongInfo()
  }

  fun setEntriesPerPattern(epp: Int) {
    if (epp > entriesPerPattern) {
      for (ip in instruments) {
        ip.updateEntriesPerPattern(epp)
      }
    }

    if (epp != entriesPerPattern) {
      entriesPerPattern = epp

      PatternView.renderTracks(true)
    }
  }

  fun setBpm(bpm: Int) {
    this.bpm = bpm

    updateSamplesPerEntry()
  }

  fun setLinesPerBeat(lpb: Int) {
    this.linesPerBeat = lpb

    updateSamplesPerEntry()
  }

  fun setBeatsPerTrack(bpt: Int) {
    this.beatsPerTrack = bpt

    updateSamplesPerEntry()
  }

  fun addInstrument(instr: Instrument) {
    instr.number = instruments.size
    val instrumentPatterns = InstrumentTrack(this, instr)

    while (instrumentPatterns.patternList.size <= Daw.song.numberOfPatterns) {
      instrumentPatterns.patternList.add(-1)
    }

    instruments.add(instrumentPatterns)
    numberOfChannels += 1

    if (instr.type == InstrumentType.DRUMS) {
      Daw.song.instruments[instr.number].setChannels(instr.samples.size, true)
    }

    if (!WebsocketConnection.loading && !Daw.importing) {
      PatternsView.renderPatternList()
      PatternView.renderTracks()
    }
  }

  fun setPatterns(patterns: InstrumentPatternsData) {
    while (instruments.size < patterns.instrumentIndex + 1) {
      addInstrument(Instrument(patterns.songId, -1))
    }

    instruments[patterns.instrumentIndex].setChannels(patterns.channels, true)
    instruments[patterns.instrumentIndex].show = !patterns.hide
    instruments[patterns.instrumentIndex].color = patterns.color
    instruments[patterns.instrumentIndex].patternList.clear()
    for (number in patterns.patterns) {
      instruments[patterns.instrumentIndex].patternList.add(number)
    }
  }

  fun setInstrument(instrument: InstrumentData) {
    while (instruments.size < instrument.number + 1) {
      addInstrument(Instrument(instrument.songId, -1))
    }

    instruments[instrument.number].instrument = instrumentFromInstrumentData(instrument)
  }

  fun setTrack(track: TrackData) {
    while (instruments.size < track.instrument + 1) {
      addInstrument(Instrument(track.songId, -1))
    }

    val instr = instruments[track.instrument]

    while (instr.availablePatterns.size < track.number + 1) {
      instr.addAvailableTrack()
    }

    for (x in 0 until track.notes.size) {
      for (y in 0 until track.notes[x].size) {
        //println("-> ${track.number} $x, $y")
        instr.availablePatterns[track.number].notes[x][y] = Note.valueOf(track.notes[x][y])
      }
    }

    if (track.effects != null && track.effects.isNotEmpty()) {
      instr.availablePatterns[track.number].effects = Array(track.effects.size, { Effects() })
      for (index in 0..track.effects.size - 1) {
        instr.availablePatterns[track.number].effects[index] = decodeEffects(track.effects[index])
      }
    } else {
      instr.availablePatterns[track.number].effects = Array(track.notes.size, { Effects() })
    }
  }

  fun addPattern() {
    numberOfPatterns++

    for (ip in instruments) {
      while (ip.patternList.size <= numberOfPatterns) {
        ip.patternList.add(-1)
      }
      WebsocketConnection.addDirtyInstrumentPatterns(ip)
    }

    updateSamplesPerEntry()

    WebsocketConnection.setSongIsDirty()
    PatternsView.renderPatternList()
  }

  fun save() {
    val songData = SongData(songId, name, user, bpm)

    songData.copiedFrom = copiedFrom
    songData.entriesPerPattern = entriesPerPattern
    songData.numberOfPatterns = numberOfPatterns
    songData.linesPerBeat = linesPerBeat
    songData.beatsPerTrack = beatsPerTrack
    songData.reverbDecay = reverbDecay
    songData.reverbDelay = reverbDelay

    WebsocketConnection.saveSong(songData)
  }

  fun play() {
    updateSamplesPerEntry()

    AudioHandler.play(
        SongPlayer(Daw.song, selectedPattern),
        ReverbProcessor(
            delayInput = { reverbDelay },
            decayInput = { reverbDecay }
        ),
        LowPassProcessor(
            frequencyInput = { lowPass }
        )
    )
  }

  fun stop() {
    AudioHandler.stop()
  }

  fun playPattern(selectedPattern: Int) {
    updateSamplesPerEntry()

    AudioHandler.play(SongPlayer(Daw.song, selectedPattern, true))
  }

  fun getSample(sample: Int): Sample {
    if (sample >= 0) {
      while (samples.size <= sample) {
        val number = samples.size
        val newSample = Sample()
        newSample.sampleNumber = number
        samples.add(newSample)
      }

      return samples[sample]
    } else {
      throw IllegalStateException("Invalid value for sample: $sample")
    }
  }

  fun getNewSample(): Sample {
    val number = samples.size
    val result = Sample()

    result.sampleNumber = number

    samples.add(result)

    return result
  }

  fun hasNotes(): Boolean {
    for (ip in instruments) {
      if (ip.hasNotes()) {
        return true
      }
    }

    return false
  }

  fun removeInstrument(ip: InstrumentTrack) {
    Daw.song.save()

    WebsocketConnection.sendRemoveInstrument(ip.instrument)
  }

  fun removeEntry(ptnum: Int) {
    for (ip in instruments) {
      ip.patternList.remove(ptnum)
      ip.save()
    }

    Daw.song.numberOfPatterns--
    Daw.song.save()

    PatternsView.renderPatternList()
  }

  fun exportWav() {
    val seconds = 60 //Daw.song.length
    val samples = 44100 * seconds
    val buffer = ArrayBuffer(samples * 2 * 2)
    val test = Array(0, { 0.toByte() })

    val bufferDataView = DataView(buffer)

    val bpb = BlobPropertyBag()
    // val blob = Blob(buffer as Array<Byte>, bpb)
    // var wav = "data:application/octet-stream," + encodeURIComponent(content);
    // throw UnsupportedOperationException("not implemented") //To change body of created functions use File | Settings | File Templates.
  }

  fun findInstrument(instrumentNumber: Int): Instrument? {

    return instruments.firstOrNull {
      it.instrument.number == instrumentNumber
    }?.instrument
  }

}
