package daw.view.effect

import daw.component.NumberInput
import daw.input.TrackListInput
import daw.song.effects.PitchSlide
import daw.view.PatternView
import kotlinx.html.div
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include

/**
 * Created by rnentjes on 6-3-17.
 */

class PitchSlideComponent : Komponent() {
  val title = EffectTitle("Seminotes/line:") {
    updatePitchSlideAction()

    refresh()
  }

  var pitchSlide: PitchSlide = PitchSlide(0, 0)

  fun updatePitchSlide(active: Boolean, semiNotes: Int, lines: Int) {
    this.title.active = active
    this.pitchSlide.semiNotes = semiNotes
    this.pitchSlide.lines = lines
  }

  fun updatePitchSlideAction() {
    val effects = TrackListInput.getEffects()

    if (this.title.active) {
      effects?.pitchSlide = effects?.pitchSlide ?: PitchSlide(0, 0)
      effects?.pitchSlide?.semiNotes = pitchSlide.semiNotes
      effects?.pitchSlide?.lines = pitchSlide.lines
    } else {
      effects?.pitchSlide = null
    }

    PatternView.updateEntry()
    TrackListInput.setSelectedTrackDirty()
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes = "bigtable") {

    include(this@PitchSlideComponent.title)

    if (this@PitchSlideComponent.title.active) {
      effect("Seminotes/line:") {
        div(classes = "row") {
          div(classes = "cell") {
            include(NumberInput("effect_seminotes",
                value = pitchSlide.semiNotes,
                step = 1,
                min = -72,
                max = 72,
                changeHandler = {
              pitchSlide.semiNotes = it
              updatePitchSlideAction()
            }))
          }
          div(classes = "cell") {
            +"/"
          }
          div(classes = "cell") {
            include(NumberInput("effect_seminotes_lines",
                value = pitchSlide.lines,
                step = 1,
                min = 1,
                max = 72,
                changeHandler = {
              pitchSlide.lines = it
              updatePitchSlideAction()
            }))
          }
        }
      }
    }

    div(classes = "row") {
      div(classes = "cell") {
        + " "
      }
    }
  }
}
