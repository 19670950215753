package daw.view

import daw.AudioHandler
import daw.Daw
import daw.Settings
import daw.html.button
import daw.html.cell
import daw.html.clear
import daw.html.cls
import daw.html.create
import daw.html.div
import daw.html.elem
import daw.html.hasElem
import daw.html.id
import daw.html.main
import daw.html.txt
import daw.html.with
import daw.input.PatternListInput
import daw.input.TrackListInput
import daw.instrument.Instrument
import daw.view.instrument.InstrumentComponent
import daw.view.instrument.InstrumentView
import daw.view.menu.MenuView
import nl.astraeus.komp.Komponent
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import kotlin.browser.document
import kotlin.dom.addClass
import kotlin.dom.removeClass

/**
 * Created by rnentjes on 12-12-15.
 */

object View {
  val instrumentComponent = InstrumentComponent(instrument = Instrument(Daw.song.songId, -1))

  fun render() {
    SongView.renderSongInfo()
    renderButtons()
    PatternsView.renderPatternList()
    renderPatternButtons()
    PatternView.renderTracks()
    MenuView.renderMenu()
    //MenuView.renderTopMenu()

    //renderExample("component_example")
    Komponent.create(document.body!!, instrumentComponent)
  }

  fun setConnectionStatus(message: String) {
    elem("status").textContent = message
  }

  fun updateDirty(dirty: Boolean) {
    val ds = elem("dirty_status")

    if (Daw.user == null) {
      ds.removeClass("clean")
      ds.txt("Not logged in!").cls("danger")
    } else {

      if (dirty) {
        ds.removeClass("clean")
        ds.txt("Modified").cls("dirty")
      } else {
        ds.removeClass("dirty")
        ds.txt("Saved").cls("clean")
      }
    }
  }

  fun showError(message: String) {
    println("ERROR: $message")
    alert(message)
  }

  fun showMessage(message: String) {
    elem("message").textContent = message
  }

  fun renderButtons() {
    if (!hasElem("buttons")) {
      main().with(div().id("buttons").cls("buttonrow"))
    }

    clear("buttons")

    val buttons = elem("buttons")

    val playPattern = div().cls("button").txt("Play pattern") as HTMLDivElement
    val playAll = div().cls("button").txt("Play song") as HTMLDivElement
    val stop = div().cls("button").txt("Stop playing") as HTMLDivElement
    val addInstrument = div().cls("button").txt("Add instrument") as HTMLDivElement

    playPattern.onclick = {
      Daw.song.playPattern(Daw.song.selectedPattern)
    }

    playAll.onclick = {
      Daw.song.play()
    }

    stop.onclick = {
      Daw.song.stop()
    }

    addInstrument.onclick = {
      InstrumentView.renderInstrumentModal(-1)
      //instrumentComponent.show()
    }

    buttons.with(cell(playPattern))
    buttons.with(cell(playAll))
    buttons.with(cell(stop))
    buttons.with(cell(addInstrument))
  }

  fun renderPatternButtons() {
    if (!hasElem("pattern_buttons")) {
      main().with(div().id("pattern_buttons").cls("buttonrow"))
    }

    clear("pattern_buttons")

    val buttons = elem("pattern_buttons")

    val tracker = div().cls("button").txt("Tracker") as HTMLDivElement
    val sequencer = div().cls("button").txt("Sequencer") as HTMLDivElement

    tracker.onclick = {
      PatternView.sequencer = false
      PatternView.renderTracks()
    }

    sequencer.onclick = {
      PatternView.sequencer = true
      PatternView.renderTracks(true)
    }

    buttons.with(cell(tracker))
    buttons.with(cell(sequencer))
  }

  // chow confirm dialog, executes yes response immediaty when confirmed = true
  fun confirm(message: String, function: () -> Unit, confirmed: Boolean = false, cleanup: () -> Unit = {}) {
    if (confirmed) {
      function()
      cleanup()
    } else {
      clear("confirm")

      val confirm = elem("confirm")
      val confirm_layer = elem("confirm_layer")

      confirm.addClass("show")
      confirm_layer.addClass("show")

      val ok = button().txt("Ok") as HTMLButtonElement
      val cancel = button().txt("Cancel") as HTMLButtonElement

      ok.onclick = {
        function()
        cleanup()

        confirm.removeClass("show")
        confirm_layer.removeClass("show")
      }

      cancel.onclick = {
        cleanup()

        confirm.removeClass("show")
        confirm_layer.removeClass("show")
      }

      confirm.with(create("h1").txt("CONFIRM"))
      confirm.with(create("hr"))
      confirm.with(div().txt(message))
      confirm.with(create("hr"))
      confirm.with(ok)
      confirm.with(cancel)
    }
  }

  fun alert(message: String) {
/*
    clear("confirm")
    val confirm = elem("confirm")
    val confirm_layer = elem("confirm_layer")

    confirm.addClass("show")
    confirm_layer.addClass("show")

    confirm.with(create("h1").txt("CONFIRM"))
    confirm.with(div().txt(message))
    confirm.with(create("hr"))
    val ok = button().txt("Ok") as HTMLButtonElement

    ok.onclick = {
      confirm.removeClass("show")
      confirm_layer.removeClass("show")
    }

    confirm.with(ok)
*/
    val messageElement = document.getElementById("message")

    messageElement?.textContent = message
  }

  fun playingPattern(currentPattern: Int) {
    PatternListInput.selectPosition(currentPattern, 0)
  }

  fun playingEntry(entry: Int) {
    if (Settings.displayTracksDuringPlayback || !AudioHandler.isPlaying()) {
      TrackListInput.playingPosition(entry)
    }
  }

}
