package daw.view.effect

import daw.html.attr
import daw.html.elem
import daw.input.TrackListInput
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.div
import kotlinx.html.h1
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onClickFunction
import kotlinx.html.style
import kotlinx.html.unsafe
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include
import org.w3c.dom.HTMLInputElement
import kotlin.browser.document

/**
 * User: rnentjes
 * Date: 21-2-16
 * Time: 11:45
 */

class EffectTitle(
    val title: String,
    var active: Boolean = false,
    val switchCallback: (active: Boolean) -> Unit
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "row") {
    if (active) {
      classes += "active_effects_title"
    } else {
      classes += "effects_title"
    }
    div(classes = "cell effects_title_cell") { +this@EffectTitle.title }
    div(classes = "cell effects_title_cell") {
      + " "
    }
    div(classes = "cell effects_title_cell") {
      style = "float: right;"

      input {
        type = InputType.checkBox

        checked = active

        onClickFunction = { e ->
          val cb = e.target
          if (cb is HTMLInputElement) {
            active = cb.checked

            switchCallback(active)
          }
        }
      }
    }
  }
}

class EffectComponent : Komponent() {
  var visible = false

  val volumeComponent = VolumeComponent()
  val pitchSlideComponent = PitchSlideComponent()
  val panningComponent = PanningComponent()
  val arpeggioComponent = ArpeggioComponent()

  override fun render(consumer: KompConsumer) = consumer.div {
    val main = elem("main")
    val menu = elem("menu")
    val fx_menu = elem("fx_menu")

    id = "effects"

    if (this@EffectComponent.visible) {
      classes += "show"
    }

    div(classes = "bigtable") {
      id = "effects_content"

      if (this@EffectComponent.visible) {
        main.attr("style", "margin-left: 300px;")
        menu.attr("style", "margin-left: 300px;")
        fx_menu.attr("style", "margin-left: 290px;")

        h1 {
          + "EFFECTS"
        }

        include(volumeComponent)
        include(pitchSlideComponent)
        include(panningComponent)
        include(arpeggioComponent)
      } else {
        main.attr("style", "")
        menu.attr("style", "")
        fx_menu.attr("style", "")
      }
    }
  }

  fun updateEffects() {
    val trackEffects = TrackListInput.getEffects()

    if (trackEffects == null) {
      volumeComponent.updateVolume(false, 1f, false)
      pitchSlideComponent.updatePitchSlide(false, 0, 0)
      panningComponent.updatePanning(false, 0f)
      arpeggioComponent.updateArpeggio(false, 0, 1)
    } else {
      volumeComponent.updateVolume(
          trackEffects.setVolume != null,
          trackEffects.setVolume?.volume ?: 1f,
          trackEffects.setVolume?.retrigger ?: true
      )
      pitchSlideComponent.updatePitchSlide(
          trackEffects.pitchSlide != null,
          trackEffects.pitchSlide?.semiNotes ?: 0,
          trackEffects.pitchSlide?.lines ?: 1
      )
      panningComponent.updatePanning(
          trackEffects.panning != null,
          trackEffects.panning?.panning ?: 0f
      )
      arpeggioComponent.updateArpeggio(
          trackEffects.arpeggio != null,
          trackEffects.arpeggio?.notes ?: 0,
          trackEffects.arpeggio?.lines ?: 0
      )
    }

    refresh()
  }
}

class EffectFxView : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "fx_menu") {
    id = "fx_menu"

    div(classes="fx_menu_title") {
      + "Fx"

      onClickFunction = {
        EffectView.switchView()
      }
    }
  }

}

object EffectView {
  val effectComponent = EffectComponent()
  val effectFxView = EffectFxView()

  init {
    Komponent.create(document.body!!, effectFxView)
    Komponent.create(document.body!!, effectComponent)
  }

  fun hide() {
    effectComponent.visible = false
    effectComponent.refresh()
  }

  fun show() {
    effectComponent.visible = true
    effectComponent.refresh()
  }

  fun update() {
    effectComponent.updateEffects()
  }

  fun switchView() {
    if (effectComponent.visible) {
      hide()
    } else {
      show()
    }
  }

  fun focus() {
    if (effectComponent.visible) {
      effectComponent.updateEffects()
    }
  }

  fun unfocus() {
    effectComponent.updateEffects()
  }
}
