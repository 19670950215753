package daw.html

import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent
import kotlin.math.PI
import kotlin.math.atan2
import kotlin.math.max
import kotlin.math.min

/**
 * Created by rien on 10-9-16.
 */

private val START_ANGLE = PI * 0.7
private val END_ANGLE = PI * 0.3
private val ANGLE_RANGE = PI * 1.6

private val CIRCLE_CENTRE_X = 25.0
private val CIRCLE_CENTRE_Y = 35.0
private val CIRCLE_RADIUS = 18.0
private val CIRCLE_ARC_WIDTH = 6.0

object Knob {

  fun create(value: Float = 1f,
             ringColor: String = "#4b4b4b",
             volumeColor: String = "#fb4b4b",
             backgroundColor: String = "#1a1a1a",
             label: String = "",
             minValue: Float = 0f,
             maxValue: Float = 5f,
             step: Float = 0.05f,
             callback: (value: Double) -> Unit = {}
  ): Element {
    val canvas = create("canvas") as HTMLCanvasElement

    canvas.cls("notextselect")

    canvas.attr("data-dt", "volumeknob")
    canvas.attr("data-label", label)
    canvas.attr("data-min-value", "$minValue")
    canvas.attr("data-max-value", "$maxValue")
    canvas.attr("data-step", "$step")
    canvas.attr("data-value", "$value")

    canvas.attr("data-ring-color", ringColor)
    canvas.attr("data-volume-color", volumeColor)
    canvas.attr("data-background-color", backgroundColor)

    canvas.attr("data-activated", "false")

    canvas.width = 50
    canvas.height = 60

    canvas.onscroll = {
      if (it is WheelEvent) {
        val volumeKnob = it.target

        if (volumeKnob is HTMLCanvasElement) {
          val delta = it.deltaY / 250.0

          var newValue = getValue(volumeKnob) - delta

          newValue = min(newValue, maxValue.toDouble())
          newValue = max(newValue, minValue.toDouble())

          volumeKnob.setAttribute("data-value", "$newValue")

          callback(newValue)

          render(volumeKnob)

          it.preventDefault()
        }
      }
    }

    canvas.onmousedown = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "true")
        setValueByMouseCoords(it, minValue, maxValue, callback)
      }
    }

    canvas.onmousemove = {
      if (it is MouseEvent) {
        if (canvas.getAttribute("data-activated") == "true" && it.buttons == 1.toShort()) {
          setValueByMouseCoords(it, minValue, maxValue, callback)
        }
      }
    }

    canvas.onmouseup = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "false")
      }
    }

    canvas.onmouseleave = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "false")
      }
    }

    render(canvas)

    return canvas
  }

  private fun setValueByMouseCoords(it: MouseEvent,
                                    minValue: Float = 0f,
                                    maxValue: Float = 5f,
                                    callback: (value: Double) -> Unit) {
    val volumeKnob = it.target

    if (volumeKnob is HTMLCanvasElement) {
      val deltaX = it.offsetX - CIRCLE_CENTRE_X
      val deltaY = it.offsetY - CIRCLE_CENTRE_Y

      var angle = atan2(deltaX, deltaY)

      if (angle < 0) {
        angle = -angle
      } else {
        angle = PI * 2.0 - angle
      }

      angle += PI / 2.0

      if (angle > PI * 2.0) {
        angle -= PI * 2.0
      }

      var newValue = minValue

      if (angle > START_ANGLE) {
        newValue = minValue + ((maxValue - minValue) / ANGLE_RANGE * (angle - START_ANGLE)).toFloat()
      } else if (angle < END_ANGLE) {
        val calcAngle = angle + PI * 2.0
        newValue = minValue + ((maxValue - minValue) / ANGLE_RANGE * (calcAngle - START_ANGLE)).toFloat()
      } else if (angle < PI / 2.0) {
        newValue = maxValue
      }

      volumeKnob.setAttribute("data-value", "$newValue")

      callback(newValue.toDouble())

      render(volumeKnob)

      it.preventDefault()
    }
  }

  fun getValue(knob: Element): Double {
    return (
        knob.getAttribute("data-value") ?: throw IllegalStateException("data-value attribute not found on volume knob!")
        ).toDouble()
  }

  fun render(knob: Element) {
    if (knob.getAttribute("data-dt") != "volumeknob") {
      throw IllegalArgumentException("Given element is not a volume knob!")
    }

    val canvas = knob as HTMLCanvasElement

    val minValue = (knob.getAttribute("data-min-value")!!).toDouble()
    val maxValue = (knob.getAttribute("data-max-value")!!).toDouble()

    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    val value = getValue(knob)
    val volume = (ANGLE_RANGE / (maxValue - minValue) * (value - minValue))

    val width = canvas.width.toDouble()
    val height = canvas.height.toDouble()

    val volumeColor = knob.getAttribute("data-volume-color")
    val label = knob.getAttribute("data-label")

    ctx.fillStyle = knob.getAttribute("data-background-color")
    ctx.fillRect(0.0, 0.0, width, height)

    ctx.beginPath()
    ctx.strokeStyle = knob.getAttribute("data-ring-color")
    ctx.lineWidth = CIRCLE_ARC_WIDTH
    ctx.arc(CIRCLE_CENTRE_X, CIRCLE_CENTRE_Y, CIRCLE_RADIUS, START_ANGLE, START_ANGLE + ANGLE_RANGE)
    ctx.stroke()

    ctx.beginPath()
    ctx.strokeStyle = volumeColor
    ctx.arc(CIRCLE_CENTRE_X, CIRCLE_CENTRE_Y, CIRCLE_RADIUS, START_ANGLE, START_ANGLE + volume)
    ctx.stroke()

    ctx.font = "bold 12px Inconsolata"
    ctx.fillStyle = volumeColor

    val text = "${value.toFloat().toFixed(2)}" //formatFloat(value.toFloat(), 2)
    var textWidth = ctx.measureText(text)
    ctx.fillText(text, (width / 2) - textWidth.width / 2.0, (height / 2) + 9.0)

    if (label != null && label != "") {
      ctx.font = "bold 14px Inconsolata"
      textWidth = ctx.measureText(label)
      ctx.fillText(label, (width / 2) - textWidth.width / 2.0, 10.0)
    }
  }
}
