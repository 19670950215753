package daw.instrument

import kotlin.math.sqrt

/**
 * Created by rnentjes on 14-11-15.
 */

abstract class Envelope(val name: String) {

  abstract fun getVolume(time: Float, noteUp: Float): Float

}

open class EnvelopePlayer(val env: Envelope) {
  var lastValue = 0f
  var noteUp: Float = -1f
  var muted = false

  open fun noteStart() {
    noteUp = -1f
  }

  open fun getVolume(time: Float): Float {
    var result = 0f;

    if (!muted) {
      result = env.getVolume(time, noteUp)
    }

    result = (result + lastValue * 49) / 50f
    lastValue = result
    return result
  }

  open fun noteUp(time: Float) {
    this.noteUp = time
  }

  open fun mute() {
    muted = true
  }

  open fun unMute() {
    muted = false
  }

  fun isNoteUp(): Boolean {
    return noteUp > 0f
  }

}

class NoneEnvelope : Envelope("None") {

  override fun getVolume(time: Float, noteUp: Float): Float {
    if (time > noteUp && noteUp >= 0f) {
      return 0f
    } else {
      return 1f
    }
  }

}

class ADSREnvelope(
    val attackLevel: Float,
    val decayLevel: Float,
    val attackTime: Float,
    val decayTime: Float,
    val releaseTime: Float) : Envelope("ARSD") {

  override fun getVolume(time: Float, noteUp: Float): Float {
    var result = 0f
    var ct = time

    if (ct < attackTime) {
      result = attackLevel * ct / attackTime;
    } else {
      ct -= attackTime
      if (ct < decayTime) {
        val delta = attackLevel - decayLevel
        result = decayLevel + (delta * (1 - (ct / decayTime)));
      } else {
        ct -= decayTime
        var noteUpTime = noteUp
        if (attackTime + decayTime > noteUpTime) {
          noteUpTime = attackTime + decayTime
        }
        if (noteUp < 0f || time < noteUpTime) {
          result = decayLevel;
        } else {
          ct = time - noteUp
          if (ct < releaseTime) {
            result = decayLevel * (1 - (ct / releaseTime));
          }
        }
      }
    }

    return result
  }
}

class EnvelopePoint(var x: Float, var y: Float)

fun dist(x1: Double, y1: Double, x2: Double, y2: Double) = sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2))

class ManualEnvelope(val instrument: Instrument) : Envelope("MANUAL") {
  var buffer = FloatArray(0, { 0f })
  var loopStartTime = 0f
  var loopEndTime = 0f

  init {
    if (instrument.envelope.isEmpty()) {
      resetEnvelope()
    }

    recalculate()
  }

  fun resetEnvelope() {
    instrument.envelope = Array(5, { EnvelopePoint(0f, 0f) })

    instrument.envelope[0] = EnvelopePoint(0f, 0.25f)
    instrument.envelope[1] = EnvelopePoint(0.5f, 0.9f)
    instrument.envelope[2] = EnvelopePoint(1f, 0.75f)
    instrument.envelope[3] = EnvelopePoint(1.5f, 0.75f)
    instrument.envelope[4] = EnvelopePoint(2f, 0.25f)

    instrument.envelopeLength = 2f
    instrument.envelopeLoopStart = 2
    instrument.envelopeLoopEnd = 3
  }

  private fun recalculate() {
    buffer = FloatArray((instrument.envelopeLength * 250).toInt())

    for (index in 0 until buffer.size) {
      buffer[index] = getVolumeInternal((index / buffer.size.toFloat()) * instrument.envelopeLength, 0f)
    }

    // todo: fix this situation occurring
    if (instrument.envelopeLoopStart >= instrument.envelope.size) {
      instrument.envelopeLoopStart = 1
    }

    // todo: fix this situation occurring
    if (instrument.envelopeLoopEnd >= instrument.envelope.size) {
      instrument.envelopeLoopEnd = instrument.envelope.size - 2
    }

    loopStartTime = instrument.envelope[instrument.envelopeLoopStart].x
    loopEndTime = instrument.envelope[instrument.envelopeLoopEnd].x
  }

  private fun readBuffer(time: Float): Float {
    val index = (time * 250).toInt()

    if (index < buffer.size) {
      return buffer[index]
    } else {
      return 0f
    }
  }

  override fun getVolume(time: Float, noteUp: Float): Float {
    if (time < loopStartTime) {
      val result = readBuffer(time)
      //println("VOLUME: ${result}")
      return result
    } else if (noteUp > 0 && time > noteUp) {
      var calcNoteUpTime = noteUp
      while (calcNoteUpTime > loopEndTime) {
        calcNoteUpTime -= (loopEndTime - loopStartTime)
      }
      calcNoteUpTime += (time - noteUp)
      val result = readBuffer(calcNoteUpTime)

      return result

    } else {
      var calcTime = time
      while (calcTime > loopEndTime) {
        calcTime -= (loopEndTime - loopStartTime)
      }
      val result = readBuffer(calcTime)

      return result
    }
  }

  fun getVolumeInternal(time: Float, noteUp: Float): Float {
    var result = 1f

    var index = 0
    val hasLoop = instrument.envelopeLoopStart > 0
    var found = false
    var loopLength = 0f
    var loopTime = 0f

    if (hasLoop) {
      // todo: fix this situation occurring
      if (instrument.envelopeLoopEnd < instrument.envelope.size && instrument.envelopeLoopStart < instrument.envelope.size) {
        loopLength = instrument.envelope[instrument.envelopeLoopEnd].x - instrument.envelope[instrument.envelopeLoopStart].x
      }
    }

    while (!found) {
      if (index > instrument.envelope.size - 2) {
        found = true
        result = 0f
      } else {
        val startTime = instrument.envelope[index].x + loopTime
        val endTime = instrument.envelope[index + 1].x + loopTime

        if (startTime <= time && endTime > time) {
          found = true
          result = findVolume(time - loopTime, instrument.envelope[index], instrument.envelope[index + 1])
        }

        if (!found) {
          if (hasLoop && index == instrument.envelopeLoopEnd - 1 && endTime <= time && (endTime < noteUp || noteUp < 0)) {
            loopTime += loopLength
            index = instrument.envelopeLoopStart
          } else {
            index++
          }
        }
      }
    }

    return result
  }

  fun findVolume(time: Float, point1: EnvelopePoint, point2: EnvelopePoint): Float {
    if (time > point2.x || time < point1.x) {
      throw IllegalArgumentException("ERROR: Time out of bounds! (Envelope.findVolume(...))")
    }

    val delta = (point2.y - point1.y) / (point2.x - point1.x)

    return point1.y + (time - point1.x) * delta
  }

  fun findPoint(startX: Float, startY: Float): Int {
    var closestPoint = -1
    var closest = 1000000.0

    for (index in 0 until instrument.envelope.size) {
      val point = instrument.envelope[index]
      val dist = dist(startX.toDouble(), startY.toDouble(), point.x.toDouble(), point.y.toDouble())

      if (dist < 0.025f && dist < closest) {
        closest = dist
        closestPoint = index
      }
    }

    return closestPoint
  }

  fun insertPoint(startX: Float, startY: Float) {
    var insertAfter = -1

    for (index in 0 until instrument.envelope.size) {
      val point = instrument.envelope[index]

      if (point.x < startX) {
        insertAfter = index
      }
    }

    if (insertAfter >= 0) {
      val oldEnvelope = instrument.envelope
      instrument.envelope = Array(instrument.envelope.size + 1, { EnvelopePoint(0f, 0f) })
      // insert point
      for (index in 0..insertAfter) {
        instrument.envelope[index] = oldEnvelope[index]
      }
      instrument.envelope[insertAfter + 1] = EnvelopePoint(startX.toFloat(), startY.toFloat())
      for (index in insertAfter + 2 until instrument.envelope.size) {
        instrument.envelope[index] = oldEnvelope[index - 1]
      }
    }

    if (insertAfter < instrument.envelopeLoopEnd) {
      instrument.envelopeLoopEnd++
    }
    if (insertAfter < instrument.envelopeLoopStart) {
      instrument.envelopeLoopStart++
    }

    recalculate()
  }

  fun removePoint(point: Int) {
    if (point > 0 && instrument.envelope.size > point + 1 && point != instrument.envelopeLoopStart && point != instrument.envelopeLoopEnd) {
      val newEnvelope = Array(instrument.envelope.size - 1, { EnvelopePoint(0f, 0f) })

      for (index in 0 until newEnvelope.size) {
        if (index < point) {
          newEnvelope[index] = instrument.envelope[index]
        } else {
          newEnvelope[index] = instrument.envelope[index + 1]
        }
      }

      if (instrument.envelopeLoopStart > point) {
        instrument.envelopeLoopStart--
      }
      if (instrument.envelopeLoopEnd > point) {
        instrument.envelopeLoopEnd--
      }

      instrument.envelope = newEnvelope
    } else {
      // TODO: make fail sound
    }

    recalculate()
  }

  fun updatePoint(point: Int, x: Float, y: Float) {
    if (instrument.envelope.size > point) {
      instrument.envelope[point].x = x
      instrument.envelope[point].y = y

      recalculate()
    }
  }

}
