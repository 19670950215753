package daw.view.effect

import daw.component.NumberInput
import daw.input.TrackListInput
import daw.song.effects.ArpeggioType
import daw.song.effects.SetArpeggio
import daw.view.PatternView
import kotlinx.html.div
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include

/**
 * Created by rnentjes on 6-3-17.
 */
class ArpeggioComponent : Komponent() {
  val title = EffectTitle("Arpeggio:") {
    updateArpeggioActionAction()

    refresh()
  }

  var setArpeggio: SetArpeggio = SetArpeggio(ArpeggioType.UP, 0, 1)

  fun updateArpeggio(active: Boolean, notes: Int, lines: Int) {
    this.title.active = active
    this.setArpeggio.notes = notes
    this.setArpeggio.lines = lines
  }

  fun updateArpeggioActionAction() {
    val effects = TrackListInput.getEffects()

    if (this.title.active) {
      effects?.arpeggio = effects?.arpeggio ?: SetArpeggio(ArpeggioType.UP, 0, 1)
      effects?.arpeggio?.notes = setArpeggio.notes
      effects?.arpeggio?.lines = setArpeggio.lines
    } else {
      effects?.arpeggio = null
    }

    PatternView.updateEntry()
    TrackListInput.setSelectedTrackDirty()
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes = "bigtable") {

    include(this@ArpeggioComponent.title)

    if (this@ArpeggioComponent.title.active) {
      effect("Notes/line:") {
        div(classes = "row") {
          div(classes = "cell") {
            include(NumberInput("effect_seminotes",
                value = setArpeggio.notes,
                step = 1,
                min = -72,
                max = 72,
                changeHandler = {
              setArpeggio.notes = it

              updateArpeggioActionAction()
            }))
          }
          div(classes = "cell") {
            +"/"
          }
          div(classes = "cell") {
            include(NumberInput("effect_seminotes_lines",
                value = setArpeggio.lines,
                step = 1,
                min = 1,
                max = 72,
                changeHandler = {
              setArpeggio.lines = it

              updateArpeggioActionAction()
            }))
          }
        }
      }
    }

    div(classes = "row") {
      div(classes = "cell") {
        + " "
      }
    }
  }
}
