package daw.view.menu

import daw.Daw
import daw.view.Modal
import daw.view.View
import daw.view.cell
import daw.view.emptyRow
import daw.view.row
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.div
import kotlinx.html.h1
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.label
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 14:12
 */

class ImportView: Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div {
    h1 {
      + "IMPORT MOD"
    }

    row {
      cell {
        label(classes = "button") {
          htmlFor = "load_mod"
          +"Select mod file..."
        }
      }
    }
    row {
      cell {
        input(classes = "inputfile") {
          name = "load_mod"
          id = "load_mod"
          value = ""
          type = InputType.file
          accept = ".mod,audio/x-mod"

          onChangeFunction = this@ImportView::fileInputSelectHandler
        }
      }
    }

    emptyRow()

    row {
      cell { + "" }
      cell {
        div(classes = "button") {
          classes += "button"
          +"Close"
          onClickFunction = {
            Modal.hide()
          }
        }
      }
    }
  }

  private fun fileInputSelectHandler(event: Event) {
    val sel = event.target
    if (sel is HTMLInputElement) {
      if (sel.files != null) {
        val list: FileList = sel.files!!
        if (list.length == 1) {
          val file = list[0]
          if (file != null) {
            val reader = FileReader()

            reader.onload = {
              val result = reader.result
              if (result is ArrayBuffer) {
                Daw.importing {
                  daw.import.Mod.load(result)
                }

                View.render()
              }
            }

            reader.readAsArrayBuffer(file)
          }
        } else {
          View.showError("ERROR!")
        }
      }
    }
  }

}
