package daw.player

import daw.instrument.InstrumentEffectState
import daw.note.Note
import daw.song.InstrumentTrack
import kotlin.js.Date

class InstrumentPlayer(val instrument: InstrumentTrack, numberOfChannels: Int) : Player {
  var stopped = false
  val effectState = InstrumentEffectState(instrument.instrument, numberOfChannels)

  var instruments: Array<InstrumentChannelPlayer> = Array(numberOfChannels) {
    createChannelPlayer(this, 0, numberOfChannels)
  }
  var notes: Array<Note> = Array(numberOfChannels) { Note.NONE }
  var played: Array<Date> = Array(numberOfChannels) { Date() }

  init {
    for ((index, instrument) in instruments.withIndex()) {
      instrument.noteStart(Note.END)
      instrument.channel = index
    }
  }

  override fun stop() {
    stopped = true
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, count: Int) {
    var first = true
    instruments.forEach {
      it.fillBuffer(left, right, 0, count, first)
      first = false
    }
  }

  fun findInstrumentPlayer(note: Note): Int {
    for (index in notes.indices) {
      if (notes[index] == note) {
        return index
      }
    }

    return -1
  }

  fun getNextPlayer(): Int {
    var oldest = -1

    for (index in 0 until notes.size) {
      if (notes[index] == Note.NONE) {
        return index
      } else if (oldest == -1 || played[index].getTime() < played[oldest].getTime()) {
        oldest = index
      }
    }

    return oldest
  }

  fun playNote(note: Note) {
    var instr = findInstrumentPlayer(note)

    if (instr == -1) {
      instr = getNextPlayer()
    }

    played[instr] = Date()
    notes[instr] = note
    instruments[instr].noteStart(note)
  }

  fun noteUp(note: Note) {
    val instr = findInstrumentPlayer(note)

    if (instr != -1) {
      notes[instr] = Note.UP
      instruments[instr].noteStart(Note.UP)
    }
  }

}
