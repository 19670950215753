package daw.view.menu

import daw.Daw
import daw.view.Modal
import daw.ws.WebsocketConnection
import kotlinx.html.div
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 12:15
 */

class LoginMenu: Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "login_menu") {
    id = "menu"

    div(classes = "login_menu_title") {
      if (Daw.user == null) {
        +"LOGIN"

        onClickFunction = {
          Modal.show(LoginView())
        }
      } else {
        +"LOGOUT"

        onClickFunction = {
          // todo: LOGOUT
          Daw.user = null
          WebsocketConnection.sendLogout()

          MenuView.renderMenu()
        }
      }
    }
  }

}
