package daw.model

import daw.song.Song
import daw.song.SongInfo

/**
 * User: rnentjes
 * Date: 26-6-16
 * Time: 11:46
 */

object DawModel {

  /* User song list */
  var songList: Array<SongInfo>? = null

  /* Song */
  var song: Song = Song("", "", "", 120)

  /* Song view */
  var currentPattern: Int = -1
  var currentInstrument: Int = -1
  var currentEntry: Int = -1

  /* Menu */
  var showMenu = false
  var showFxMenu = false

  /* Instrument */
  var showInstrumentEditor = false

}
