package daw.instrument

import daw.AudioHandler
import daw.Daw
import daw.html.Select
import daw.note.Note
import daw.ws.WebsocketConnection
import org.khronos.webgl.ArrayBuffer
import kotlin.js.Math.random
import kotlin.math.PI
import kotlin.math.sin

object BasicWaveForms {
  var square = Sample()
  var sinus = Sample()
  var triangle = Sample()
  var sawtooth = Sample()
  var noise = Sample()

  fun createWaves() {
    square = Sample(ArrayBuffer(2700 * 2), false, AudioHandler.sampleRate, Note.C3, "Square")
    sinus = Sample(ArrayBuffer(2700 * 2), false, AudioHandler.sampleRate, Note.C3, "Sinus")
    triangle = Sample(ArrayBuffer(2700 * 2), false, AudioHandler.sampleRate, Note.C3, "Triangle")
    sawtooth = Sample(ArrayBuffer(2700 * 2), false, AudioHandler.sampleRate, Note.C3, "Sawtooth")
    noise = Sample(ArrayBuffer(2700 * 2), false, AudioHandler.sampleRate, Note.C3, "Noise")

    createSquare()
    createSinus()
    createTriangle()
    createSawtooth()
    createNoise()
  }

  fun createSquare() {
    val dataView = square.dataView
    for (index in 0 until square.samples()) {
      if (index < square.samples() / 2) {
        dataView.setInt16(index * 2, Short.MAX_VALUE, true)
      } else {
        dataView.setInt16(index * 2, Short.MIN_VALUE, true)
      }
    }
  }

  fun createSinus() {
    val dataView = sinus.dataView
    for (index in 0 until sinus.samples()) {
      dataView.setInt16(index * 2, (sin(2.0 * PI * index.toDouble() / sinus.samples().toDouble()) * Short.MAX_VALUE).toShort(), true)
    }
  }

  fun createTriangle() {
    val dataView = triangle.dataView
    val samples = triangle.samples()
    for (index in 0 until samples) {
      if (index < samples * 0.25) {
        dataView.setInt16(index * 2, ((index / samples.toFloat()) * 4 * Short.MAX_VALUE).toShort(), true)
      } else if (index < samples * 0.75f) {
        dataView.setInt16(index * 2, ((1f - (((index - (samples * 0.25f)) / samples.toFloat()) * 4)) * Short.MAX_VALUE).toShort(), true)
      } else {
        dataView.setInt16(index * 2, ((-1f + (((index - (samples * 0.75f)) / samples.toFloat()) * 4)) * Short.MAX_VALUE).toShort(), true)
      }
    }
  }

  fun createSawtooth() {
    val dataView = sawtooth.dataView
    for (index in 0 until sawtooth.samples()) {
      dataView.setInt16(index * 2, ((1f - (index / sawtooth.samples().toFloat()) * 2f) * Short.MAX_VALUE).toShort(), true)
    }
  }

  fun createNoise() {
    val dataView = noise.dataView
    for (index in 0 until noise.samples()) {
      dataView.setInt16(index * 2, (random() * Int.MAX_VALUE).toShort(), true)
    }
  }
}

enum class InstrumentType(val description: String) {
  BASIC("Chip"),
  WAVEFORM("Waveform"),
  SAMPLE("Sample"),
  DRUMS("Drums"),
  ;
}

fun InstrumentTypeSelect(): Array<Select> {
  val result = Array(InstrumentType.values().size, { Select("", "") })

  for (type in InstrumentType.values()) {
    result[type.ordinal] = Select(type.name, type.description)
  }

  return result
}

enum class BasicType(val description: String) {
  SQUARE("Square"),
  SINUS("Sinus"),
  TRIANGLE("Triangle"),
  SAWTOOTH("Sawtooth"),
  NOISE("Noise"),
  MANUAL("Manual"),
  ;
}

fun BasicTypeSelect(): Array<Select> {
  val result = Array(BasicType.values().size, { Select("", "") })

  for (type in BasicType.values()) {
    result[type.ordinal] = Select(type.name, type.description)
  }

  return result
}


enum class EnvelopeType(val description: String) {
  NONE("None"),
  ADSR("Attack-decay-sustain-release"),
  MANUAL("Manual"),
  ;
}

fun EnvelopeTypeSelect(): Array<Select> {
  val result = Array(EnvelopeType.values().size, { Select("", "") })

  for (type in EnvelopeType.values()) {
    result[type.ordinal] = Select(type.name, type.description)
  }

  return result
}

fun instrumentFromInstrumentData(data: InstrumentData): Instrument {
  val result = Instrument(data.songId, data.number)

  result.volume = data.volume
  result.panning = data.panning

  result.type = InstrumentType.valueOf(data.type)
  result.envelopeType = EnvelopeType.valueOf(data.envelopeType)

  result.basicType = BasicType.valueOf(data.basicType)

  result.attackLevel = data.attackLevel
  result.decayLevel = data.decayLevel
  result.attackTime = data.attackTime
  result.decayTime = data.decayTime
  result.releaseTime = data.releaseTime

  result.envelope = data.envelope
  result.envelopeLength = data.envelopeLength
  result.envelopeLoopStart = data.envelopeLoopStart
  result.envelopeLoopEnd = data.envelopeLoopEnd

  result.samples = data.samples

  result.name = data.name
  result.named = data.named

  return result
}

class InstrumentData(val songId: String, var number: Int) {
  var volume = 1f
  var panning = 0f
  var type = ""
  var envelopeType = ""

  // BASIC instrument type data
  var basicType = ""

  // ADST envelope type data
  // 1f, 0.4f, 0.05f, 0.2f, 0.1f
  var attackLevel = 1f
  var decayLevel = 0.4f
  var attackTime = 0.05f
  var decayTime = 0.1f
  var releaseTime = 0.05f

  // MANUAL envelope data
  var envelope = Array(0, { EnvelopePoint(0f, 0f) })
  var envelopeLength = 0f
  var envelopeLoopStart = 0
  var envelopeLoopEnd = 0

  // to store waveform or sample
  var samples = Array(0, { -1 })

  var name = basicType
  var named = false
}

class Instrument(val songId: String, var number: Int) {
  var volume = 1f
  var panning = 0f
  var muted = false

  var type = InstrumentType.BASIC
  var envelopeType = EnvelopeType.MANUAL

  // BASIC instrument type data
  var basicType = BasicType.SQUARE

  // ADST envelope type data
  // 1f, 0.4f, 0.05f, 0.2f, 0.1f
  var attackLevel = 1f
  var decayLevel = 0.4f
  var attackTime = 0.05f
  var decayTime = 0.1f
  var releaseTime = 0.05f

  // MANUAL envelope data
  var envelope = Array(0, { EnvelopePoint(0f, 0f) })
  var envelopeLength = 0f
  var envelopeLoopStart = 0
  var envelopeLoopEnd = 0

  // to store waveform or sample
  var samples: Array<Int> = Array(0, { -1 })

  var name = basicType.name
  var named = false

  fun copy(): Instrument {
    val result = Instrument(songId, number)

    result.volume = volume
    result.panning = panning
    result.type = type
    result.envelopeType = envelopeType
    result.basicType = basicType

    result.attackLevel = attackLevel
    result.decayLevel = decayLevel
    result.attackTime = attackTime
    result.decayTime = decayTime
    result.releaseTime = releaseTime

    result.envelope = envelope
    result.envelopeLength = envelopeLength
    result.envelopeLoopStart = envelopeLoopStart
    result.envelopeLoopEnd = envelopeLoopEnd

    result.samples = samples

    result.name = name
    result.named = named

    return result
  }

  fun data(): InstrumentData {
    val result = InstrumentData(songId, number)

    result.volume = volume
    result.panning = panning
    result.type = type.name
    result.envelopeType = envelopeType.name
    result.basicType = basicType.name

    result.attackLevel = attackLevel
    result.decayLevel = decayLevel
    result.attackTime = attackTime
    result.decayTime = decayTime
    result.releaseTime = releaseTime

    result.envelope = envelope
    result.envelopeLength = envelopeLength
    result.envelopeLoopStart = envelopeLoopStart
    result.envelopeLoopEnd = envelopeLoopEnd

    result.samples = samples //SampleData(songId, number, -1, sample.buffer, sample.sampleName, sample.stereo, sample.sampleNote.name, sample.sampleRate)

    result.name = name
    result.named = named

    return result
  }

  fun description(maxLength: Int = 999): String {
    var result = ""

    if ((number + 1) < 10) {
      result += "0"
    }

    result += (number + 1).toString()

    result += "-"
    if (named) {
      result = name
    } else {
      when (type) {
        InstrumentType.BASIC -> {
          result += type.description
          result += "-"
          result += basicType.description
        }
        else -> {
          result += type.description

          if (samples.isNotEmpty() && samples[0] > -1) {
            val smpl = Daw.song.getSample(samples[0])

            result += "-"
            result += smpl.sampleName
          }
        }
      }
    }

    if (result.length > maxLength - 1) {
      result = result.slice(0..maxLength - 4) + "..."
    }

    return result
  }

  fun save() {
    WebsocketConnection.saveInstrument(data())
  }

  fun updateEnvelopeLength(length: Float) {
    for (index in 0 until envelope.size) {
      envelope[index].x = envelope[index].x * length / envelopeLength
    }

    envelopeLength = length
  }
}

