package daw.view

import daw.Daw
import daw.component.fxSymbol
import daw.html.attr
import daw.html.cell
import daw.html.clear
import daw.html.cls
import daw.html.create
import daw.html.div
import daw.html.elem
import daw.html.hasElem
import daw.html.i
import daw.html.id
import daw.html.main
import daw.html.row
import daw.html.span
import daw.html.tooltip
import daw.html.topcell
import daw.html.trow
import daw.html.txt
import daw.html.with
import daw.input.TrackListInput
import daw.instrument.InstrumentType
import daw.note.Note
import daw.song.Effects
import daw.song.InstrumentTrack
import daw.song.Track
import daw.ws.WebsocketConnection
import kotlinx.html.dom.create
import nl.astraeus.komp.Komponent
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.get
import kotlin.browser.document
import kotlin.dom.addClass
import kotlin.dom.removeClass

/**
 * User: rnentjes
 * Date: 22-12-15
 * Time: 17:13
 */

fun calculateColor(index: Int, nrColors: Int): String {
  val hue = (270 + index * (360 / nrColors)) % 360

  return "hsl($hue, 50%, 50%)"
}

val BLOCK_SIZE = 13

object PatternView {
  var sequencer = false
  var backgroundCanvas = createBackgroundCanvas()

  fun createBackgroundCanvas(): HTMLCanvasElement {
    val canvas = create("canvas") as HTMLCanvasElement
    canvas.id("tracker_bck_canvas")
    canvas.cls("tracker_canvas")
    canvas.width = (Note.values().size - 3) * BLOCK_SIZE + 1
    canvas.height = (Daw.song.entriesPerPattern * BLOCK_SIZE + 1) + BLOCK_SIZE * 3
    canvas.attr("style", "overflow: auto;")

    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    for (index in 0..Note.values().size - 4) {
      if (
          (index % 12 == 1) ||
          (index % 12 == 3) ||
          (index % 12 == 6) ||
          (index % 12 == 8) ||
          (index % 12 == 10)
      ) {
        ctx.fillStyle = "hsl(0, 0%, 10%)"
      } else {
        ctx.fillStyle = "hsl(0, 0%, 15%)"
      }
      ctx.fillRect(
          BLOCK_SIZE * index.toDouble() + 1,
          BLOCK_SIZE * 2.0,
          BLOCK_SIZE.toDouble(),
          canvas.height.toDouble()
      )
    }

    for (index in 0..Daw.song.entriesPerPattern + 1) {
      if (index % Daw.song.linesPerBeat == 1) {
        ctx.fillStyle = "hsl(0, 0%, 45%)"
      } else {
        ctx.fillStyle = "hsl(0, 0%, 30%)"
      }

      ctx.fillRect(0.0, BLOCK_SIZE * 2.0 + BLOCK_SIZE * index.toDouble(), canvas.width.toDouble(), 1.0)
    }

    for (index in 0..Note.values().size - 3) {
      if (index % 12 == 0) {
        ctx.fillStyle = "hsl(0, 0%, 45%)"
      } else {
        ctx.fillStyle = "hsl(0, 0%, 30%)"
      }

      ctx.fillRect(BLOCK_SIZE * index.toDouble(), 0.0, 1.0, canvas.height.toDouble())
    }

    for (index in 0..Note.values().size - 4) {
      if (
          (index % 12 == 1) ||
          (index % 12 == 3) ||
          (index % 12 == 6) ||
          (index % 12 == 8) ||
          (index % 12 == 10)
      ) {
        ctx.fillStyle = "hsl(0, 100%, 100%)"
        ctx.fillRect(BLOCK_SIZE * index.toDouble(), 1.0, BLOCK_SIZE.toDouble() + 2.0, BLOCK_SIZE * 3.0 - 1.0)

        ctx.fillStyle = "hsl(0, 0%, 0%)"
        ctx.fillRect(BLOCK_SIZE * index.toDouble() + BLOCK_SIZE * 0.5, 1.0, 1.0, BLOCK_SIZE * 3.0)

        ctx.fillStyle = "hsl(0, 0%, 0%)"
        ctx.fillRect(BLOCK_SIZE * index.toDouble() + 1.0, 1.0, BLOCK_SIZE * 1.0 - 2.0, BLOCK_SIZE * 2.0)
      } else {
        ctx.fillStyle = "hsl(0, 100%, 100%)"
        ctx.fillRect(BLOCK_SIZE * index.toDouble() + 1.0, 1.0, BLOCK_SIZE.toDouble() - 1.0, BLOCK_SIZE * 3.0 - 1.0)
      }
    }

    return canvas
  }

  fun renderTracks(clear: Boolean = false) {
    if (!hasElem("tracks")) {
      main().with(div().id("tracks").cls("tracks"))
    }

    if (clear) {
      backgroundCanvas = createBackgroundCanvas()
      clear("tracks")
    }

    if (sequencer) {
      clear("tracks")
      val pattern = elem("tracks")

      if (!hasElem("tracker_canvas")) {
        val canvas = create("canvas") as HTMLCanvasElement
        canvas.id("tracker_canvas")
        canvas.cls("tracker_canvas")
        canvas.width = (Note.values().size - 3) * BLOCK_SIZE + 1
        canvas.height = (Daw.song.entriesPerPattern * BLOCK_SIZE + 1) + BLOCK_SIZE * 3
        canvas.attr("style", "overflow: auto;")

        canvas.onclick = { e ->
          if (e is MouseEvent) {
            println("Click ${e.offsetX}, ${e.offsetY}")
          }
        }

        pattern.with(backgroundCanvas)
        pattern.with(canvas)
      }

      val canvas = elem("tracker_canvas") as HTMLCanvasElement
      val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

      ctx.globalCompositeOperation = "lighter"

      for (ip in Daw.song.instruments) {
        if (ip.instrument.type != InstrumentType.DRUMS) {
          ctx.fillStyle = calculateColor(ip.instrument.number, Daw.song.instruments.size)

          if (ip.show && ip.patternList.size > Daw.song.selectedPattern) {
            val trackIndex = ip.patternList[Daw.song.selectedPattern]

            if (trackIndex >= 0 && ip.availablePatterns.size > trackIndex) {
              val track = ip.availablePatterns[trackIndex]

              val channels = ip.numberOfChannels

              for (c in 0 until channels) {
                var lastNote = Note.END

                if (Daw.song.selectedPattern > 0) {
                  val ti = ip.patternList[Daw.song.selectedPattern - 1]
                  if (ti >= 0 && ip.availablePatterns.size > ti) {
                    val tr = ip.availablePatterns[ti]
                    var entry = ip.song.entriesPerPattern
                    do {
                      entry--
                      lastNote = tr.notes[c][entry]
                    } while (entry > 0 && lastNote == Note.NONE)
                  }
                }

                for (y in 0 until ip.song.entriesPerPattern) {
                  val note = track.notes[c][y]
                  if (note == Note.NONE && lastNote != Note.NONE && lastNote != Note.UP && lastNote != Note.END) {
                    ctx.fillRect(
                        lastNote.ordinal * BLOCK_SIZE + 1.0,
                        BLOCK_SIZE * 3.0 + y * BLOCK_SIZE.toDouble(),
                        BLOCK_SIZE - 1.0,
                        BLOCK_SIZE.toDouble()
                    )
                  } else if (note != Note.UP && note != Note.END && note != Note.NONE) {
                    ctx.fillRect(
                        note.ordinal * BLOCK_SIZE + 1.0,
                        BLOCK_SIZE * 3.0 + y * BLOCK_SIZE + 1.0,
                        BLOCK_SIZE - 1.0,
                        BLOCK_SIZE - 1.0
                    )
                    lastNote = note
                  } else if (note != Note.NONE) {
                    lastNote = note
                  }
                }
              }
            }
          }
        }
      }

      ctx.globalCompositeOperation = "source-over"
    } else {
      clear("tracks")
      val pattern = elem("tracks")

      if (Daw.song.instruments.size > 0) {
        val div = div().cls("tracks").id("track_parent")
        val row = trow()

        // for track tracks
        // row.with(renderTrack(track))

        for (ip in Daw.song.instruments) {
          if (ip.patternList.size > Daw.song.selectedPattern) {
            val track = ip.patternList[Daw.song.selectedPattern]
            if (track >= 0 && ip.availablePatterns.size > track) {
              Komponent.create(row as HTMLElement, InstrumentPatternView(ip,  ip.availablePatterns[track], ip.maxPattern()))
              //row.with(renderPattern(ip, ip.availablePatterns[track], ip.maxPattern()))
            } else {
              Komponent.create(row as HTMLElement, InstrumentPatternView(ip,  null, ip.maxPattern()))
            }
          } else {
            Komponent.create(row as HTMLElement, InstrumentPatternView(ip,  null, ip.maxPattern()))
          }
        }

        div.with(row)
        /*
        div.with(row(cell().txt("&nbsp;")))
        div.with(row(cell().txt("&nbsp;")))
        */

        pattern.with(div)
      }
    }

    TrackListInput.selectCurrentPosition()
  }

  fun updateTracks() {
    if (sequencer) {
      renderTracks()
    } else {
      if (Daw.song.instruments.size > 0) {
        for (ip in Daw.song.instruments) {
          if (ip.show) {
            if (ip.patternList.size > Daw.song.selectedPattern) {
              val track = ip.patternList[Daw.song.selectedPattern]
              if (track >= 0 && ip.availablePatterns.size > track) {
                updateTrack(ip, ip.availablePatterns[track], ip.maxPattern())
              } else {
                updateTrack(ip, null, ip.maxPattern())
              }
            } else {
              updateTrack(ip, null, ip.maxPattern())
            }
          }
        }
      }

      TrackListInput.selectCurrentPosition()
    }
  }

  private fun updateTrack(ip: InstrumentTrack, track: Track?, maxPattern: String) {
    val instrument = ip.instrument
    val patId = TrackListInput.getPatId(instrument.number)
    val patElem = elem(patId)

    if (track != null) {
      patElem.txt("${track.description()}/$maxPattern")
    } else {
      patElem.txt("--/$maxPattern")
    }

    for (entry in 0 until ip.song.entriesPerPattern) {
      updateEntry(ip, track, entry)
    }
  }

  fun updateEntry(ip: InstrumentTrack, track: Track?, entry: Int) {
    val instrument = ip.instrument
    val channels = ip.numberOfChannels

    if (ip.instrument.type == InstrumentType.DRUMS) {
      if (entry >= 0) {
        for (c in 0 until channels) {
          val id = TrackListInput.getId(instrument.number, entry, c)
          val cell = elem(id)
          val iElem = if (cell.childElementCount > 0) {
            cell.children[0]
          } else {
            val result = i().cls("fa-square")
            if (track != null) {
              cell.with(result)
            }
            result
          }

          when {
            track == null -> cell.txt(" ")
            track.notes[c][entry] == Note.C3 -> {
              if (iElem != null) {
                iElem.removeClass("far")
                iElem.addClass("fas")
              }
            }
            else -> {
              if (iElem != null) {
                iElem.removeClass("fas")
                iElem.addClass("far")
              }
            }
          }
        }
      }
    } else {
      if (entry >= 0) {
        for (c in 0 until channels) {
          val id = TrackListInput.getId(instrument.number, entry, c)
          val cell = elem(id)

          if (track != null) {
            cell.txt(track.notes[c][entry].description)
          } else {
            cell.txt("   ")
          }
        }
      }

      if (track != null) {
        setEffects(track.effects[entry], instrument.number, entry)
      } else {
        setEffects(null, instrument.number, entry)
      }
    }
  }

  private fun setEffects(fx: Effects?, instrumentNumber: Int, entry: Int) {
    var id = TrackListInput.getId(instrumentNumber, entry, 11)
    val vol = elem(id)
    id = TrackListInput.getId(instrumentNumber, entry, 12)
    val pan = elem(id)
    id = TrackListInput.getId(instrumentNumber, entry, 13)
    val pitch = elem(id)
    id = TrackListInput.getId(instrumentNumber, entry, 14)
    val arp = elem(id)

    setEffects(fx, vol, pan, pitch, arp)
  }

  private fun setEffects(fx: Effects?, vol: Element, pan: Element, pitch: Element, arp: Element) {
    if (fx == null) {
      vol.txt("  ")
      pan.txt("  ")
      pitch.txt("  ")
      arp.txt("  ")
    } else {
      val vs = fx.volumeSlide
      vol.txt("  ")
      if (fx.setVolume != null) {
        vol.txt("▶ ") // right
      } else if (vs != null) {
        // up or down
        if (vs.amount > 0) {
          vol.txt("▲ ")
        } else if (vs.amount < 0) {
          vol.txt("▼ ")
        } else {
          vol.txt("▪ ")
        }
      }

//                    vol.txt("&#9660;&nbsp;") // down
//                    pan.txt("&#9654;&nbsp;") // right
//                    pan.txt("&#9664;&nbsp;") // left
//                    pitch.txt("&#9660;&nbsp;") // up?

      val pitchSlide = fx.pitchSlide
      if (pitchSlide != null) {
        if (pitchSlide.semiNotes > 0) {
          pitch.txt("▲ ")
        } else if (pitchSlide.semiNotes < 0) {
          pitch.txt("▼ ")
        } else {
          pitch.txt("▪ ")
        }
      } else {
        pitch.txt("  ")
      }

      val panning = fx.panning
      if (panning != null) {
        if (panning.panning < 0f) {
          pan.txt("◀ ")
        } else if (panning.panning > 0f) {
          pan.txt("▶ ")
        } else {
          pan.txt("▪ ")
        }
      } else {
        pan.txt("  ")
      }

      val arpeggio = fx.arpeggio
      if (arpeggio != null) {
        arp.txt("\uD834\uDD83 ")
      } else {
        arp.txt("  ")
      }

    }
  }

  fun updateShow(ip: InstrumentTrack) {
    if (sequencer) {
      renderTracks()
    } else {
      if (hasElem("instrumenttrack_${ip.instrument.number}")) {
        val track = elem("instrumenttrack_${ip.instrument.number}")

        if (ip.show) {
          track.attr("style", "display: inline-block;")
        } else {
          track.attr("style", "display: none;")
        }
      }
    }
  }

  fun updateEntry() {
    if (sequencer) {
      renderTracks()
    } else {
      if (TrackListInput.selectedInstrument >= 0 && TrackListInput.selectedEntry >= 0) {
        val track = TrackListInput.getSelectedTrack()
        val entry = TrackListInput.selectedEntry
        val ip = Daw.song.instruments[TrackListInput.selectedInstrument]

        updateEntry(ip, track, entry)
      }
    }
  }

}
