package daw.worker

import org.khronos.webgl.ArrayBuffer
import org.w3c.dom.DedicatedWorkerGlobalScope
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import org.w3c.workers.ServiceWorkerGlobalScope

/**
 * User: rnentjes
 * Date: 25-2-18
 * Time: 15:45
 */

external val self: ServiceWorkerGlobalScope

object Worker {

  fun start() {
    println("Starting worker!")

    self.onmessage = ::receiveMessage
  }

  fun receiveMessage(message: Event) {
    if (message is MessageEvent) {
      val vnt: dynamic = message
      val target: DedicatedWorkerGlobalScope = vnt.currentTarget

      val data = message.data

      if (data is String) {
        console.log("Received String from main: ", data)

        target.postMessage("Result data as string")
      } else {
        console.log("Received data from main: ", data)

        target.setTimeout({
          val array = Array<ArrayBuffer>(1, { ArrayBuffer(1) })

          target.postMessage(array, array)
        }, 5000)
      }
    }
  }

}
