package daw.component

import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onBlurFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.HTMLInputElement

/**
 * User: rnentjes
 * Date: 3-12-17
 * Time: 17:11
 */

class TextInput(
    val id: String,
    val name: String = id,
    var value: String = "",
    var changeHandler: (HTMLInputElement) -> Unit = {}
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.input {
    id = this@TextInput.id
    type = InputType.text
    name = this@TextInput.name
    value = this@TextInput.value

    onBlurFunction = { input ->
      if (input is HTMLInputElement) {
        changeHandler(input)
      }
    }
  }

}