package daw.html.components

import org.w3c.dom.events.Event

/**
 * Created by rnentjes on 6-3-17.
 */

class SimpleController(
    val id: String,
    val value: String) {

  fun onclick(event: Event) {

  }

}

//language=HTML
var simpleTemplate = """
  <div class="myClass">
    <input id="{{id}}" type="text" value="{{value}}" [onclick]="{{onclick}}"
  </div>
"""

object Templater {

  fun defineTemplate(tag: String, html: String) {

  }

}

