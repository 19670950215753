package daw.view

import daw.Daw
import daw.component.NumberInput
import daw.input.PatternListInput
import daw.input.TrackListInput
import daw.instrument.InstrumentType
import daw.song.InstrumentTrack
import daw.view.instrument.InstrumentView
import daw.ws.WebsocketConnection
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.div
import kotlinx.html.i
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onClickFunction
import kotlinx.html.style
import kotlinx.html.title
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import kotlin.browser.document
import kotlin.browser.window

/**
 * User: rnentjes
 * Date: 22-12-15
 * Time: 17:12
 */

class TrackView: Komponent() {

  override fun update() {
    super.update()

    window.setTimeout({
      PatternView.updateTracks()
    }, 0)
  }

  private fun removeInstrument(ip: InstrumentTrack) {
    View.confirm("Remove instrument '${ip.instrument.description()}', are you sure?", {
      Daw.song.removeInstrument(ip)
    })
  }

  private fun removeEntry(ptnum: Int) {
    var ptNumStr = "${ptnum + 1}"

    if (ptnum < 9) {
      ptNumStr = "0$ptNumStr"
    }

    View.confirm("Remove entry '$ptNumStr' from tracks, are you sure?", {
      Daw.song.removeEntry(ptnum)

      if (Daw.song.selectedPattern > Daw.song.numberOfPatterns - 1) {
        Daw.song.selectedPattern = Daw.song.numberOfPatterns - 1

        PatternsView.renderPatternList()
      }
    })
  }

  override fun render(consumer: KompConsumer) = consumer.div(classes =  "patternlist") {
    row {
      div(classes = "pattern") {
        id = "instrument_parent"

        row {
          id = "instrument_row"

          cell {
            classes += "info"

            + " "
          }

          cell {
            classes += "info"

            + "Instruments"
          }

          cell {
            classes += "info"

            + "Chan"
            title = "Number of channels for this instrument"
          }

          cell {
            classes += "info"

            + "Pat"
            title = "Pattern number for the selected entry"
          }

          cell {
            classes += "info"
            title = "Show/hide patterns for this track"

            i(classes = "far fa-eye")
          }

          for (ptnum in 0 until Daw.song.numberOfPatterns) {
            val id = PatternListInput.getId(ptnum, -1)
            var title = (ptnum + 1).toString()

            if (ptnum < 9) {
              title = "0$title"
            }

            cell {
              this.id = id
              this.classes += "header"
              this.title = title

              + title

              onClickFunction = {
                Daw.song.selectedPattern = ptnum

                this@TrackView.update()
              }
            }
          }

          cell {
            style = "width: 21px"
            classes += "pbutton"
            title = "Add extra patterns to the song"

            + "+"

            onClickFunction = {
              Daw.song.addPattern()
            }
          }
        }

        for (ip in Daw.song.instruments) {
          row {
            cell {
              div {
                style = "color: ${calculateColor(ip.instrument.number, Daw.song.instruments.size)}"

                +  "■ "
              }
            }

            cell {
              classes += "align_left"
              classes += "button"
              classes += "info"

              + ip.instrument.description()

              onClickFunction = {
                InstrumentView.renderInstrumentModal(ip.instrument.number)
              }
            }

            cell {
              classes += "pcell"

              include(NumberInput(
                  id = "channels_${ip.instrument.number}",
                  value = ip.numberOfChannels,
                  min = 1,
                  max = 9,
                  width = 20,
                  readOnly = (ip.instrument.type == InstrumentType.DRUMS),
                  changeHandler = {
                    ip.setChannels(it)
                  }
              ))
            }

            cell {
              classes += "pcell"

              include(NumberInput(
                  id = "pattern_${ip.instrument.number}",
                  value = ip.patternList[Daw.song.selectedPattern] + 1,
                  min = 0, max = 99, width = 30,
                  changeHandler = {
                    ip.patternList[Daw.song.selectedPattern] = it - 1

                    TrackListInput.setTrack(ip.instrument.number, ip.patternList[Daw.song.selectedPattern])
                    this@TrackView.update()
                  },
                  valueMapper = {
                    when {
                      it == 0 -> ""
                      it < 10f -> "0$it"
                      else -> "$it"
                    }
                  }
              ))
            }

            cell {
              classes += "pcell"

              input {
                type = InputType.checkBox
                checked = ip.show

                onClickFunction = {
                    val checkbox = it.currentTarget as HTMLInputElement

                    ip.show = checkbox.checked

                    PatternView.updateShow(ip)
                    WebsocketConnection.addDirtyInstrumentPatterns(ip)
                }
              }
            }

            for (ptnum in 0 until Daw.song.numberOfPatterns) {
              val id = PatternListInput.getId(ptnum, ip.instrument.number)
              var value = "  "

              if (ip.patternList.size > ptnum) {
                val num = ip.patternList[ptnum] + 1

                if (num == 0) {
                  value = "--"
                } else {
                  if (num < 10) {
                    value = "0$num"
                  } else {
                    value = num.toString()
                  }
                }
              }

              cell {
                this.id = id
                this.classes += "pcell"

                if (ptnum == Daw.song.selectedPattern) {
                  classes += if (ip.instrument.number == 0) {
                    if (Daw.song.instruments.size == 1) {
                      "selected_top_bottom"
                    } else {
                      "selected_top"
                    }
                  } else if (ip.instrument.number == Daw.song.instruments.size - 1) {
                    "selected_bottom"
                  } else {
                    "selected"
                  }
                }

                + value

                onClickFunction = {
                  Daw.song.selectedPattern = ptnum

                  this@TrackView.update()
                }
              }
            }

            cell {
              classes += "cbutton"
              title = "Remove this instrument"

              + "✖"

              onClickFunction = {
                this@TrackView.removeInstrument(ip)
              }
            }
          }
        }

        row {
          cell {}
          cell {}
          cell {}
          cell {}
          cell {}

          for (ptnum in 0 until Daw.song.numberOfPatterns) {
            cell {
              classes += "cbutton"
              title = "Remove this entry"

              + "✖"

              onClickFunction = {
                this@TrackView.removeEntry(ptnum)
              }
            }
          }
        }
      }
    }
  }

}

object PatternsView {
  val patternListParent: HTMLElement =
      (document.getElementById("main") ?: throw IllegalStateException("div with id 'main' not found!")) as HTMLElement
  val patternView = TrackView()

  init {
    Komponent.create(patternListParent, patternView)
  }

  fun renderPatternList() {
    patternView.update()
  }

}
