package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * Created by rnentjes on 2-2-16.
 */

class SetVolume(var volume: Float, var retrigger: Boolean = true) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.currentInstrumentVolume = volume // Math.pow(volume.toDouble(), 3.0).toFloat()
    if (retrigger) {
      player.cycleOffset = 0.0
    }
  }

  override fun toString(): String {
    return "SetVolume(retrigger=$retrigger, volume=$volume)"
  }

}

class VolumeSlide(var amount: Float, var lines: Int) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    if (lines < 1) {
      player.effectState.currentInstrumentVolumeSlide = 1f
    } else {
      player.effectState.setVolumeSlide(amount / lines)
    }
  }

  override fun toString(): String {
    return "VolumeSlide(amount=$amount, lines=$lines)"
  }

}
