package daw

import daw.html.elem
import daw.instrument.BasicWaveForms
import daw.instrument.Instrument
import daw.song.Song
import daw.song.SongData
import daw.song.UserData
import daw.view.Modal
import daw.view.View
import daw.view.effect.EffectView
import daw.worker.WorkerHandler
import daw.ws.WebsocketConnection
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.UpdateStrategy
import org.w3c.dom.events.MouseEvent
import org.w3c.workers.ServiceWorkerGlobalScope
import kotlin.browser.window
import kotlin.dom.addClass
import kotlin.dom.removeClass
import kotlin.math.ln
import kotlin.math.log

/**
 * User: rnentjes
 * Date: 14-11-15
 * Time: 12:37
 */

fun onerror(msg: dynamic, filename: String, line: Int, column: Int, err: Any?) {
  println("Error message: $msg")
  println("Error file: $filename")
  println("Error line: $line")
  println("Error column: $column")
  println("Error: $err")
}

interface MouseEventHandler {

  fun mouseDown(event: MouseEvent)

  fun mouseMove(event: MouseEvent)

  fun mouseUp(event: MouseEvent)

}

object Daw {
  var blocks = 0
  var song: Song = Song("", "", "", 120)
  var importing = false
  var mouseEventHandler: MouseEventHandler? = null
  var user: UserData? = null

  fun importing(block: () -> Unit) {
    try {
      importing = true

      block()
    } finally {
      importing = false
    }
  }

  // example
  // bpm = 120
  // measure = 4/4
  // smallest note = 1/32
  // entries per measure: 32
  // pattern length = 60 / 120 * 4 = 2 sec
  // entry length = 2 / 32 = 0,0625
  // 0,0625 sec @ 44100 sample rate = 2756 samples per entry
  // 2756 * 2 (stereo) = 5512
  // 5512 * 32 = 176400 samples per pattern
  // var currentPattern = Array(10, { Array(32, { Array(5512, { 0f }) }) } )

  fun start() {
    //window.onerror = ::onerror

/*
    Komponent.logRenderEvent = true
    Komponent.logEquals = true
    Komponent.logReplaceEvent = true
*/

    Komponent.updateStrategy = UpdateStrategy.REPLACE

    initMouseEvents()

    BasicWaveForms.createWaves()

    //println("COOKIE: ${document.cookie}")
    block()

    View.render()

    WebsocketConnection.init()

    WorkerHandler.start()

    Modal.hide()
    EffectView.hide()
  }

  private fun initMouseEvents() {
    window.onmousedown = {
      val handler = mouseEventHandler

      if (handler != null && it is MouseEvent) {
        handler.mouseDown(it)
      }
    }

    window.onmousemove = {
      val handler = mouseEventHandler

      if (handler != null && it is MouseEvent) {
        handler.mouseMove(it)
      }
    }

    window.onmouseup = {
      val handler = mouseEventHandler

      if (handler != null && it is MouseEvent) {
        handler.mouseUp(it)
      }
    }
  }

  fun block() {
    if (blocks == 0) {
      elem("loading").addClass("show")
    }
    blocks++
  }

  fun unblock() {
    blocks--
    if (blocks == 0) {
      elem("loading").removeClass("show")
    }
  }

  fun setSong(song: SongData) {
    this.song = Song(song.songId, song.name, song.user, song.bpm)
    this.song.copiedFrom = song.copiedFrom
    this.song.linesPerBeat = song.linesPerBeat
    this.song.beatsPerTrack = song.beatsPerTrack
    this.song.entriesPerPattern = song.entriesPerPattern
    this.song.numberOfPatterns = song.numberOfPatterns
    this.song.reverbDelay = song.reverbDelay
    this.song.reverbDecay = song.reverbDecay

    this.song.addInstrument(Instrument(song.songId, 0))
    this.song.instruments[0].addAvailableTrack()
    this.song.instruments[0].patternList[0] = 0
  }
}

external val self: ServiceWorkerGlobalScope

fun main() {/*
  println("Log 1 ${log(1.0, 10.0)}")
  println("Log 5 ${log(5.0, 10.0)}")
  println("Log 10 ${log(10.0, 10.0)}")
  println("Log 20 ${log(20.0, 10.0)}")
  println("Log 200 ${log(200.0, 10.0)}")
  println("Log 10000 ${log(10000.0, 10.0)}")
  println("Log 20000 ${log(20000.0, 10.0)}")

  println("Ln 1 ${ln(1.0)}")
  println("Ln 5 ${ln(5.0)}")
  println("Ln 10 ${ln(10.0)}")
  println("Ln 20 ${ln(20.0)}")
  println("Ln 200 ${ln(200.0)}")
  println("Ln 10000 ${ln(10000.0)}")
  println("Ln 20000 ${ln(20000.0)}")*/

  if (self.asDynamic()["window"] != undefined) {
    println("Starting MTO")

    Daw.start()
  } else {
    println("Starting worker")
  }
}
