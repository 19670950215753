package daw.input

import daw.AudioHandler
import daw.Daw
import daw.Settings
import daw.html.attr
import daw.html.cls
import daw.html.elem
import daw.html.hasElem
import daw.html.txt
import daw.song.InstrumentTrack
import daw.view.PatternsView
import daw.view.PatternView
import daw.ws.WebsocketConnection
import org.w3c.dom.events.Event
import org.w3c.dom.events.WheelEvent
import kotlin.dom.removeClass

/**
 * User: rnentjes
 * Date: 27-12-15
 * Time: 12:28
 */

object PatternListInput : InputHandler {
  override fun mouseWheel(event: WheelEvent) {
  }

  override fun keyDown(key: Event) {
  }

  override fun keyUp(key: Event) {
  }

  override fun focus() {
    //elem("instrument_parent").addClass("inputselect")
  }

  override fun unfocus() {
    //elem("instrument_parent").removeClass("inputselect")
  }

  fun moveRight() {
    selectPosition(Daw.song.selectedPattern + 1, 0)

    PatternsView.renderPatternList()
    PatternView.updateTracks()
  }

  fun moveLeft() {
    if (Daw.song.selectedPattern > 0) {
      selectPosition(Daw.song.selectedPattern - 1, 0)

      PatternsView.renderPatternList()
      PatternView.updateTracks()
    }
  }

  fun refreshPosition() {
    selectPosition(Daw.song.selectedPattern, 0)
  }

  fun selectPosition(position: Int, instrument: Int) {
    Input.setFocus(this)

    for (instr in 0 until Daw.song.instruments.size) {
      val id = getId(Daw.song.selectedPattern, instr)
      if (hasElem(id)) {
        elem(id).removeClass("selected")
        elem(id).removeClass("selected_top")
        elem(id).removeClass("selected_bottom")
        elem(id).removeClass("selected_top_bottom")
      }
    }

    Daw.song.selectedPattern = position

    var updateAll = false

    for (instr in 0 until Daw.song.instruments.size) {
      val id = getId(position, instr)
      if (hasElem(id)) {
        if (instr == 0) {
          if (Daw.song.instruments.size == 1) {
            elem(id).cls("selected_top_bottom")
          } else {
            elem(id).cls("selected_top")
          }
        } else if (instr == Daw.song.instruments.size - 1) {
          elem(id).cls("selected_bottom")
        } else {
          elem(id).cls("selected")
        }
      }

      if (instr >= 0) {
        val ip = Daw.song.instruments[instr]
        while (ip.patternList.size < Daw.song.selectedPattern + 1) {
          ip.patternList.add(-1)
          updateAll = true;
          WebsocketConnection.addDirtyInstrumentPatterns(ip)
        }
        PatternListInput.updateSelected(instr)

        val patternNumber = elem("pattern_${ip.instrument.number}")
        patternNumber.attr("value", ip.getSelectedPattern())
      }
    }

    if (updateAll) {
      PatternsView.renderPatternList()
    }
    if (Settings.displayTracksDuringPlayback || !AudioHandler.isPlaying()) {
      PatternView.updateTracks()
    }
  }


  fun getId(position: Int, instrument: Int): String {
    if (instrument >= 0) {
      return "pattern_${instrument}_$position"
    } else {
      return "pattern_x_$position"
    }
  }

  fun updateSelected(instr: Int) {
    val ip = Daw.song.instruments[instr]
    val pt = ip.patternList[Daw.song.selectedPattern]
    var txt = ""
    if (pt < 0) {
      txt = "--"
    } else {
      if ((pt + 1) < 10) {
        txt += "0"
      }
      txt += (pt + 1).toString()
    }
    val id = getId(Daw.song.selectedPattern, instr)

    if (hasElem(id)) {
      elem(id).txt(txt)
    }

    //println("UPDATE - ${ip.getSelectedPattern()}")
    val pattern = elem("pattern_${ip.instrument.number}")

    pattern.attr("value", ip.getSelectedPattern())
  }

  fun setSelectedPattern(ip: InstrumentTrack, value: Int) {
    ip.patternList[Daw.song.selectedPattern] = value - 1

    TrackListInput.setTrack(ip.instrument.number, ip.patternList[Daw.song.selectedPattern])
  }
}
