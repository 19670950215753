package daw.instrument

import daw.AudioHandler
import daw.Daw
import daw.player.InstrumentPlayer
import daw.song.effects.SetArpeggio
import kotlin.math.PI
import kotlin.math.pow

class ArpeggioFxState(val setArpeggio: SetArpeggio, val channels: Int) {
  private val samplesPerEntry = Daw.song.samplesPerEntry
  private val samplesPerNote = if (setArpeggio.notes > 0) {
    samplesPerEntry * setArpeggio.lines / setArpeggio.notes
  } else {
    samplesPerEntry * setArpeggio.lines
  }
  var currentSample = 0
  var currentChannel = 0

  fun enabled() = channels > 1

  fun getChannelVolume(player: InstrumentPlayer, ch: Int, cycleOffset: Double) =
      if (setArpeggio.notes == 0 || ch == currentChannel) {
        1f
      } else {
        0f
      }

  fun nextSample(channel: Int, cycleOffset: Double) {
    if (channel == this.currentChannel) {
      currentSample++

      if (currentSample > samplesPerNote) {
        currentChannel++
        if (currentChannel >= channels) {
          currentChannel = 0
        }
        currentSample = 0
      }
    }

  }
}

data class InstrumentEffectState(val instrument: Instrument, val channels: Int) {
  val samplesPerSecond2pi = (AudioHandler.sampleRate * 2 * PI).toFloat()
  var currentInstrumentVolume = 0f
  var currentInstrumentVolumeSlide = 0f
  var currentInstrumentPanning = 0f
  var currentInstrumentPanningVolumeLeft = 1f
  var currentInstrumentPanningVolumeRight = 1f
  var currentInstrumentPanningSlide = 1f
  var currentInstrumentPitchOffset = 0f
  var currentInstrumentPitchSlide = 1f
  var currentVibratoSpeed = 0f
  var currentVibratoDepth = 0f
  var arpeggio: ArpeggioFxState? = null

  var copyOffset = false
  var cycleOffset = 0f

  init {
    reset()
  }

  fun reset() {
    currentInstrumentVolume = instrument.volume
    setPanning(instrument.panning)

    currentVibratoSpeed = 0f
    currentVibratoDepth = 0f
  }

  fun setPitchSlide(semiTonesPerLine: Float) {
    currentInstrumentPitchSlide = 2.0.pow(semiTonesPerLine / (12.0 * Daw.song.samplesPerEntry)).toFloat()
  }

  fun setPitchOffset(semiTones: Float) {
    currentInstrumentPitchOffset = semiTones
  }

  fun setVolumeSlide(volumePerLine: Float) {
    currentInstrumentVolumeSlide = volumePerLine / Daw.song.samplesPerEntry
  }

  fun setPanning(panning: Float) {
    currentInstrumentPanning = panning

    if (currentInstrumentPanning <= 0) {
      currentInstrumentPanningVolumeLeft = 1f
      currentInstrumentPanningVolumeRight = currentInstrumentPanning + 1
    } else if (currentInstrumentPanning >= 0) {
      currentInstrumentPanningVolumeLeft = 1 - currentInstrumentPanning
      currentInstrumentPanningVolumeRight = 1f
    }
  }

  fun setArpeggio(setArpeggio: SetArpeggio) {
    arpeggio = ArpeggioFxState(setArpeggio, channels)
  }

  fun nextSample(channel: Int, cycleOffset: Double) {
    currentInstrumentVolume += currentInstrumentVolumeSlide
    if (currentInstrumentVolume < 0) {
      currentInstrumentVolume = 0f
    }
    arpeggio?.nextSample(channel, cycleOffset)
  }


}
