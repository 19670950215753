package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * Created by rnentjes on 2-2-16.
 */

class SetPanning(var panning: Float) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.setPanning(panning)
  }

}

