package daw.view.instrument

import daw.component.TextInput
import daw.instrument.Instrument
import kotlinx.html.classes
import kotlinx.html.div
import kotlinx.html.hr
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include

/**
 * User: rnentjes
 * Date: 2-12-17
 * Time: 17:26
 */

class InstrumentComponent(
    var visible: Boolean = false,
    var instrument: Instrument
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "modal") {
    id = "modal"
    if (visible) {
      classes += "show"
    }

    div(classes = "modal-dialog") {
      div(classes = "row") {
        div(classes = "cell") {
          include(TextInput(
              "instrument_name",
              value = instrument.description(),
              changeHandler = { input ->
                if (input.value == "") {
                  instrument.named = false
                  input.value = instrument.description()
                } else {
                  instrument.name = input.value
                  instrument.named = true
                }
              }
          ))
        }
      }

      div(classes = "row") {
        div(classes = "cell") {
          hr {}
        }
      }

      div(classes = "button") {
        +"Cancel"
        onClickFunction = {
          hide()
        }
      }
    }

    div(classes = "modal-layer") {}
  }

  fun show() {
    visible = true
    refresh()
  }

  fun hide() {
    visible = false
    refresh()
  }

}