package daw.component

import daw.html.formatFloat
import kotlinx.html.div
import kotlinx.html.js.onMouseDownFunction
import kotlinx.html.js.onMouseMoveFunction
import kotlinx.html.js.onMouseUpFunction
import kotlinx.html.js.onMouseWheelFunction
import kotlinx.html.style
import kotlinx.html.svg
import kotlinx.html.unsafe
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.events.WheelEvent
import kotlin.math.PI
import kotlin.math.atan2
import kotlin.math.cos
import kotlin.math.max
import kotlin.math.min
import kotlin.math.sin

/**
 * User: rnentjes
 * Date: 26-11-17
 * Time: 16:52
 */

private const val START_ANGLE_DEG = 230
private const val END_ANGLE_DEG = 130
private const val ANGLE_RANGE_DEG = 260

private const val START_ANGLE = PI * START_ANGLE_DEG / 180.toFloat() - PI / 2
private const val END_ANGLE = PI * END_ANGLE_DEG / 180.toFloat() - PI / 2
private const val ANGLE_RANGE = PI * ANGLE_RANGE_DEG / 180.toFloat()

class KnobComponent(
    var value: Float = 1f,
    var ringColor: String = "#4b4b4b",
    var volumeColor: String = "#fb4b4b",
    var backgroundColor: String = "#1a1a1a",
    var label: String = "",
    var minValue: Float = 0f,
    var maxValue: Float = 5f,
    var step: Float = 0.05f,
    var width: Int = 50,
    var height: Int = 60,
    var renderer: (Float) -> String = { nv -> formatFloat(nv, 2) },
    val callback: (Double) -> Unit = {}
) : Komponent() {
  var activated = false
  var mouseX = 0.0
  var mouseY = 0.0

  private fun getMiddleX() = width / 2
  private fun getMiddleY() = ((height - 16) / 2) + 16
  private fun getRadius() = min(getMiddleX(), getMiddleY() - 16) - 5

  override fun render(consumer: KompConsumer) = consumer.div(classes = "notextselect") {
    svg {
      style = "width: ${this@KnobComponent.width}px; height: ${this@KnobComponent.height}px;"
      // ${if (activated) { "cursor:none;" } else { "" }}"

      val middle = (((ANGLE_RANGE_DEG.toFloat() * (value - minValue)) / (maxValue-minValue) + START_ANGLE_DEG.toFloat()).toInt())

      val middleX = getMiddleX()
      val middleY = getMiddleY()
      val radius = getRadius()

      if (activated) {
        unsafe {
          +"""<rect x="0" y="0" height="$height" width="$width"
                 style="fill: $backgroundColor;" />"""
        }
      }
      if (middle < 360) {
        unsafe {
          + """<path d="${describeArc(middleX, middleY, radius, START_ANGLE_DEG, middle)}"
                   fill="none" stroke="$volumeColor" stroke-width="10" />"""
       }
        unsafe {
          +"""<path d="${describeArc(middleX, middleY, radius, middle, 360)}"
                   fill="none" stroke="$ringColor" stroke-width="10" />"""
        }
        unsafe {
          + """<path d="${describeArc(middleX, middleY, radius, 0, END_ANGLE_DEG)}"
                   fill="none" stroke="$ringColor" stroke-width="10" />"""
        }
      } else {
        unsafe {
          +"""
          <path d="${describeArc(middleX, middleY, radius, START_ANGLE_DEG, middle)}"
                   fill="none" stroke="$volumeColor" stroke-width="10" />"""
        }
        unsafe {
          +"""<path d="${describeArc(middleX, middleY, radius, middle, END_ANGLE_DEG)}"
                   fill="none" stroke="$ringColor" stroke-width="10" />"""
        }
      }

      val renderedValue = renderer(value)
      unsafe {
        +"""
          <text x="${middleX - (renderedValue.length * 4)}"  y="$height"
            style=" font-family: Inconsolata, monospace, sans-serif;
                    font-size  : 16;
                    fill       : $volumeColor;
                  "
          >${renderedValue}</text>"""
      }
      unsafe {
        +"""<text x="${middleX - (label.length * 4)}"  y="12"
            style=" font-family: Inconsolata, monospace, sans-serif;
                    font-size  : 16;
                    fill       : $volumeColor;
                  "
          >$label</text>"""
      }
    }

    onMouseWheelFunction = {
      if (it is WheelEvent) {
        val delta = if (it.deltaY > 0) { 1.0 } else { -1.0 } //it.deltaY / 250.0

        var newValue = value - delta * step

        newValue = min(newValue, maxValue.toDouble())
        newValue = max(newValue, minValue.toDouble())

        value = newValue.toFloat()

        callback(newValue)

        update()

        it.preventDefault()
      }
    }

    onMouseDownFunction = {
      if (it is MouseEvent) {
        activated = true
        setValueByMouseCoords(it, minValue, maxValue, callback)
      }
    }

    onMouseUpFunction = {
      if (it is MouseEvent) {
        activated = false
        update()
      }
    }

/*
    onMouseOutFunction = {
      if (it is MouseEvent) {
        activated = false
      }
    }
*/

    onMouseMoveFunction = {
      if (it is MouseEvent) {
        if (activated && it.buttons == 1.toShort()) {
          setValueByMouseCoords(it, minValue, maxValue, callback)
        }
      }
    }

/*    onMouseOutFunction = { e ->
      if (e is MouseEvent) {
        val x = e.offsetX
        val y = e.offsetY

        console.log("Mouse out ($x, $y) - $activated!")
        if (x < 0 || y < 0 || x > width || y > height) {
          activated = false
        }
      }
    }*/

  }

  private fun setValueByMouseCoords(it: MouseEvent,
                                    minValue: Float = 0f,
                                    maxValue: Float = 5f,
                                    callback: (value: Double) -> Unit) {
    val deltaX = it.offsetX - getMiddleX()
    val deltaY = it.offsetY - getMiddleY()

    var angle = atan2(deltaX, deltaY)

    angle = if (angle < 0) {
      -angle
    } else {
      PI * 2.0 - angle
    }

    angle += PI / 2.0

    if (angle > PI * 2.0) {
      angle -= PI * 2.0
    }

    var newValue = minValue

    when {
      angle > START_ANGLE -> newValue = minValue + ((maxValue - minValue) / ANGLE_RANGE * (angle - START_ANGLE)).toFloat()
      angle < END_ANGLE -> {
        val calcAngle = angle + PI * 2.0
        newValue = minValue + ((maxValue - minValue) / ANGLE_RANGE * (calcAngle - START_ANGLE)).toFloat()
      }
      angle < PI / 2.0 -> newValue = maxValue
    }

    value = newValue

    callback(newValue.toDouble())

    update()

    it.preventDefault()
  }

  private fun polarToCartesian(centerX: Int, centerY: Int, radius: Int, angleInDegrees: Int): Pair<Double, Double> {
    val angleInRadians = (angleInDegrees - 90).toDouble() * PI / 180.0

    return Pair(
        centerX + (radius * cos(angleInRadians)),
        centerY + (radius * sin(angleInRadians))
    )
  }

  private fun describeArc(x: Int, y: Int, radius: Int, startAngle: Int, endAngle: Int): String {
    val start = polarToCartesian(x, y, radius, endAngle)
    val end = polarToCartesian(x, y, radius, startAngle)

    val largeArcFlag = if (endAngle - startAngle <= 180) {
      "0"
    } else {
      "1"
    }

    return """
      M ${start.first} ${start.second}
      A $radius $radius 0 $largeArcFlag 0 ${end.first} ${end.second}
      """
  }
}
