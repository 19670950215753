package daw.view.instrument

import daw.Daw
import daw.html.attr
import daw.html.bottomcell
import daw.html.cell
import daw.html.cls
import daw.html.div
import daw.html.html
import daw.html.label
import daw.html.row
import daw.html.select
import daw.html.txt
import daw.html.with
import daw.instrument.Instrument
import daw.instrument.Sample
import daw.note.Note
import daw.note.NoteSelect
import daw.view.View
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.Event
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get
import kotlin.browser.document

/**
 * Created by rien on 9-9-16.
 */

object DrumsView {

  fun renderDrumsSamplesSelect(parent: Element, instrument: Instrument) {

    for (sampleIndex in instrument.samples.indices) {
      val result = div()
      val label = label("instrument_waveform_$sampleIndex", "Select file...").cls("button")
      val fileInput = document.createElement("input") as HTMLInputElement

      var sample = Sample()
      if (instrument.samples[sampleIndex] >= 0) {
        sample = Daw.song.getSample(instrument.samples[sampleIndex])
      }

      fileInput.cls("inputfile")
      fileInput.attr("name", "instrument_waveform")
      fileInput.attr("id", "instrument_waveform_$sampleIndex")
      fileInput.attr("value", sample.sampleName)
      fileInput.attr("type", "file")

      fileInput.onchange = fileinputSelectHandler(instrument, sampleIndex, fileInput)

      val selectNoteLabel = label("instrument_note", "Sampled note:")
      val selectNote = select("instrument_note", NoteSelect(), "${sample.sampleNote}")

      result.with(row(cell(row(selectNoteLabel, selectNote))))

      selectNote.onchange = {
        val sel = it.currentTarget as HTMLSelectElement

        sample.sampleNote = Note.valueOf(sel.value)

        false
      }

      result.with(row(cell(row(cell().txt("Sample:"), cell().html(sample.sampleName))), cell(label), cell(fileInput)))

      result.with(row(cell().txt(" ")))

      parent.with(row(bottomcell(result), bottomcell(WaveView.create(sample, height = 100))))
    }

    val addSample = div().cls("button").txt("Add sample") as HTMLDivElement
    val removeSample = div().cls("button").txt("Remove sample") as HTMLDivElement

    addSample.onclick = {
      val old = instrument.samples

      instrument.samples = Array(old.size + 1, { -1 })
      for (index in old.indices) {
        instrument.samples[index] = old[index]
      }

      InstrumentView.renderInstrumentModal(instrument)
    }

    removeSample.onclick = {
      val old = instrument.samples

      instrument.samples = Array(old.size - 1, { -1 })
      for (index in 0..old.size - 2) {
        instrument.samples[index] = old[index]
      }

      Daw.song.instruments[instrument.number].setChannels(instrument.samples.size, true)

      InstrumentView.renderInstrumentModal(instrument)
    }

    if (instrument.samples.isNotEmpty() && instrument.samples.size < 9) {
      parent.with(row(bottomcell(addSample), bottomcell(removeSample)))
    } else if (instrument.samples.isNotEmpty()) {
      parent.with(row(bottomcell(), bottomcell(removeSample)))
    } else {
      parent.with(row(bottomcell(addSample), bottomcell()))
    }
  }

  private fun fileinputSelectHandler(instrument: Instrument, sampleIndex: Int, sel: HTMLInputElement): (Event) -> Unit {
    return {
      val list: FileList? = sel.files
      if (list != null && list.length == 1) {
        val file = list[0]
        if (file != null) {
          val reader = FileReader()

          reader.onload = {
            if (reader.result is ArrayBuffer) {
              val buffer = reader.result

              if (file.type == "audio/wav") {
                val dataView = DataView(buffer)

                if (dataView.byteLength < 44) {
                  throw IllegalStateException("This is not a WAVE file!")
                }

                // assert pcm, 16 bit mono
                val channels = dataView.getInt16(22, true)
                val sampleRate = dataView.getInt32(24, true)
                val bytesPerSample = dataView.getInt16(32, true).toInt()
                val dataLength = dataView.getInt32(40, true)

                println("Found $channels channels, bytes per sample: ${bytesPerSample}, sample rate: ${sampleRate} data length: ${dataLength}")

                val sample = Daw.song.getNewSample()

                sample.buffer = buffer.slice(44, dataLength + 44)
                sample.sampleRate = sampleRate
                sample.sampleNote = Note.C3
                sample.sampleName = file.name
                sample.bytesPerSample = bytesPerSample

                when (channels) {
                  1.toShort() -> sample.stereo = false
                  2.toShort() -> sample.stereo = true
                  else -> throw IllegalStateException("More than 2 sample samples not supported!")
                }

                instrument.samples[sampleIndex] = sample.sampleNumber

                InstrumentView.renderInstrumentModal(instrument)
              }
            }
          }

          reader.readAsArrayBuffer(file)
        }
      } else {
        View.showError("ERROR!")
      }
    }
  }

}