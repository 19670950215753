package daw.view.instrument

import daw.Settings
import daw.html.attr
import daw.html.cls
import daw.instrument.Sample
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.events.MouseEvent
import kotlin.browser.document
import kotlin.math.min

/**
 * User: rnentjes
 * Date: 13-11-16
 * Time: 13:29
 */

object WaveEditorView {

  fun create(
      wave: Sample,
      width: Int = Settings.defaultCanvasWidth,
      height: Int = Settings.defaultCanvasHeight
  ): Element {
    val canvas = document.createElement("canvas") as HTMLCanvasElement

    canvas.width = width
    canvas.height = height

    canvas.cls("wavecanvas")

    canvas.onmousedown = {
      if (it is MouseEvent) {
        canvas.attr("data-activated", "true")

        updateSample(it, canvas, wave)
      }
    }

    canvas.onmousemove = {
      if (it is MouseEvent) {
        if (canvas.getAttribute("data-activated") == "true" && it.buttons == 1.toShort()) {
          updateSample(it, canvas, wave)
        }
      }
    }

    fun mouseUp(me: MouseEvent) {
      canvas.attr("data-activated", "false")

      updateSample(me, canvas, wave)
    }

    canvas.onmouseleave = {
      if (it is MouseEvent) {
        mouseUp(it)
      }
    }

    canvas.onmouseup = {
      if (it is MouseEvent) {
        mouseUp(it)
      }
    }

    update(canvas, wave)

    return canvas
  }

  fun update(element: Element,
             wave: Sample) {
    if (element is HTMLCanvasElement) {
      val context = element.getContext("2d") as CanvasRenderingContext2D

      val width = context.canvas.width
      val height = context.canvas.height

      val levels = wave.levels
      val samples = wave.samples()

      context.fillStyle = "#1b1b1b"
      context.fillRect(0.0, 0.0, width.toDouble(), height.toDouble())

      context.fillStyle = "#3b3b3b"

      if (levels <= 16) {
        for (index in 0..levels - 1) {
          context.fillRect(0.0, (0.5 * height) + index * (0.5 * height / levels.toDouble()), width.toDouble(), 1.0)
          context.fillRect(0.0, (0.5 * height) - index * (0.5 * height / levels.toDouble()), width.toDouble(), 1.0)
        }
      }
      if (samples <= 32) {
        for (index in 1..samples - 1) {
          context.fillRect(index * (width / samples.toDouble()), 0.0, 1.0, height.toDouble())
        }
      }

      for (x in 1..width - 1) {
        val index = wave.samples() * x / width

        val yl = -(height / 2) * (wave.leftSampleValue(index) / Short.MAX_VALUE.toFloat())
        val yr = -(height / 2) * (wave.rightSampleValue(index) / Short.MAX_VALUE.toFloat())

        context.fillStyle = "rgba(116,96,76,0.6)"
        context.fillRect(x.toDouble(), (height / 2.0), 1.0, yl.toDouble())
        context.fillStyle = "rgba(116,76,96,0.6)"
        context.fillRect(x.toDouble(), (height / 2.0), 1.0, yr.toDouble())
      }

      if (wave.repeatStart > 0) {
        val wx = 1f / wave.samples().toDouble() * wave.repeatStart
        val ww = 1f / wave.samples().toDouble() * wave.repeatLength

        context.fillStyle = "rgba(75,75,75,0.4)"
        context.fillRect(wx * width.toDouble(), 0.0, ww * width.toDouble(), height.toDouble())
      }
    }
  }

  fun updateSample(mouseEvent: MouseEvent,
                   element: Element,
                   wave: Sample) {
    val (x, y) = getLocation(mouseEvent)

    if (x > 0 && x < 1f) {
      val sample = min((wave.samples() - 1).toDouble(), wave.samples() * x.toDouble()).toInt()

      if (y > 0 && y < 1f) {
        var value = 1f - (y * 2)
        val lvls = wave.levels

        value *= lvls
        if (value > 0) {
          value = (value.toInt() + 1).toFloat() //toFixed(0)
        } else {
          value = (value.toInt() - 1).toFloat() //toFixed(0)
        }
        value /= lvls

        wave.setSample(sample, (value * Short.MAX_VALUE).toShort())
      }
    }

    update(element, wave)
  }

  fun getLocation(mouseEvent: MouseEvent): Pair<Float, Float> {
    val target = mouseEvent.target

    if (target is HTMLCanvasElement) {
      val width = target.width.toDouble()
      val height = target.height.toDouble()

      val x = mouseEvent.offsetX
      val y = mouseEvent.offsetY

      val resX = x.toFloat() / width.toFloat()
      val resY = y.toFloat() / height.toFloat()

      return resX to resY
    }

    return -1f to -1f
  }

}
