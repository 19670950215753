package daw.input

import daw.AudioHandler
import daw.Daw
import daw.html.elem
import daw.html.hasElem
import daw.html.html
import daw.instrument.InstrumentType
import daw.note.Note
import daw.note.getNoteKeyMap
import daw.player.InstrumentPlayer
import daw.song.Effects
import daw.song.InstrumentTrack
import daw.song.Track
import daw.view.PatternView
import daw.view.effect.EffectView
import daw.ws.WebsocketConnection
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.WheelEvent
import kotlin.browser.document
import kotlin.dom.addClass
import kotlin.dom.removeClass

/**
 * User: rnentjes
 * Date: 27-12-15
 * Time: 12:28
 */

object TrackListInput : InputHandler {
  override fun mouseWheel(event: WheelEvent) {}

  var selectedInstrument = -1
  var selectedEntry = -1
  var selectedChannel = -1
  var playingEntry = -1
  var player: InstrumentPlayer? = null

  var octave = 3
  var noteKeyMap: Map<Int, Note> = HashMap()

  init {
    updateKeyMap()
  }

  override fun keyDown(key: Event) {
    if (key is KeyboardEvent) {
      //println(key.keyCode)

      if (key.keyCode == 32 || (key.keyCode >= 33 && key.keyCode <= 40)) {
        key.preventDefault()
      }

      if (key.ctrlKey) {
        when (key.keyCode) {
          38 -> SongBox.octaveUp()
          40 -> SongBox.octaveDown()
        }
      } else {
        // println("key: ${key.keyCode}")
        when (key.keyCode) {
          37 -> moveLeft()
          38 -> moveUp()
          39 -> moveRight()
          40 -> moveDown()
          32 -> {
            moveDown()
          }
          192 -> {
            EffectView.switchView()
          }
          219 -> {
            SongBox.octaveDown()
          }
          221 -> {
            SongBox.octaveUp()
          }
          else -> {
            if (noteKeyMap.containsKey(key.keyCode)) {
              note(noteKeyMap.get(key.keyCode)!!)
              moveDown()
              player?.playNote(noteKeyMap[key.keyCode]!!)
            }
          }
        }
      }
    }
  }

  override fun keyUp(key: Event) {
    if (key is KeyboardEvent) {
      if (noteKeyMap.containsKey(key.keyCode)) {
        player?.noteUp(noteKeyMap[key.keyCode]!!)
      }
    }
  }

  override fun focus() {
    //elem("track_parent").addClass("inputselect")
    EffectView.focus()
  }

  override fun unfocus() {
    deselect()

    EffectView.unfocus()
    //elem("track_parent").removeClass("inputselect")
  }

  private fun getInstrumentPatterns(): InstrumentTrack {
    return Daw.song.instruments[selectedInstrument]
  }

  private fun getTrack(): Track {
    val ip = getInstrumentPatterns()
    val tracknumber = ip.patternList[Daw.song.selectedPattern]
    val track = ip.availablePatterns[tracknumber]

    return track
  }

  private fun note(note: Note) {
    val track = getTrack()

    track.notes[selectedChannel][selectedEntry] = note
    elem(getId(selectedInstrument, selectedEntry, selectedChannel)).html(note.description)

    if (note == Note.NONE) {
      track.effects[selectedEntry] = Effects()
    }

    WebsocketConnection.addDirtyTrack(track)
  }

  fun getSelectedTrack(): Track? {
    if (selectedInstrument > -1 && selectedEntry > -1) {
      return getTrack()
    }

    return null
  }

  fun setSelectedTrackDirty() {
    val track = getSelectedTrack()

    if (track != null) {
      WebsocketConnection.addDirtyTrack(track)
    }
  }

  fun getEffects(): Effects? {
    if (selectedInstrument > -1 && selectedEntry > -1) {
      val track = getTrack()

      return track.effects[selectedEntry]
    }

    return null
  }

  fun setEffects(effects: Effects) {
    if (selectedInstrument > -1 && selectedEntry > -1) {
      val track = getTrack()

      track.effects[selectedEntry] = effects
    }
  }

  private fun moveDown() {
    if (selectedEntry < Daw.song.entriesPerPattern - 1) {
      selectPosition(selectedInstrument, selectedEntry + 1, selectedChannel)
    } else if (Daw.song.selectedPattern < Daw.song.numberOfPatterns - 1) {
      val instrument = selectedInstrument
      val channel = selectedChannel
      PatternListInput.moveRight()

      selectPosition(instrument, 0, channel)
    }
  }

  private fun moveRight() {
    val instrumentPatterns = getInstrumentPatterns()

    if (selectedChannel < instrumentPatterns.numberOfChannels - 1) {
      selectPosition(selectedInstrument, selectedEntry, selectedChannel + 1)
    } else if (selectedInstrument < Daw.song.instruments.size - 1) {
      var instr = selectedInstrument + 1

      while (instr < Daw.song.instruments.size - 1 &&
          (!Daw.song.instruments[instr].show || Daw.song.instruments[instr].instrument.type == InstrumentType.DRUMS)
          ) {
        instr++
      }

      if (instr <= Daw.song.instruments.size - 1) {
        selectPosition(instr, selectedEntry, 0)
      }
    }
  }

  private fun moveUp() {
    if (selectedEntry > 0) {
      selectPosition(selectedInstrument, selectedEntry - 1, selectedChannel)
    } else if (Daw.song.selectedPattern > 0) {
      val instrument = selectedInstrument
      val channel = selectedChannel

      PatternListInput.moveLeft()
      selectPosition(instrument, Daw.song.entriesPerPattern - 1, channel)
    }
  }

  private fun moveLeft() {
    if (selectedChannel > 0) {
      selectPosition(selectedInstrument, selectedEntry, selectedChannel - 1)
    } else if (selectedInstrument > 0) {
      var instr = selectedInstrument - 1

      while (instr > 0 &&
          (!Daw.song.instruments[instr].show || Daw.song.instruments[instr].instrument.type == InstrumentType.DRUMS)
          ) {
        instr--
      }

      if (instr >= 0) {
        selectPosition(instr, selectedEntry, 999)
      }
    }
  }

  fun selectCurrentPosition() {
    if (selectedInstrument > -1 && selectedEntry > -1 && selectedChannel > -1) {
      selectPosition(selectedInstrument, selectedEntry, selectedChannel)
    }
  }

  fun deselect() {
    if (selectedInstrument >= 0 && selectedEntry >= 0 && selectedChannel >= 0) {
      elem(getId(selectedInstrument, selectedEntry, selectedChannel)).removeClass("selected")

      selectedInstrument = -1
      selectedEntry = -1
      selectedChannel = -1
    }
  }

  fun selectPosition(instr: Int, entry: Int, channel: Int) {
    Input.setFocus(TrackListInput)
    val updatePlayer = player == null || !AudioHandler.isPlaying() || selectedInstrument != instr

    if (selectedInstrument >= 0 && selectedEntry >= 0 && selectedChannel >= 0) {
      elem(getId(selectedInstrument, selectedEntry, selectedChannel)).removeClass("selected")
    }

    selectedInstrument = instr
    selectedEntry = entry

    val instrumentPatterns = getInstrumentPatterns()

    if (channel > instrumentPatterns.numberOfChannels - 1) {
      selectedChannel = instrumentPatterns.numberOfChannels - 1
    } else {
      selectedChannel = channel
    }

    val selected = elem(getId(selectedInstrument, selectedEntry, selectedChannel))
    val activeElement = document.activeElement

    if (activeElement is HTMLElement) {
      activeElement.blur()
    }

    selected.addClass("selected")
    selected.focus()

    if (updatePlayer) {
      //println("Updating player! player $player AudioHandler.player ${AudioHandler.player}")
      player?.stop()

      player = InstrumentPlayer(InstrumentTrack(Daw.song.instruments[instr].instrument), 1)
      player?.also {
        AudioHandler.play(it)
      }
    }

    EffectView.update()
  }

  fun playingPosition(entry: Int) {
    if (playingEntry >= 0) {
      for (ip in Daw.song.instruments) {
        for (channel in 0..ip.numberOfChannels - 1) {
          val id = getId(ip.instrument.number, playingEntry, channel)

          if (hasElem(id)) {
            elem(id).removeClass("playing")
          }
        }
      }
    }

    playingEntry = entry

    if (playingEntry >= 0) {
      for (ip in Daw.song.instruments) {
        for (channel in 0..ip.numberOfChannels - 1) {
          val id = getId(ip.instrument.number, playingEntry, channel)

          if (hasElem(id)) {
            elem(id).addClass("playing")
          }
        }
      }
    }
  }

  fun updateKeyMap() {
    noteKeyMap = getNoteKeyMap(octave);
  }

  fun trackUp(track: Int) {
    val ip = Daw.song.instruments[track]

    setTrack(track, ip.patternList[Daw.song.selectedPattern] + 1)
  }

  fun trackDown(track: Int) {
    val ip = Daw.song.instruments[track]
    val pattern = ip.patternList[Daw.song.selectedPattern]

    if (pattern > -1) {
      setTrack(track, ip.patternList[Daw.song.selectedPattern] - 1)
    }
  }

  fun setTrack(track: Int, pattern: Int) {
    val ip = Daw.song.instruments[track]
    while (ip.patternList.size < pattern) {
      ip.patternList.add(0)
    }

    ip.patternList[Daw.song.selectedPattern] = pattern

    while (ip.availablePatterns.size <= ip.patternList[Daw.song.selectedPattern]) {
      ip.addAvailableTrack()
    }

    PatternListInput.updateSelected(ip.instrument.number);
    PatternView.updateTracks()

    Input.setFocus(TrackListInput)
    WebsocketConnection.addDirtyInstrumentPatterns(ip)
  }

  fun copyTrack(track: Int) {
    val ip = Daw.song.instruments[track]
    while (ip.patternList.size < Daw.song.selectedPattern) {
      ip.patternList.add(0)
    }

    val original = ip.patternList[Daw.song.selectedPattern]
    if (original > -1) {
      ip.patternList[Daw.song.selectedPattern] = ip.copyTrack(ip.availablePatterns[original])
    }

    PatternListInput.updateSelected(ip.instrument.number)
    PatternView.updateTracks()
    Input.setFocus(TrackListInput)
    WebsocketConnection.addDirtyInstrumentPatterns(ip)
  }

  fun getId(instr: Int, entry: Int, channel: Int) = "track_${instr}_${entry}_$channel"

  fun getPatId(instr: Int) = "track_pat_${instr}"
}
