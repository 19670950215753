package daw.view

import daw.AudioHandler
import daw.Daw
import daw.component.KnobComponent
import daw.component.NumberInput
import daw.html.formatFloat
import daw.input.TrackListInput
import daw.ws.WebsocketConnection
import kotlinx.html.InputType
import kotlinx.html.a
import kotlinx.html.div
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onBlurFunction
import kotlinx.html.label
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include
import org.w3c.dom.HTMLInputElement
import kotlin.math.pow
import kotlin.math.exp
import kotlin.math.log10

class SongComponent : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "row") {
    /*
        div(classes = "row") {
          div(classes = "cell") {
            div(classes = "pattern") {
              include(NoteWheelComponent(
                  backgroundColor = "#292929",
                  ringColor = "#343434",
                  volumeColor = "#bf7940",
                  width = 100,
                  height = 100,
                  callback = { value ->
                    println("NoteWheelComponent callback $value")
                  }
              ))
            }
          }
        }
    */

    div(classes = "row") {
      div(classes = "cell") {

        div(classes = "pattern") {


          div(classes = "row") {
            div(classes = "cell") {
              label {
                htmlFor = "song_title"
                +"Song: "
              }
            }
            div(classes = "cell") {
              input {
                id = "song_title"
                type = InputType.text
                name = "song_title"
                value = Daw.song.name
                onBlurFunction = {
                  val target = it.target

                  if (target is HTMLInputElement) {
                    Daw.song.name = target.value

                    WebsocketConnection.setSongIsDirty()
                  }
                }
              }
            }
            div(classes = "cell") {
              label {
                htmlFor = "song_bpm"
                +"Beats per minute: "
              }
            }
            div(classes = "cell") {
              include(
                  NumberInput(
                      "song_bpm",
                      value = Daw.song.bpm,
                      min = 1,
                      max = 999,
                      step = 1,
                      changeHandler = {
                        Daw.song.setBpm(it.toInt())

                        WebsocketConnection.setSongIsDirty()
                      })
              )
            }
          }

          div(classes = "row") {
            div(classes = "cell") {
              label {
                htmlFor = "song_octave"
                +"Octave: "
              }
            }
            div(classes = "cell") {
              include(
                  NumberInput(
                      "song_octave",
                      value = TrackListInput.octave,
                      min = 1,
                      max = 8,
                      step = 1,
                      changeHandler = {
                        TrackListInput.octave = it
                        TrackListInput.updateKeyMap()
                        WebsocketConnection.setSongIsDirty()
                      })
              )
            }
            div(classes = "cell") {
              label {
                htmlFor = "song_bpm"
                +"Lines per beat: "
              }
            }
            div(classes = "cell") {
              include(
                  NumberInput(
                      "song_linesPerBeat",
                      value = Daw.song.linesPerBeat,
                      min = 1,
                      max = 32,
                      step = 1,
                      changeHandler = {
                        Daw.song.setLinesPerBeat(it)

                        WebsocketConnection.setSongIsDirty()
                      })
              )
            }
          }

          div(classes = "row") {
            div(classes = "cell") {
              label {
                htmlFor = "song_length"
                +"Length: "
              }
            }
            div(classes = "cell") {
              id = "song_length"
              +Daw.song.length
            }
            div(classes = "cell") {
              label {
                htmlFor = "song_beatsPerTrack"
                +"Beats per pattern: "
              }
            }
            div(classes = "cell") {
              include(
                  NumberInput(
                      "song_beatsPerTrack",
                      value = Daw.song.beatsPerTrack,
                      min = 1,
                      max = 32,
                      step = 1,
                      changeHandler = {
                        Daw.song.setBeatsPerTrack(it)

                        WebsocketConnection.setSongIsDirty()
                      })
              )
            }
          }

          div(classes = "row") {
            if (Daw.song.copiedFrom != null) {
              div(classes = "cell") {
                label {
                  htmlFor = "song_copied"
                  +"Copy of: "
                }
              }
              div(classes = "cell") {
                id = "song_copied"
                a {
                  +(Daw.song.copiedFrom ?: "")
                  href = "tracker.html?${Daw.song.copiedFrom}"
                }
              }
            } else {
              div(classes = "cell") { }
              div(classes = "cell") { }
            }

            div(classes = "cell") {
              label {
                htmlFor = "song_linesPerTrack"
                +"Entries per pattern: "
              }
            }
            div(classes = "cell") {
              id = "song_linesPerTrack"
              +Daw.song.entriesPerPattern.toString()
            }
          }

        }
      }
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Volume",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = Daw.song.masterVolume,
              callback = { value ->
                Daw.song.masterVolume = value.toFloat()
              }
          ))
        }
      }
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Delay",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = Daw.song.reverbDelay,
              minValue = 0f,
              maxValue = 1000f,
              step = 25f,
              renderer = { value -> "${formatFloat(value, 0)}ms" },
              callback = { value ->
                Daw.song.reverbDelay = value.toFloat()
              }
          ))
        }
      }
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Decay",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = Daw.song.reverbDecay,
              minValue = 0f,
              maxValue = 0.99f,
              step = 0.01f,
              renderer = { value -> "${formatFloat((1f-value) * 100, 0)}%" },
              callback = { value ->
                Daw.song.reverbDecay = value.toFloat()
              }
          ))
        }
      }
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Low pass",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = log10(Daw.song.lowPass),
              minValue = 1f,
              maxValue = 4.5f,
              step = 0.05f,
              renderer = { value -> "${formatFloat(10f.pow(value), 0)}Hz" },
              callback = { value ->
                Daw.song.lowPass = 10f.pow(value.toFloat())
              }
          ))
        }
      }/*
      div (classes = "cell") {
        div(classes = "pattern") {
          +"Buffers: ${AudioHandler.buffersCreated}"
        }
      }*/
/*
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Mod. speed",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = Daw.song.reverbSpeed,
              minValue = 0f,
              maxValue = 250f,
              step = 1f,
              callback = { value ->
                Daw.song.reverbSpeed = value.toFloat()
              }
          ))
        }
      }
      div(classes = "cell") {
        div(classes = "pattern") {
          include(KnobComponent(
              label = "Mod. depth",
              backgroundColor = "#292929",
              ringColor = "#343434",
              volumeColor = "#bf7940",
              width = 64,
              height = 80,
              value = Daw.song.reverbDepth,
              minValue = 0f,
              maxValue = 10f,
              step = 0.01f,
              callback = { value ->
                Daw.song.reverbDepth = value.toFloat()
              }
          ))
        }
      }
*/
    }
  }
}
