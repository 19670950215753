package daw.view.instrument

import daw.AudioHandler
import daw.Settings
import daw.html.attr
import daw.html.button
import daw.html.cell
import daw.html.cls
import daw.html.div
import daw.html.input
import daw.html.label
import daw.html.row
import daw.html.select
import daw.html.span
import daw.html.tooltip
import daw.html.txt
import daw.html.with
import daw.input.InstrumentController
import daw.instrument.ADSREnvelope
import daw.instrument.EnvelopeType
import daw.instrument.EnvelopeTypeSelect
import daw.instrument.Instrument
import daw.instrument.ManualEnvelope
import daw.instrument.NoneEnvelope
import daw.player.InstrumentPlayer
import daw.song.InstrumentTrack
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLSpanElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import kotlin.browser.document
import kotlin.math.PI
import kotlin.math.max
import kotlin.math.min

/**
 * User: rnentjes
 * Date: 12-11-16
 * Time: 12:02
 */

object EnvelopeEditorView {
  val envelopBackgroundColor = "#1b1b1b"
  val envelopGridColor = "#3b3b3b"
  val envelopeColor = "rgba(150,96,150,0.6)"
  val envelopeDotColor = "rgba(96,255,76,0.6)"
  val envelopeLoopDotColor = "rgba(255,96,76,0.6)"
  val dotRadius = 4.5
  var point = -1

  fun create(
      instrument: Instrument,
      width: Int = Settings.defaultCanvasWidth,
      height: Int = Settings.defaultCanvasHeight
  ): Element {
    val result = div()

    result.attr("data-dt", "envelopEditor")

    val canvas = document.createElement("canvas") as HTMLCanvasElement
    val context = canvas.getContext("2d") as CanvasRenderingContext2D

    canvas.cls("envelopecanvas")

    context.canvas.width = width
    context.canvas.height = height

    val label = label("envelope_type", "Envelope:")
    val select = select("envelope_type", EnvelopeTypeSelect(), instrument.envelopeType.name)

    select.onchange = {
      val sel = it.currentTarget as HTMLSelectElement

      instrument.envelopeType = EnvelopeType.valueOf(sel.value)
      InstrumentView.renderInstrumentModal(instrument)
    }

    val lengthLabel = label("envelope_length", "Length:")
    val length = input("envelope_length").attr("name", "envelope_length")
        .attr("type", "number")
        .attr("step", "0.1").attr("min", "0.1").attr("max", "100")
        .attr("value", "${instrument.envelopeLength}") as HTMLInputElement

    fun handleLengthAdjust(): (Event) -> Unit {
      return {
        val len = it.currentTarget as HTMLInputElement

        instrument.updateEnvelopeLength(len.value.toFloat())
        update(instrument, canvas)
      }
    }

    length.onmouseup = handleLengthAdjust()
    length.onblur = handleLengthAdjust()

    update(instrument, canvas)

    result.with(cell(
        row(cell(label), cell(select)),
        row(cell(lengthLabel), cell(length))
    ))
    result.with(row(cell(canvas)))

    return result
  }

  fun update(instrument: Instrument, canvas: HTMLCanvasElement) {
    when (instrument.envelopeType) {
      EnvelopeType.NONE -> {
        val envelope = NoneEnvelope()

        renderEnvelope(canvas, envelope)
      }
      EnvelopeType.ADSR -> {
        val envelope = ManualEnvelope(instrument)
        //var envelope = ADSREnvelope(instrument.attackLevel, instrument.decayLevel, instrument.attackTime, instrument.decayTime, instrument.releaseTime)

        renderManualEnvelope(canvas, envelope)
        setManualEnvelopeMouseEvents(canvas, envelope)
      }
      EnvelopeType.MANUAL -> {
        val envelope = ManualEnvelope(instrument)

        renderManualEnvelope(canvas, envelope)

        setManualEnvelopeMouseEvents(canvas, envelope)
      }
      else -> {
        throw IllegalStateException("Envelope: ${instrument.envelopeType} not implemented!")
      }
    }

    InstrumentView.player = InstrumentPlayer(InstrumentTrack(instrument), 1)
    AudioHandler.play(InstrumentView.player)
  }

  fun renderEnvelope(canvas: HTMLCanvasElement, envelope: NoneEnvelope) {

  }

  fun renderEnvelope(canvas: HTMLCanvasElement, envelope: ADSREnvelope) {

  }

  var red = 0
  fun renderManualEnvelope(canvas: HTMLCanvasElement, manualEnvelope: ManualEnvelope) {
    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    canvas.tooltip("""
            Use the left mouse button to move/add points,
            use the right mouse button to remove them.
            Red dots indicate repeat start and end points,
            and cannot be removed.""".trimIndent())

    val width = canvas.width.toDouble()
    val height = canvas.height.toDouble()

    val length = manualEnvelope.instrument.envelopeLength

    fun getX(envX: Float): Double = envX / length * width
    fun getY(envY: Float): Double = (1f - envY) * height

    ctx.fillStyle = envelopBackgroundColor

    red = (red + 1) % 256
    ctx.fillRect(0.0, 0.0, width, height)

    ctx.fillStyle = envelopGridColor

    ctx.fillRect(0.0, height / 2.0, width.toDouble(), 1.0)
    ctx.fillRect(width / 2.0, 0.0, 1.0, height.toDouble())

    ctx.fillStyle = envelopeColor

    val env = manualEnvelope.instrument.envelope

    if (env.isNotEmpty()) {
      // lines
      val end = max(1, manualEnvelope.instrument.envelope.size - 2)

      ctx.beginPath()
      ctx.moveTo(0.0, height)
      ctx.lineTo(getX(0f), getY(env[0].y))

      for (index in 1..end) {
        ctx.lineTo(getX(env[index].x), getY(env[index].y))
      }

      ctx.lineTo(width, getY(env[env.size - 1].y))
      ctx.lineTo(width, height)
      //ctx.stroke()
      ctx.fill()

      // dots
      dot(ctx, 0.0, getY(env[0].y), envelopeDotColor)

      for (index in 1..end) {
        var color = envelopeDotColor
        if (index == manualEnvelope.instrument.envelopeLoopStart || index == manualEnvelope.instrument.envelopeLoopEnd) {
          color = envelopeLoopDotColor
        }
        dot(ctx, getX(env[index].x), getY(env[index].y), color)
      }

      dot(ctx, width, getY(env[env.size - 1].y), envelopeDotColor)
    }

  }

  private fun setManualEnvelopeMouseEvents(canvas: HTMLCanvasElement, envelope: ManualEnvelope) {

    canvas.onmousedown = {
      it.preventDefault()

      //println("MouseDown button: ${it.button}")
      // convert from canvas coords to envelope coords...
      val envX = it.offsetX / canvas.width.toFloat() * envelope.instrument.envelopeLength
      val envY = (canvas.height - it.offsetY) / canvas.height.toFloat()

      if (it.button == 0.toShort()) {
        point = envelope.findPoint(envX.toFloat(), envY.toFloat())

        if (point == -1) {
          envelope.insertPoint(envX.toFloat(), envY.toFloat())
          point = envelope.findPoint(envX.toFloat(), envY.toFloat())
        }
      } else {
        point = -1
        val pnt = envelope.findPoint(envX.toFloat(), envY.toFloat())
        if (pnt > -1) {
          //println("Remove point $pnt")
          envelope.removePoint(pnt)
        }
      }
      //println("Mousedown: ${envX}, ${envY} -> $point")

      update(envelope.instrument, canvas)
    }

    canvas.onmouseup = {
      point = -1

      false
    }

    canvas.onmouseleave = {
      point = -1

      false
    }

    canvas.onmousemove = {
      if (point >= 0) {
        var envX = it.offsetX / canvas.width.toFloat() * envelope.instrument.envelopeLength
        val envY = (canvas.height - it.offsetY) / canvas.height.toFloat()

        if (point > 0) {
          envX = max(envX, envelope.instrument.envelope[point - 1].x.toDouble())
        }
        if (point < envelope.instrument.envelope.size - 2) {
          envX = min(envX, envelope.instrument.envelope[point + 1].x.toDouble())
        }

        envelope.updatePoint(point, envX.toFloat(), envY.toFloat())

        if (point == envelope.instrument.envelopeLoopStart && envelope.instrument.envelopeLoopEnd >= 0) {
          envelope.instrument.envelope[envelope.instrument.envelopeLoopEnd].y = envY.toFloat()
        }

        if (point == envelope.instrument.envelopeLoopEnd && envelope.instrument.envelopeLoopEnd >= 0) {
          envelope.instrument.envelope[envelope.instrument.envelopeLoopStart].y = envY.toFloat()
        }

        update(envelope.instrument, canvas)
      }
    }

  }

  private fun dot(ctx: CanvasRenderingContext2D, x: Double, y: Double, color: String) {
    ctx.beginPath();
    ctx.fillStyle = color
    ctx.arc(x, y, dotRadius, 0.0, 2 * PI)
    ctx.fill()
  }

}
