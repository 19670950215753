package daw.component

import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onClickFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.HTMLInputElement

/**
 * User: rnentjes
 * Date: 14-5-17
 * Time: 13:12
 */

class Checkbox(
    val id: String,
    val name: String = id,
    val checked: Boolean = false,
    val changeHandler: (HTMLInputElement) -> Unit = {}
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.input {
    type = InputType.checkBox
    this.name = this@Checkbox.name
    this.id = this@Checkbox.id
    this.checked = this@Checkbox.checked

    onClickFunction = {
      val target = it.target
      if (target is HTMLInputElement) {
        changeHandler(target)
      } else {
        throw IllegalStateException("Wrong type! $target")
      }
    }
  }

}
