package daw.song

import daw.AudioHandler
import daw.player.InstrumentChannelPlayer
import daw.player.SampleInstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.effects.*

/**
 * User: rnentjes
 * Date: 14-11-15
 * Time: 11:58
 */

fun decodeEffects(txt: String): Effects {
  val result = Effects()
  if (txt.startsWith("{")) {
    val tmp = JSON.parse<Effects>(txt)

    if (tmp.pitchSlide != null) {
      result.pitchSlide = PitchSlide(tmp.pitchSlide?.semiNotes ?: 0, tmp.pitchSlide?.lines ?: 0)
    }
    if (tmp.setVolume != null) {
      result.setVolume = SetVolume(tmp.setVolume?.volume ?: 1f, tmp.setVolume?.retrigger ?: true)
    }
    if (tmp.volumeSlide != null) {
      result.volumeSlide = VolumeSlide(tmp.volumeSlide?.amount ?: 0f, tmp.volumeSlide?.lines ?: 0)
    }
    if (tmp.vibrato != null) {
      result.vibrato = Vibrato(tmp.vibrato?.speed ?: 0f, tmp.vibrato?.depth ?: 0f)
    }
    if (tmp.patternBreak != null) {
      result.patternBreak = PatternBreak(tmp.patternBreak?.newEntry ?: 0, tmp.patternBreak?.nextPattern ?: true)
    }
    if (tmp.panning != null) {
      result.panning = SetPanning(tmp.panning?.panning ?: 0f)
    }
    if (tmp.arpeggio != null) {
      result.arpeggio = SetArpeggio(
          tmp.arpeggio?.type ?: ArpeggioType.UP,
          tmp.arpeggio?.notes ?: 3,
          tmp.arpeggio?.lines ?: 1
      )
    }
  }

  return result
}

fun encodeEffects(fx: Effects): String = JSON.stringify(fx, { key, value ->
  var result: dynamic = undefined

  if (value != null) {
    result = value
  }

  result
})

class Effects {
  var setVolume: SetVolume? = null
  var volumeSlide: VolumeSlide? = null
  var pitchSlide: PitchSlide? = null
  var vibrato: Vibrato? = null
  var patternBreak: PatternBreak? = null
  var panning: SetPanning? = null
  var arpeggio: SetArpeggio? = null

  fun encode(): String {
    println("encoding track!")
    return JSON.stringify(this, { key, value ->
      var result: dynamic = undefined

      if (value != null) {
        result = value
      }

      result
    })
  }

  fun execute(track: Track, entry: Int, songPlayer: SongPlayer, instrumentChannelPlayer: InstrumentChannelPlayer) {
    // pitch slide
    pitchSlide?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    setVolume?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    volumeSlide?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    vibrato?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    patternBreak?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    panning?.execute(track, entry, songPlayer, instrumentChannelPlayer)
    arpeggio?.execute(track, entry, songPlayer, instrumentChannelPlayer)
  }

  override fun toString(): String {
    return "Effects(setVolume=$setVolume, volumeSlide=$volumeSlide, pitchSlide=$pitchSlide, vibrato=$vibrato, patternBreak=$patternBreak, panning=$panning, arpeggio=$arpeggio)"
  }

}

abstract class Effect {

  override fun toString(): String = "..."

  open fun encode(): String = "D---"

  abstract fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer)

}

class ModEffect(var effect: Byte = 0,
                var x: Byte = 0,
                var y: Byte = 0) : Effect() {
  companion object {
    val NIBBLE_TO_HEX = "0123456789ABCDEF"
    val HEX_TO_NIBLE = HashMap<Char, Byte>()

    init {
      HEX_TO_NIBLE['0'] = 0;
      HEX_TO_NIBLE['1'] = 1;
      HEX_TO_NIBLE['2'] = 2;
      HEX_TO_NIBLE['3'] = 3;
      HEX_TO_NIBLE['4'] = 4;
      HEX_TO_NIBLE['5'] = 5;
      HEX_TO_NIBLE['6'] = 6;
      HEX_TO_NIBLE['7'] = 7;
      HEX_TO_NIBLE['8'] = 8;
      HEX_TO_NIBLE['9'] = 9;
      HEX_TO_NIBLE['A'] = 10;
      HEX_TO_NIBLE['B'] = 11;
      HEX_TO_NIBLE['C'] = 12;
      HEX_TO_NIBLE['D'] = 13;
      HEX_TO_NIBLE['E'] = 14;
      HEX_TO_NIBLE['F'] = 15;
    }
  }

  constructor(data: Short) : this((data / 256).toByte(), (data / 16 % 16).toByte(), (data % 16).toByte())

  constructor(txt: String) : this() {
    effect = HEX_TO_NIBLE[txt[0]] ?: throw IllegalArgumentException("${txt[0]} is not a valid hex value!")
    x = HEX_TO_NIBLE[txt[1]] ?: throw IllegalArgumentException("${txt[1]} is not a valid hex value!")
    y = HEX_TO_NIBLE[txt[2]] ?: throw IllegalArgumentException("${txt[2]} is not a valid hex value!")
  }

  override fun toString(): String {
    var result = ""

    result += NIBBLE_TO_HEX[(effect % 0xf)]
    result += NIBBLE_TO_HEX[(x % 0xf)]
    result += NIBBLE_TO_HEX[(y % 0xf)]

    return result
  }

  override fun encode(): String = "M${toString()}"

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    val effectNumber = effect.toInt()

    when (effectNumber) {
      9 -> {
        // retrigger sample at offset
        if (player is SampleInstrumentChannelPlayer) {
          player.retrigger(x * 4096 + y * 256 / 2)
        }
      }
      12 -> {
        // Set volume
        //println("Setting volume: ${(1 / 64f) * (x * 16 + y)}")
        player.effectState.currentInstrumentVolume = (1 / 64f) * (x * 16 + y)
        player.cycleOffset = 0.0
      }
      13 -> {
        // Pattern break
        val newEntry = x * 10 + y

        songPlayer.jumpToEntry(newEntry, true)
      }
      15 -> {
        // set speed
        var speed = x * 16 + y
        var bpm = 125

        if (speed == 0) {
          speed = 1
        }

        if (speed <= 32) {
          bpm = (125 / 6.toFloat() * speed).toInt()
        } else {
          bpm = speed
        }

        songPlayer.samplesPerEntry = AudioHandler.sampleRate / bpm * 15
      }

    }
  }

}
