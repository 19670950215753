package daw.view.menu

import nl.astraeus.komp.Komponent
import kotlin.browser.document

/**
 * Created by rnentjes on 8-1-16.
 */

object MenuView {
  val menu = Menu()
  val loginMenu = LoginMenu()

  init {
    Komponent.create(document.body!!, menu)
    Komponent.create(document.body!!, loginMenu)
  }

  fun renderMenu() {
    menu.update()
    loginMenu.update()
  }

}
