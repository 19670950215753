package daw.view

import kotlinx.html.div
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import nl.astraeus.komp.include
import kotlin.browser.document

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 12:35
 */

class EmptyKomponent: Komponent() {
  override fun render(consumer: KompConsumer) = consumer.div {}
}

class ModalSpash(
    var komponent: Komponent
): Komponent() {
  var show = false

  override fun render(consumer: KompConsumer) = consumer.div(
      classes = if (show) {
        "menu_layer show"
      } else {
        "menu_layer"
      }
  ) {
    div(
        classes = if (show) {
          "menu_content show"
        } else {
          "menu_content"
        }
    ) {
      if (show) {
        include(komponent)
      }
    }
  }
}

object Modal {
  val modal: ModalSpash = ModalSpash(EmptyKomponent())

  init {
    println("Creating modal layers")
    Komponent.create(document.body!!, modal)
  }

  fun show(komponent: Komponent) {
    modal.komponent = komponent
    modal.show = true
    modal.update()
  }

  fun hide() {
    modal.show = false
    modal.update()
  }
}
