package daw.ws

import daw.Command
import daw.Daw
import daw.html.getCookie
import daw.html.parseParameters
import daw.html.setCookie
import daw.instrument.Instrument
import daw.instrument.InstrumentData
import daw.instrument.Sample
import daw.instrument.SampleData
import daw.instrument.SampleInfo
import daw.model.DawModel
import daw.note.Note
import daw.song.InstrumentPatternsData
import daw.song.InstrumentTrack
import daw.song.SongData
import daw.song.Track
import daw.song.TrackData
import daw.song.UserData
import daw.view.View
import daw.view.menu.MenuView
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import org.khronos.webgl.Int8Array
import org.w3c.dom.ARRAYBUFFER
import org.w3c.dom.BinaryType
import org.w3c.dom.CloseEvent
import org.w3c.dom.MessageEvent
import org.w3c.dom.WebSocket
import kotlin.browser.document
import kotlin.browser.window
import kotlin.js.Math.random
import kotlin.math.abs

/**
 * Created by rnentjes on 16-12-15.
 */

enum class BinaryMessageType(val type: Short) {
  UNKNOWN(0),
  SAMPLE(1),
  ;
}

object WebsocketConnection {
  private var ws: WebSocket? = null
  private var wsConnected: Boolean = false
  private var connecting: Boolean = false

  var songId = ""
  var playImmediatly = false
  var dirtyTracks = HashSet<Track>()
  var dirtyInstrumentPatterns = HashSet<InstrumentTrack>()
  var dirtyInstruments = HashSet<Instrument>()
  var loading = false
  var dirtySong = false
  var dirty = false

  fun init() {
    window.setInterval({
      checkWebsocketConnection()
    }, 500)

    connect()
  }

  fun setDirty(value: Boolean = true) {
    dirty = value

    View.updateDirty(dirty)
  }

  fun setSongIsDirty() {
    dirtySong = true
    setDirty()
  }

  fun addDirtyTrack(track: Track) {
    if (!loading) {
      dirtyTracks.add(track)
      setDirty()
    }
  }

  fun addDirtyInstrumentPatterns(instrumentPatterns: InstrumentTrack) {
    if (!loading) {
      dirtyInstrumentPatterns.add(instrumentPatterns)
      setDirty()
    }
  }

  fun addDirtyInstrument(instrument: Instrument) {
    if (!loading) {
      dirtyInstruments.add(instrument)
      setDirty()
    }
  }

  fun isConnected() = wsConnected

  fun checkWebsocketConnection() {
    if (!isConnected()) {
      connect(true)
    } else if (!Daw.importing) {
      View.setConnectionStatus("Saving.")

      if (dirtySong) {
        Daw.song.save()

        dirtySong = false
      }

      for (instrument in dirtyInstruments) {
        instrument.save()
      }
      dirtyInstruments.clear()
      for (ip in dirtyInstrumentPatterns) {
        ip.save()
      }
      dirtyInstrumentPatterns.clear()
      for (track in dirtyTracks) {
        track.save()
      }
      dirtyTracks.clear()
      setDirty(false)

      View.setConnectionStatus("Connected")
    }
  }

  fun connect(reconnecting: Boolean = false) {
    if (connecting) {
      return
    }
    connecting = true
    this.ws?.close()

    View.setConnectionStatus("Connecting")
    var url = "wss://${window.location.hostname}/daw"

    if (window.location.hostname.contains("mto") || window.location.hostname.contains("localhost")) {
      url = "ws://${window.location.hostname}:1234/daw"
    }

    val ws = WebSocket(url)
    ws.binaryType = BinaryType.ARRAYBUFFER

    if (document.location != null) {
      //println("Search=${document.location?.search}")
      val parameters = parseParameters(document.location!!.search)

      songId = if (parameters["song"] != null) {
        parameters["song"]!!
      } else {
        document.location!!.search.substring(1)
      }

      if (parameters["play"] != null) {
        playImmediatly = parameters["play"]!! == "true"
      }
    }

    ws.onopen = {
      connecting = false
      View.setConnectionStatus("Connected")
      wsConnected = true

      if (songId.isEmpty()) {
        val cmd = Command("GETKEY")

        cmd.token = getToken()

        ws.send(JSON.stringify(cmd))
      } else if (!reconnecting) {
        load(songId)
      } else {
        sendSongId()
      }

      window.setInterval({
        ws.send("OK")
      }, 60000)
    }

    ws.onmessage = {
      View.setConnectionStatus("Receiving")

      if (it is MessageEvent) {
        if (it.data is String) {
          //println("received -> ${it.data}")

          val data = it.data
          val command = JSON.parse<Command>(data as String)

          when (command.command) {
            "REDIRECT" -> {
              //println("REDIRECT")
              document.location!!.href = "${window.location.protocol}//${window.location.host}${window.location.pathname}?${command.songId}"
            }
            "SONGS" -> {
              if (command.songs != null) {
                DawModel.songList = command.songs!!
              }
            }
            "SONG" -> {
              if (command.song != null) {
                Daw.setSong(command.song!!)
              } else {
                View.showError("command.song missing in load song call!")
              }
            }
            "PATTERNS" -> {
              if (command.patterns != null) {
                Daw.song.setPatterns(command.patterns!!)
              } else {
                View.showError("command.patterns missing in load patterns call!")
              }
            }
            "SAMPLE" -> {
              if (command.sample != null) {
                //println("Sample: -> ${it.data}")

                val recSample = command.sample!!
                if (recSample.sampleNumber > -1) {
                  val sample = Daw.song.getSample(recSample.sampleNumber)

                  sample.sampleName = recSample.sampleName
                  sample.sampleNote = Note.valueOf(recSample.sampleNote)
                  sample.sampleRate = recSample.sampleRate
                  sample.bytesPerSample = recSample.bytesPerSample
                  sample.levels = recSample.levels
                  sample.repeatStart = recSample.repeatStart
                  sample.repeatLength = recSample.repeatLength
                }
              }
            }
            "INSTRUMENT" -> {
              if (command.instrument != null) {
                //println("Instrument: -> ${it.data}")

                Daw.song.setInstrument(command.instrument!!)
              } else {
                View.showError("command.instrument missing in load instrument call!")
              }
            }
            "TRACK" -> {
              if (command.track != null) {
                Daw.song.setTrack(command.track!!)
              } else {
                View.showError("command.track missing in load track call!")
              }
            }
            "LOADING_DONE" -> {
              View.render()

              loading = false
              Daw.unblock()

              if (playImmediatly) {
                window.setTimeout({
                  Daw.song.play()
                }, 500)
              }
            }
            "ALERT" -> {
              View.showError(command.message)
            }
            "MESSAGE" -> {
              View.showMessage(command.message)
            }
            "LOGGEDINUSER" -> {
              Daw.user = command.user

              MenuView.renderMenu()
            }
          }
        } else {
          handleBinary(it.data as ArrayBuffer)
        }
      }
    }

    ws.onclose = {
      connecting = false
      if (it is CloseEvent) {
        println("Connection closed: ${it.code} ${it.reason}")
        View.setConnectionStatus("Conn. closed: ${it.code} ${it.reason}")
      } else {
        View.setConnectionStatus("Connection closed")
      }

      wsConnected = false
      "dynamic"
    }

    ws.onerror = {
      connecting = false
      if (it is CloseEvent) {
        println("Connection closed (error): ${it.code} ${it.reason}")
        View.setConnectionStatus("Error connecting: ${it.code} ${it.reason}")
      } else {
        View.setConnectionStatus("Error connecting")
      }

      WebsocketConnection.wsConnected = false
      "dynamic"
    }

    this.ws = ws
  }

  private fun handleBinary(data: ArrayBuffer) {
    val dataView = DataView(data)

    // println("Bin data: ${data.byteLength}")
    // check length > 18
    if (data.byteLength > 1) {
//            for (index in 0..data.byteLength-1) {
//                println("Binrec: ${dataView.getInt8(index)}")
//            }
      val type = dataView.getInt16(0, true)
      when (type) {
        BinaryMessageType.SAMPLE.type -> {
          if (data.byteLength > 18) {
            val sampleNumber = dataView.getInt16(2, true)
            val stereo = dataView.getInt16(4, true) == 1.toShort()
            val sampleLength = dataView.getInt32(6, true)
            val chunkStart = dataView.getInt32(10, true)
            val chunkLength = dataView.getInt32(14, true)

            val sample = Daw.song.getSample(sampleNumber.toInt())

            if (sample.buffer.byteLength < sampleLength) {
              sample.buffer = ArrayBuffer(sampleLength)
            }

            sample.stereo = stereo
            // sample.sampleNote = Note.C3
            // sample.sampleRate = AudioHandler.sampleRate
            // sample.sampleName = "sample-name"

            val sourceInt8Array = Int8Array(data)
            val targetInt8Array = Int8Array(sample.buffer)

            // println("Sample $sampleNumber set data from: $chunkStart length: $chunkLength sampleLength: $sampleLength")

            targetInt8Array.set(sourceInt8Array.subarray(18, chunkLength + 18), chunkStart)
          }
        }
        else -> {
          throw IllegalStateException("Binary message type $type is not supported!")
        }
      }
    }
  }

  val tokenChars = "abcdefghijklmnopqrstuvmxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-"
  fun createToken(length: Int = 32): String {
    var result = ""

    while (result.length < length) {
      result += tokenChars[(abs(random() * tokenChars.length)).toInt()]
    }

    return result
  }

  fun getToken(): String {
    var result = getCookie("mto-token")

    if (result.isEmpty()) {
      result = createToken()
      setCookie("mto-token", result)
    }

    return result
  }

  fun load(songId: String) {
    loading = true
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("LOAD")

    cmd.songId = songId
    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun sendNewSong() {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("NEWSONG")

    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun sendCopySong() {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("COPYSONG")

    cmd.songId = Daw.song.songId
    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun sendLogin(name: String, email: String, pwd: String) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("LOGIN")

    cmd.user = UserData(name, email, pwd)
    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun sendLogout() {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("LOGOUT")

    cmd.songId = WebsocketConnection.songId
    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun sendSongId() {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("SONGID")

    cmd.songId = WebsocketConnection.songId
    cmd.token = getToken()

    myws.send(JSON.stringify(cmd))
  }

  fun saveSong(song: SongData) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("SONG")

    cmd.songId = songId
    cmd.token = getToken()
    cmd.song = song

    myws.send(JSON.stringify(cmd))
  }

  fun savePatterns(patterns: InstrumentPatternsData) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("PATTERNS")

    cmd.songId = songId
    cmd.token = getToken()
    cmd.patterns = patterns

    myws.send(JSON.stringify(cmd))
  }

  fun saveTrack(track: TrackData) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    val cmd = Command("TRACK")

    cmd.songId = songId
    cmd.token = getToken()
    cmd.track = track

    myws.send(JSON.stringify(cmd))
  }

  fun saveSample(sample: SampleData) {
    val blockSize = 32768
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")

    // send tekst info
    val cmd = Command("SAMPLE")

    cmd.songId = sample.songId
    cmd.token = getToken()
    cmd.sample = SampleInfo(
        sample.songId,
        sample.sampleNumber,
        sample.sampleName,
        sample.sampleNote,
        sample.sampleRate,
        sample.bytesPerSample,
        sample.levels,
        sample.repeatStart,
        sample.repeatLength
    )

    myws.send(JSON.stringify(cmd))

    val length = sample.buffer.byteLength
    var remaining = sample.buffer.byteLength

    while (remaining > 0) {
      // send binary sample data
      val sourceInt8Array = Int8Array(sample.buffer)
      val data = ArrayBuffer(18 + blockSize)
      val dataView = DataView(data)
      val targetInt8Array = Int8Array(data)

      var sourceView = DataView(sample.buffer)

      dataView.setInt16(0, BinaryMessageType.SAMPLE.type, true)
      // 2, short = sample number
      // 2, short = 1 == stereo
      // 4, int = sample length (bytes)
      // 4, int = chunk start
      // 4, int = chunk length
      dataView.setInt16(2, sample.sampleNumber.toShort(), true)
      if (sample.stereo) {
        dataView.setInt16(4, 1, true)
      } else {
        dataView.setInt16(4, 0, true)
      }
      dataView.setInt32(6, sample.buffer.byteLength, true)
      dataView.setInt32(10, 0, true)
      dataView.setInt32(14, 0, true)

      dataView.setInt32(10, length - remaining, true)

      if (remaining < blockSize) {
        dataView.setInt32(14, remaining, true)
        targetInt8Array.set(sourceInt8Array.subarray(length - remaining, length - remaining + remaining), 18)
        remaining = 0
      } else {
        dataView.setInt32(14, blockSize, true)
        targetInt8Array.set(sourceInt8Array.subarray(length - remaining, length - remaining + blockSize), 18)
        remaining -= blockSize
      }

      myws.send(data)
    }
  }

  fun saveInstrument(instrument: InstrumentData) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")
    val cmd = Command("INSTRUMENT")

    for (index in instrument.samples.indices) {
      var sample = Sample()

      if (instrument.samples[index] > -1) {
        sample = Daw.song.getSample(instrument.samples[index])
      }
      saveSample(SampleData(Daw.song.songId, instrument.samples[index], sample))
    }

//        if (instrument.samples.size > 0) {
//            saveSample(SampleData(Daw.song.songId, instrument.samples, Daw.song.getSample(instrument.samples)))
//        }

    cmd.songId = songId
    cmd.token = getToken()
    cmd.instrument = instrument

    myws.send(JSON.stringify(cmd))
  }

  fun sendRemoveInstrument(instrument: Instrument) {
    val myws: WebSocket = ws ?: throw IllegalStateException("No websocket connection!")
    val cmd = Command("REMOVE_INSTRUMENT")

    cmd.songId = songId
    cmd.token = getToken()
    cmd.instrument = instrument.data()

    loading = true
    myws.send(JSON.stringify(cmd))
  }

}
