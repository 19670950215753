package daw.player

import daw.AudioHandler
import daw.instrument.Instrument
import daw.note.Note
import daw.song.InstrumentTrack
import daw.song.Song
import daw.view.View
import kotlin.browser.window

class SongPlayer(val song: Song, val startPattern: Int, val playSinglePattern: Boolean = false) : Player {
  var entry = 0
  var stopped = false
  var samplesPerEntry = song.samplesPerEntry
  var currentSample = 0
  var currentPattern = startPattern

  var channels = Array(song.instruments.sumBy { it.numberOfChannels }) {
    createChannelPlayer(InstrumentPlayer(InstrumentTrack(song, Instrument(song.songId, -1)), 1), 0)
  }
  var notes = Array(0) {
    Array(0) {
      Note.NONE
    }
  }
  var emptyNoteList = Array(song.entriesPerPattern) {
    Note.NONE
  }

  init {
    notes = Array(channels.size) {
      Array(0) {
        Note.NONE
      }
    }

    getNotesForCurrentPattern()

    var index = 0
    for (ip in song.instruments) {
      //val instrumentPlayer = InstrumentPlayer(ip, ip.numberOfChannels)

      for (channel in 0 until ip.numberOfChannels) {
        channels[index] = createChannelPlayer(InstrumentPlayer(ip, ip.numberOfChannels), channel, ip.numberOfChannels)

        // play the first note
        channels[index].noteStart(Note.END)
        channels[index].noteStart(notes[index][entry])

        index++
      }
    }

    //handleEffects()

    View.playingPattern(currentPattern)
    View.playingEntry(entry)
  }

  fun jumpToEntry(entry: Int, nextPattern: Boolean = false) {
    if (nextPattern && !playSinglePattern) {
      currentPattern = (currentPattern + 1) % song.numberOfPatterns
    }
    this.entry = entry - 1

    nextEntry(true)

    AudioHandler.runAction {
      View.playingPattern(currentPattern)
      View.playingEntry(entry)
    }
  }

  private fun isNextEntryNewNote(): Boolean {
    var nextEntry = entry++
    var pattern = currentPattern

    if (entry == song.entriesPerPattern) {
      entry = 0
      if (!playSinglePattern) {
        currentPattern = (currentPattern + 1) % song.numberOfPatterns
      }
    }

    // todo: determine next note is new note or not
    return false
  }

  private fun nextEntry(getNotes: Boolean = false): Boolean {
    var updatePlaying = getNotes

    entry++
    currentSample = 0
    if (entry == song.entriesPerPattern) {
      entry = 0

      if (!playSinglePattern) {
        currentPattern = (currentPattern + 1) % song.numberOfPatterns
      }

      updatePlaying = true
    }

    if (updatePlaying) {
      getNotesForCurrentPattern()
    }

    for (index in channels.indices) {
      if (notes[index].isNotEmpty()) {
        channels[index].noteStart(notes[index][entry])
      }
    }

    handleEffects()

    return updatePlaying
  }

  private fun getNotesForCurrentPattern() {
    var index = 0
    for (ip in song.instruments) {
      val track = ip.getTrackAtPosition(currentPattern)
      for (channel in 0 until ip.numberOfChannels) {
        if (track != null) {
          notes[index] = track.notes[channel]
        } else {
          notes[index] = emptyNoteList
        }
        index++
      }
    }
  }

  private fun handleEffects() {
    var index = 0

    for (ip in song.instruments) {
      val track = ip.getTrackAtPosition(currentPattern)

      for (ch in 0 until ip.numberOfChannels) {
        if (track != null) {
          track.effects[entry].execute(track, entry, this, channels[index])
        }
        index++
      }
    }
  }

  override fun stop() {
    for (index in channels.indices) {
      channels[index].stop()
    }

    stopped = true
  }

  override fun fillBuffer(left: FloatArray, right: FloatArray, count: Int) {
    var done = 0

    while (done < count) {
      val samplesTillNextEntry = samplesPerEntry - currentSample
      val remaining = count - done
      var first = true

      if (remaining < samplesTillNextEntry) {
        channels.forEach {
          it.fillBuffer(left, right, done, remaining, first)
          first = false
        }

        done += remaining
        currentSample += remaining
      } else {
        channels.forEach {
          it.fillBuffer(left, right, done, samplesTillNextEntry, first)
          first = false
        }

        val updatePlaying = !stopped && nextEntry()

        done += samplesTillNextEntry

        AudioHandler.runAction {
          if (updatePlaying) {
            View.playingPattern(currentPattern)
          }

          View.playingEntry(entry)
        }
      }
    }
  }
}
