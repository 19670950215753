package daw.instrument

import daw.note.Note
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView

/**
 * User: rnentjes
 * Date: 28-12-15
 * Time: 15:24
 */

/* Tekst based sample info */
class SampleInfo(var songId: String,
                 var sampleNumber: Int,
                 var sampleName: String = "",
                 var sampleNote: String = "",
                 var sampleRate: Int = 0,
                 var bytesPerSample: Int = 2,
                 var levels: Int = -1,
                 var repeatStart: Int = 0,
                 var repeatLength: Int = 0) {

}

class SampleData(var songId: String,
                 var sampleNumber: Int,
                 var buffer: ArrayBuffer = ArrayBuffer(0),
                 var sampleName: String = "",
                 var stereo: Boolean = false,
                 var sampleNote: String = "",
                 var sampleRate: Int = 0,
                 var bytesPerSample: Int = 2,
                 var levels: Int = -1,
                 var repeatStart: Int = 0,
                 var repeatLength: Int = 0) {

  constructor(songId: String, number: Int, sample: Sample) :
      this(songId, number, sample.buffer, sample.sampleName, sample.stereo, sample.sampleNote.name, sample.sampleRate, sample.bytesPerSample, sample.levels, sample.repeatStart, sample.repeatLength)

}

class Sample() {
  var stereo: Boolean = false
  var sampleRate: Int = 44100
  var sampleNote: Note = Note.C3
  var sampleName: String = ""
  var sampleNumber = 0
  var bytesPerSample = 2
  var levels = -1
  var repeatStart: Int = 0
  var repeatLength: Int = 0
  var buffer: ArrayBuffer = ArrayBuffer(0)
    set(value) {
      field = value
      dataView = DataView(buffer)
    }
  var dataView: DataView = DataView(buffer)

  constructor(stereo: Boolean,
              sampleRate: Int,
              sampleNote: Note,
              sampleName: String,
              repeatStart: Int = 0,
              repeatLength: Int = 0) : this() {
    this.stereo = stereo
    this.sampleRate = sampleRate
    this.sampleNote = sampleNote
    this.sampleName = sampleName
    this.repeatStart = repeatStart
    this.repeatLength = repeatLength
  }

  constructor(buffer: ArrayBuffer, stereo: Boolean, sampleRate: Int, sampleNote: Note, sampleName: String) : this(stereo, sampleRate, sampleNote, sampleName) {
    this.buffer = buffer
    this.bytesPerSample = 2
  }

  fun setSamples(samples: Int) {
    val oldDataView = dataView
    val oldSamples = samples()

    bytesPerSample = 2
    if (stereo) {
      buffer = ArrayBuffer(samples * 4)
    } else {
      buffer = ArrayBuffer(samples * 2)
    }

    for (index in 0 until samples) {
      val oldIndex = (oldSamples * index / samples.toFloat()).toInt()

      if (stereo) {
        if (oldIndex * 4 < oldDataView.byteLength - 3) {
          dataView.setInt16(index * 4, oldDataView.getUint16(oldIndex * 4, true), true)
          dataView.setInt16(index * 4 + 2, oldDataView.getUint16(oldIndex * 4 + 2, true), true)
        } else {
          dataView.setInt16(index * 4, 0, true)
          dataView.setInt16(index * 4 + 2, 0, true)
        }
      } else {
        if (oldIndex * 2 < oldDataView.byteLength - 1) {
          dataView.setInt16(index * 2, oldDataView.getUint16(oldIndex * 2, true), true)
        } else {
          dataView.setInt16(index * 2, 0, true)
        }
      }
    }
  }

  fun samples(): Int {
    if (stereo) {
      return buffer.byteLength / (bytesPerSample * 2)
    } else {
      return buffer.byteLength / bytesPerSample
    }
  }

  fun leftSampleValue(index: Int): Short {
    var byteOffset = index * bytesPerSample
    if (stereo) {
      byteOffset *= 2
    }
    if (bytesPerSample > 2) {
      byteOffset += (bytesPerSample - 2)
    }

    if (dataView.byteLength > byteOffset + 1) {
      return dataView.getInt16(byteOffset, true)
    } else {
      return 0
    }
  }

  fun rightSampleValue(index: Int): Short {
    var byteOffset = index * bytesPerSample

    if (stereo) {
      byteOffset *= 2
      byteOffset += bytesPerSample
    }

    if (bytesPerSample > 2) {
      byteOffset += (bytesPerSample - 2)
    }

    if (dataView.byteLength > byteOffset + 1) {
      return dataView.getInt16(byteOffset, true)
    } else {
      return 0
    }
  }

  fun setSample(sample: Int, value: Short) {
    var byteOffset = sample * bytesPerSample

    if (stereo) {
      byteOffset *= 2
    }
    if (bytesPerSample > 2) {
      byteOffset += (bytesPerSample - 2)
    }

    dataView.setInt16(byteOffset, value, true)

    if (stereo) {
      byteOffset += bytesPerSample
      if (bytesPerSample > 2) {
        byteOffset += (bytesPerSample - 2)
      }
      dataView.setInt16(byteOffset, value, true)
    }
  }

}
