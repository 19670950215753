package daw.view.instrument

import daw.Settings
import daw.html.cls
import daw.instrument.Sample
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.Element
import org.w3c.dom.HTMLCanvasElement
import kotlin.browser.document

/**
 * User: rnentjes
 * Date: 13-11-16
 * Time: 13:29
 */

object WaveView {

  fun create(
      wave: Sample,
      width: Int = Settings.defaultCanvasWidth,
      height: Int = Settings.defaultCanvasHeight
  ): Element {
    val canvas = document.createElement("canvas") as HTMLCanvasElement

    canvas.width = width
    canvas.height = height

    canvas.cls("wavecanvas")

    update(canvas, wave)

    return canvas
  }

  fun update(
      element: Element,
      wave: Sample
  ) {
    if (element is HTMLCanvasElement) {
      val context = element.getContext("2d") as CanvasRenderingContext2D

      val width = context.canvas.width
      val height = context.canvas.height

      context.fillStyle = "#3b3b3b"

      val lines = 8
      for (index in 1 until lines) {
        context.fillRect(index * (width / lines.toDouble()), 0.0, 1.0, height.toDouble())
        context.fillRect(0.0, index * (height / lines.toDouble()), width.toDouble(), 1.0)
      }

      for (x in 1 until width) {
        val index = wave.samples() * x / width
        val yl = -(height / 2) * (wave.leftSampleValue(index) / Short.MAX_VALUE.toFloat())
        val yr = -(height / 2) * (wave.rightSampleValue(index) / Short.MAX_VALUE.toFloat())

        context.fillStyle = "rgba(116,96,76,0.6)"
        context.fillRect(x.toDouble(), (height / 2.0), 1.0, yl.toDouble())
        context.fillStyle = "rgba(116,76,96,0.6)"
        context.fillRect(x.toDouble(), (height / 2.0), 1.0, yr.toDouble())
      }

      if (wave.repeatStart > 0) {
        val wx = 1f / wave.samples().toDouble() * wave.repeatStart
        val ww = 1f / wave.samples().toDouble() * wave.repeatLength

        context.fillStyle = "rgba(75,75,75,0.4)"
        context.fillRect(wx * width.toDouble(), 0.0, ww * width.toDouble(), height.toDouble());
      }
    }
  }
}
