package daw.import

import daw.Daw
import daw.instrument.EnvelopeType
import daw.instrument.Instrument
import daw.instrument.InstrumentType
import daw.note.Note
import daw.song.ModEffect
import daw.song.Song
import daw.song.TrackData
import daw.song.decodeEffects
import daw.view.View
import daw.ws.WebsocketConnection
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.DataView
import kotlin.math.max

/**
 * Created by rnentjes on 8-1-16.
 */

data class SampleInfo(
    val name: String,
    val length: Int,
    val finetune: Int,
    val volume: Int,
    val repeatStart: Int,
    val repeatLength: Int
)

object Mod {
  private var periodToNote = mapOf(
      1712 to Note.C0,
      1616 to Note.C0s,
      1525 to Note.D0,
      1440 to Note.D0s,
      1357 to Note.E0,
      1281 to Note.F0,
      1209 to Note.F0s,
      1141 to Note.G0,
      1077 to Note.G0s,
      1017 to Note.A0,
      961 to Note.A0s,
      907 to Note.B0,

      856 to Note.C1,
      808 to Note.C1s,
      762 to Note.D1,
      720 to Note.D1s,
      678 to Note.E1,
      640 to Note.F1,
      604 to Note.F1s,
      570 to Note.G1,
      538 to Note.G1s,
      508 to Note.A1,
      480 to Note.A1s,
      453 to Note.B1,

      428 to Note.C2,
      404 to Note.C2s,
      381 to Note.D2,
      360 to Note.D2s,
      339 to Note.E2,
      320 to Note.F2,
      302 to Note.F2s,
      285 to Note.G2,
      269 to Note.G2s,
      254 to Note.A2,
      240 to Note.A2s,
      226 to Note.B2,

      214 to Note.C3,
      202 to Note.C3s,
      190 to Note.D3,
      180 to Note.D3s,
      170 to Note.E3,
      160 to Note.F3,
      151 to Note.F3s,
      143 to Note.G3,
      135 to Note.G3s,
      127 to Note.A3,
      120 to Note.A3s,
      113 to Note.B3,

      107 to Note.C4,
      101 to Note.C4s,
      95 to Note.D4,
      90 to Note.D4s,
      85 to Note.E4,
      80 to Note.F4,
      76 to Note.F4s,
      71 to Note.G4,
      67 to Note.G4s,
      64 to Note.A4,
      60 to Note.A4s,
      57 to Note.B4
  )

  var samples31formats = arrayOf("M.K.", "")

  fun load(data: ArrayBuffer) {
    if (Daw.song.hasNotes()) {
      View.showError("Module import only allowed on empty songs!")

      return
    }

    Daw.song = Song(Daw.song.songId, "", "", 125)

    Daw.song.setEntriesPerPattern(64)
    Daw.song.setLinesPerBeat(4)
    Daw.song.setBeatsPerTrack(16)

    Daw.song.updateSamplesPerEntry()

    // map mod instrument number on mto instrument number
    val instrumentMapping = HashMap<Int, Int>()
    val dataView = DataView(data)
    //var nrSamples = 31

    val name = StringBuilder()

    for (index in 0..19) {
      val uint8 = dataView.getUint8(index)
      if (uint8 > 0) {
        name.append(uint8.toChar())
      }
    }

    Daw.song.name = name.toString()
    WebsocketConnection.setSongIsDirty()

    var offset = 20 + 30 * 31 + 130

    val ch1 = dataView.getUint8(offset++).toChar()
    val ch2 = dataView.getUint8(offset++).toChar()
    val ch3 = dataView.getUint8(offset++).toChar()
    val ch4 = dataView.getUint8(offset).toChar()

    var str = "$ch1$ch2$ch3$ch4"

    val samples = Array(31) {
      SampleInfo(
          "",
          0,
          0,
          0,
          0,
          0
      )
    }

    for (index in 1..31) {
      samples[index] = getSampleInfo(dataView, index)
    }

    val patternsPlayed = dataView.getUint8(20 + 31 * 30)
    var maxPatternNumber = 0

    offset = 20 + 31 * 30 + 2
    for (index in 0 until patternsPlayed) {
      val pn = dataView.getUint8(offset++)

      //println("Pattern $index -> $pn")

      maxPatternNumber = max(maxPatternNumber, pn.toInt())
    }

    //println("Max pattern number: $maxPatternNumber")

    val patternOffset = 20 + 31 * 30 + 130 + 4
    val sampleOffset = patternOffset + (maxPatternNumber + 1) * 1024

    var currentOffset = sampleOffset
    println("Creating instruments")

    // read samples and create instruments
    for (index in 1..31) {
      if (samples[index].length > 0) {
        //println("Saving sample $index length = ${samples[index].length}")

        val buffer = ArrayBuffer(samples[index].length * 2)
        val bufferDataView = DataView(buffer)

        for (sample in 0 until samples[index].length) {
          bufferDataView.setInt16(
              sample * 2,
              (dataView.getInt8(sample + currentOffset) * 256).toShort(),
              true
          )

          if (sample < 4) {
            //println("Value $sample = ${(dataView.getInt8(sample + currentOffset) * 256).toShort()}")
          }
        }

        val sample = Daw.song.getNewSample()

        sample.buffer = buffer
        sample.sampleRate = 8287
        sample.sampleNote = Note.C2
        sample.sampleName = samples[index].name
        sample.repeatStart = samples[index].repeatStart * 2
        sample.repeatLength = samples[index].repeatLength * 2

        val instrument = Instrument(Daw.song.songId, -1)

        instrument.envelopeType = EnvelopeType.NONE
        instrument.type = InstrumentType.SAMPLE
        instrument.samples = Array(1) { sample.sampleNumber }
        instrument.volume = (1f / 64f) * samples[index].volume.toFloat()

        Daw.song.addInstrument(instrument)
        instrument.save()

        instrumentMapping[index] = instrument.number

        currentOffset += samples[index].length
      }
    }

    val song = Daw.song

    if (!song.instruments.isEmpty() && song.instruments[0].instrument.type == InstrumentType.BASIC) {
      song.removeInstrument(song.instruments[0])
    }

    println("Created instruments")

    // read patterns
    val numberOfChannels = 4
    val numberOfInstruments = Daw.song.instruments.size

    val currentInstrument = Array(numberOfChannels) { -1 }

    // 7654-3210 7654-3210 7654-3210 7654-3210
    // wwww xxxxxxxxxxxxxx yyyy zzzzzzzzzzzzzz
    for (pattern in 0..patternsPlayed) {
      val currentTracks = Array(numberOfInstruments) {
        TrackData(
            Daw.song.songId,
            -1,
            -1,
            Array(4) { Array(64) { "NONE" } },
            Array(64) { "{}" }
        )
      }
      val track = dataView.getUint8(20 + 31 * 30 + 2 + pattern)
      for (entry in 0..63) {
        val entryOffset = patternOffset + 1024 * track + entry * 4 * numberOfChannels
        for (channel in 0 until numberOfChannels) {
          val left = channel == 1 || channel == 2
          val channelOffset = entryOffset + channel * 4
          var instr = (dataView.getUint8(channelOffset) / 16) * 16 + (dataView.getUint8(channelOffset + 2) / 16)
          val period = (dataView.getUint8(channelOffset) % 16) * 256 + dataView.getUint8(channelOffset + 1)
          val effect = (dataView.getUint8(channelOffset + 2) % 16) * 256 + dataView.getUint8(channelOffset + 3)
          val note = periodToNote[period]

          val dawInstr = instrumentMapping[instr]

          if (dawInstr != null) {
            if (period != 0 && note != null) {
              val currentDawInstr = currentInstrument[channel]
              if (currentDawInstr >= 0 && currentTracks[currentInstrument[channel]].notes[channel][entry] == Note.NONE.name) {
                currentTracks[currentInstrument[channel]].notes[channel][entry] = Note.END.name
              }
              //println("Mod instr $instr to daw intr $dawInstr")
              //println("Note $note period $period")
              // console.log("import note: ", note)
              currentTracks[dawInstr].notes[channel][entry] = note.name
              //println("[$track-$entry-$sample] -> $dawInstr, ${note.description}")
            }
            currentInstrument[channel] = dawInstr
          }

          if (effect > 0 && currentInstrument[channel] >= 0) {
//                        if (pattern == 0) {
//                            println("Channel: $sample.$entry found effect: ${ModEffect(effect.toShort()).encode()} current instrument: ${currentInstrument[sample]}")
//                        }

            currentTracks[currentInstrument[channel]].setEffect(entry, ModEffect(effect.toShort()))
          }
        }
      }

      // check if tracks exists etc.
      for (instr in 0 until numberOfInstruments) {
        val instrPattern = Daw.song.instruments[instr]
        if (currentTracks[instr].hasData()) {
          while (instrPattern.patternList.size < pattern + 1) {
            instrPattern.patternList.add(-1)
          }
          while (Daw.song.numberOfPatterns < pattern + 1) {
            Daw.song.addPattern()
          }

          //println("Find track")
          val found = instrPattern.findTrack(currentTracks[instr])
          //println("Find track done")

          if (found != null) {
            instrPattern.patternList[pattern] = found.number
          } else {
            instrPattern.addAvailableTrack()
            val newTrack = instrPattern.availablePatterns[instrPattern.availablePatterns.size - 1]

            // fill with the data
            var channels = currentTracks[instr].usedTracks()
            if (channels > instrPattern.numberOfChannels) {
              instrPattern.setChannels(channels)
            } else {
              channels = instrPattern.numberOfChannels
            }
            //println("Track: ${newTrack.number} has $channels channels.")

            for (entry in 0..63) {
              var usedChannel = 0
              for (channel in 0..3) {
                val note = currentTracks[instr].notes[channel][entry]
                //println("Instr: $instr track: ${newTrack.number} entry: $entry note: ${note}")
                if (note != "NONE") {
                  newTrack.notes[usedChannel++][entry] = Note.valueOf(note)
                }
              }
              newTrack.effects[entry] = decodeEffects(currentTracks[instr].effects[entry])
            }

            instrPattern.patternList[pattern] = newTrack.number
            newTrack.save()
          }
        } else {
          //println("Instr: $instr pattern: $pattern has not data!")
        }
      }
    }

    println("Saving patterns")
    for (instr in 0 until Daw.song.instruments.size) {
      val instrPattern = Daw.song.instruments[instr]
      instrPattern.save()
    }
    println("DONE")
  }

  private fun getSampleInfo(dataView: DataView, nr: Int): SampleInfo {
    val offset = (nr - 1) * 30 + 20
    val name = StringBuilder()

    for (index in 0..21) {
      val uint8 = dataView.getUint8(offset + index)
      if (uint8 > 0) {
        name.append(uint8.toChar())
      }
    }

//        println("Sample: [$nr - $name]")

    val length = dataView.getUint16(offset + 22) * 2
    val fineTune = dataView.getUint8(offset + 24)
    val volume = dataView.getUint8(offset + 25)
    val startRepeat = dataView.getUint16(offset + 26)
    val repeatLength = dataView.getUint16(offset + 28)

//        println("Length: $length")
//        println("Finetune: $finetune")
//        println("Volume: $volume")
//        println("StartRepeat: $startRepeat")
//        println("RepeatLength: $repeatLength")

/*
    val sampleDataOffset = 0 // ? depends on patterns length and previous samples length
    val buffer = ArrayBuffer(length * 2)
    val dataBuffer = DataView(buffer)

    for (index in 0 until length) {
      dataBuffer.setInt16(
          index * 2,
          (dataView.getInt8(sampleDataOffset + index) * 256).toShort(),
          true
      )
    }
*/

    return SampleInfo(
        name.toString(),
        length,
        fineTune.toInt(),
        volume.toInt(),
        startRepeat.toInt(),
        repeatLength.toInt()
    )
  }
}
