package daw.input

import daw.AudioHandler
import daw.Daw
import daw.instrument.Instrument
import daw.note.Note
import daw.note.getNoteKeyMap
import daw.player.InstrumentPlayer
import daw.song.InstrumentTrack
import daw.view.PatternsView
import daw.view.PatternView
import daw.ws.WebsocketConnection
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.WheelEvent

/**
 * User: rnentjes
 * Date: 27-12-15
 * Time: 12:29
 */

object InstrumentController : InputHandler {
  override fun mouseWheel(event: WheelEvent) {
  }

  var instrument = Instrument("", -1)
  var player = InstrumentPlayer(InstrumentTrack(instrument), 1)

  var noteKeyMap: Map<Int, Note> = HashMap()

  fun setInstrument(instrument: Instrument) {
    this.instrument = instrument
    this.player = InstrumentPlayer(InstrumentTrack(InstrumentController.instrument), 1)

    AudioHandler.play(player)
  }

  override fun focus() {
    noteKeyMap = getNoteKeyMap(3)
  }

  override fun unfocus() {
  }

  override fun keyDown(key: Event) {
    if (key is KeyboardEvent) {
      if (noteKeyMap.containsKey(key.keyCode)) {
        player.playNote(noteKeyMap[key.keyCode]!!)
      }
    }
  }

  override fun keyUp(key: Event) {
    if (key is KeyboardEvent) {
      if (noteKeyMap.containsKey(key.keyCode)) {
        player.noteUp(noteKeyMap[key.keyCode]!!)
      }
    }
  }

  fun save(instrument: Instrument) {
    if (instrument.number < 0) {
      Daw.song.addInstrument(instrument)

      WebsocketConnection.setSongIsDirty()
      instrument.save()

      PatternsView.renderPatternList()
      PatternView.renderTracks(true)
    } else {
      Daw.song.instruments[instrument.number].instrument = instrument
      Daw.song.instruments[instrument.number].instrument.save()

      WebsocketConnection.setSongIsDirty()

      PatternsView.renderPatternList()
      PatternView.renderTracks(true)
    }
  }

}
