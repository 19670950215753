package daw.song.effects

import daw.player.InstrumentChannelPlayer
import daw.player.SongPlayer
import daw.song.Effect
import daw.song.Track

/**
 * User: rnentjes
 * Date: 11-3-17
 * Time: 16:29
 */

enum class ArpeggioType {
  UP,
  DOWN,
  BOUNCE
}

class SetArpeggio(var type: ArpeggioType, var notes: Int, var lines: Int) : Effect() {

  override fun execute(track: Track, entry: Int, songPlayer: SongPlayer, player: InstrumentChannelPlayer) {
    player.effectState.setArpeggio(this)
  }

}
