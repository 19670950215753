package daw.view.menu

import daw.Daw
import daw.view.Modal
import daw.ws.WebsocketConnection
import kotlinx.html.div
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 12:15
 */

class Menu: Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "menu") {
    id = "menu"

    div(classes = "menu_title") {
      + "NEW SONG"

      onClickFunction = {
        WebsocketConnection.sendNewSong()
      }
    }

    div(classes = "menu_title") {
      + "COPY SONG"

      onClickFunction = {
        WebsocketConnection.sendCopySong()
      }
    }

    div(classes = "menu_title") {
      + "SONGS"

      onClickFunction = {
        Modal.show(SongsView())
      }
    }

    div(classes = "menu_title") {
      + "IMPORT"

      onClickFunction = {
        Modal.show(ImportView())
      }
    }

    div(classes = "menu_title") {
      + "EXPORT"

      onClickFunction = {
        Daw.song.exportWav()
      }
    }
  }

}
