package daw

import daw.instrument.InstrumentData
import daw.instrument.SampleInfo
import daw.song.*

/**
 * Created by rnentjes on 20-11-15.
 */
class Command(val command: String) {
  var songId: String = ""
  var token: String = ""
  var message: String = ""

  var user: UserData? = null
  var songs: Array<SongInfo>? = null
  var song: SongData? = null
  var track: TrackData? = null
  var patterns: InstrumentPatternsData? = null
  var instrument: InstrumentData? = null
  var sample: SampleInfo? = null
}
