package daw.view.effect

import kotlinx.html.DIV
import kotlinx.html.div
import kotlinx.html.style

/**
 * User: rnentjes
 * Date: 10-3-17
 * Time: 17:14
 */

abstract class ActiveHolder(var active: Boolean = false) {
  abstract fun activeSwitched()
}

fun DIV.effect(label: String, block: DIV.() -> kotlin.Unit) =
    div(classes = "row") {
      div(classes = "cell") {
        +label
      }
      div(classes = "cell")
      div(classes = "cell") {
        style = "float: right;"

        block()
      }
    }
