package daw.view.menu

import daw.Settings
import daw.view.Modal
import kotlinx.html.a
import kotlinx.html.div
import kotlinx.html.h1
import kotlinx.html.i
import kotlinx.html.js.onClickFunction
import kotlinx.html.style
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent

/**
 * User: rnentjes
 * Date: 23-12-18
 * Time: 12:29
 */

class LoginView: Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div {
    val clientId = Settings.getClientId()
    val state = "1234567890"
    val googleClientId = Settings.getGoogleClientId()
    val googleRedidectUri = Settings.getGoogleRedirectUri()
    val facebookAppId = Settings.getFacebookAppId()
    val facebookRedirectUri = Settings.getFacebookRedirectUri()

    h1 {
      + "LOGIN MENU"
    }

    div {
      div(classes = "row") { + "" }
      div(classes = "row") { + "" }
      div(classes = "row") {
        div(classes = "cell") {
          style = "vertical-align: bottom; padding: 40px;"
          a {
            href = "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=$googleRedidectUri&scope=openid%20email&state=$state&client_id=$googleClientId"

            i(classes = "fab fa-google") {
              style="font-size: 76px;"
            }
            +"Google"
          }
        }
        div(classes = "cell") {
          style = "vertical-align: bottom; padding: 40px;"
          a {
            href = "https://www.facebook.com/v2.8/dialog/oauth?client_id=$facebookAppId&redirect_uri=$facebookRedirectUri&scope=email&state=$state"

            i(classes = "fab fa-facebook-square") {
              style="font-size: 76px;"
            }
            +"Facebook"
          }
        }
        div(classes = "cell") {
          style = "vertical-align: bottom; padding: 40px;"
          a {
            href = "https://github.com/login/oauth/authorize?scope=user:email&client_id=$clientId"

            i(classes = "fab fa-github-square") {
              style="font-size: 76px;"
            }
            +"Github"
          }
        }
      }
      div(classes = "row") { + "" }
      div(classes = "row") { + "" }

      div(classes = "row") {
        div(classes = "cell") {
          + ""
        }
        div(classes = "cell") {
          div(classes = "button") {
            + "Close"

            onClickFunction = {
              Modal.hide()
            }
          }
        }
      }
    }
  }

}
