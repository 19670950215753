package daw.input

import org.w3c.dom.events.Event
import org.w3c.dom.events.WheelEvent
import kotlin.browser.document
import kotlin.browser.window

/**
 * User: rnentjes
 * Date: 13-12-15
 * Time: 16:43
 */

interface InputHandler {

  fun keyDown(key: Event)

  fun keyUp(key: Event)

  fun focus()

  fun unfocus()

  fun mouseWheel(event: WheelEvent)

}

object Input {
  var focus: InputHandler? = null

  init {
    val body = document.body
    if (body != null) {
      body.onkeydown = {
        keyDown(it)
      }

      body.onkeyup = {
        keyUp(it)
      }

      window.onwheel = {
        mouseWheel(it as WheelEvent)
      }
    }

    document.oncontextmenu = { false }
  }

  fun setFocus(inputHandler: InputHandler) {
    if (inputHandler != focus) {
      if (focus != null) {
        focus!!.unfocus()
      }

      focus = inputHandler

      focus!!.focus()
    }
  }

  fun clearFocus() {
    focus = null
  }

  fun keyDown(key: Event) {
//        if (key is KeyboardEvent) {
//            println("${key.keyCode}")
//        }
    if (focus != null) {
      focus!!.keyDown(key)
    }
  }

  fun keyUp(key: Event) {
    if (focus != null) {
      focus!!.keyUp(key)
    }
  }

  fun mouseWheel(event: WheelEvent) {
    if (focus != null) {
      focus!!.mouseWheel(event)
    }
  }

}