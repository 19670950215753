package daw

import daw.player.Player
import daw.processor.PostProcessor
import org.w3c.dom.MessageEvent
import org.w3c.dom.Worker

/**
 * User: rnentjes
 * Date: 15-11-15
 * Time: 16:21
 */

object AudioHandler {
  var worker: Worker? = null
  var audioContext: dynamic = null
  private var player: Player? = null
  private var processors: Array<out PostProcessor> = arrayOf()

  val bufferSize = 1024
  var sampleRate: Int = 44100
  val bufferTime = bufferSize.toFloat() / sampleRate.toFloat()
  var freeBuffers: ArrayList<dynamic> = ArrayList()
  var playTime: Float = 0f
  var stopTime: Float? = null
  var buffersCreated = 0
  val actions : MutableSet<()->Unit> = LinkedHashSet()

  fun play(player: Player, vararg processors: PostProcessor) {
    stopTime = null
    if (audioContext == null) {
      js("window.AudioContext = window.AudioContext || window.webkitAudioContext")

      audioContext = js("new window.AudioContext()")
      this.sampleRate = audioContext.sampleRate as Int
      println("Sample rate = ${this.sampleRate}")
    }

    this.player = player
    this.processors = processors
    playTime = audioContext.currentTime

    pumpAudio()
  }

  fun runAction(action: () -> Unit) {
    actions.add(action)
  }

  fun pumpAudio() {
    // continue playing for 5 seconds after stop command
    if (audioContext.currentTime - (stopTime ?: audioContext.currentTime) < 5f) {
      // keep 2 buffers
      while (playTime - (audioContext.currentTime as Float) < bufferTime * 6) {
        val buffer = if (freeBuffers.isNotEmpty()) {
          freeBuffers.removeAt(0)
        } else {
          buffersCreated++
          audioContext.createBuffer(2, bufferSize, sampleRate)
        }

        generateAudio(playTime - (audioContext.currentTime as Float), buffer.getChannelData(0), buffer.getChannelData(1), bufferSize)

        val bs = audioContext.createBufferSource()
        bs.buffer = buffer
        bs.connect(audioContext.destination)
        bs.start(playTime)
        bs.onended = {
          freeBuffers.add(buffer)
          pumpAudio()
        }
        playTime += bufferTime
      }
    }
    while(actions.isNotEmpty()) {
      val action = actions.first()
      actions.remove(action)
      action()
    }
  }

  fun generateAudio(delta: Float, left: FloatArray, right: FloatArray, count: Int) {
    val localPlayer = player

    /*    if (delta < bufferTime) {
            Settings.displayTracksDuringPlayback = false

            View.showMessage("Stopped patterns update to prevent buffer underrun. [$delta]")
        } else {
          Settings.displayTracksDuringPlayback = true

          View.showMessage("")
        }*/

    if (localPlayer != null) {
      localPlayer.fillBuffer(left, right, count)
    } else {
      for (index in 0 until count) {
        left[index] = 0f
        right[index] = 0f
      }
    }

    for (processor in processors) {
      processor.process(left, right, count)
    }
  }

  fun isPlaying() = player != null

  fun stop() {
    player?.stop()
    stopTime = audioContext.currentTime
  }

}
