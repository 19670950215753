package daw.song

import daw.note.Note
import daw.song.effects.PatternBreak
import daw.song.effects.SetVolume
import daw.song.effects.Vibrato
import daw.song.effects.VolumeSlide
import daw.ws.WebsocketConnection
import kotlin.math.max

/**
 * User: rnentjes
 * Date: 14-11-15
 * Time: 11:47
 */

class TrackData(
    var songId: String,
    var instrument: Int,
    var number: Int,
    var notes: Array<Array<String>>,
    var effects: Array<String>) {

  fun hasData() = usedTracks() > 0

  fun usedTracks(): Int {
    var result = 0
    var entries = 0

    for (channel in 0..notes.size - 1) {
      entries = max(entries, notes[channel].size)
    }

    for (entry in 0..entries - 1) {
      var count = 0
      for (channel in 0..notes.size - 1) {
        if (notes[channel][entry] != "NONE") {
          count++
        }
      }
      result = max(result, count)
    }

    return result;
  }

  fun setEffect(index: Int, effect: Effect) {
    val fx = JSON.parse<Effects>(effects[index])

    if (effect is ModEffect) {
      if (effect.effect == 4.toByte()) {
        fx.vibrato = Vibrato(effect.x.toFloat(), (effect.y / 64f))
      } else if (effect.effect == 10.toByte()) {
        if (effect.x > 0) {
          // vol slide up
          fx.volumeSlide = VolumeSlide(+(3 / 64f) * effect.x, 1)
        } else if (effect.y > 0) {
          // vol slide down
          fx.volumeSlide = VolumeSlide(-(3 / 64f) * effect.y, 1)
        }
      } else if (effect.effect == 12.toByte()) {
        fx.setVolume = SetVolume((1 / 64f) * (effect.x * 16 + effect.y))
      } else if (effect.effect == 13.toByte()) {
        var newEntry = effect.x * 10 + effect.y + 1

        if (newEntry < 63) {
          newEntry++
        }

        fx.patternBreak = PatternBreak(newEntry, true)
      } else if (effect.effect == 14.toByte()) {
        when (effect.x) {
          0xA.toByte() -> {
            // fine vol slide up
            fx.volumeSlide = VolumeSlide((1 / 64f) * effect.y, 1)
          }
          0xB.toByte() -> {
            // fine vol slide down
            fx.volumeSlide = VolumeSlide(-(1 / 64f) * effect.y, 1)
          }
        }
      } else {
        println("MOD effect: ${effect.effect}, ${effect.x}, ${effect.y} not implemented!")
      }
    }

    effects[index] = JSON.stringify(fx)
  }

}

class Track(val song: Song, val instrumentPatterns: InstrumentTrack, val number: Int) {
  var notes = Array(instrumentPatterns.numberOfChannels) {
    Array(song.entriesPerPattern, { Note.NONE })
  }
  var effects = Array(song.entriesPerPattern) {
    Effects()
  }

  fun save() {
    val data = TrackData(song.songId, instrumentPatterns.instrument.number, number, Array(instrumentPatterns.numberOfChannels, { Array(song.entriesPerPattern, { "NONE" }) }), Array(song.entriesPerPattern, { "" }))

    for (x in notes.indices) {
      for (y in notes[x].indices) {
        data.notes[x][y] = notes[x][y].toString()
      }
    }

    for (entry in 0 until song.entriesPerPattern) {
      val fx = effects[entry]
      if (fx is Effects) {
        data.effects[entry] = encodeEffects(fx)
      } else {
        data.effects[entry] = Effects().encode()
      }
    }

    WebsocketConnection.saveTrack(data)
  }

  fun description(): String {
    if (number < 10) {
      return "0" + (number + 1).toString()
    } else {
      return (number + 1).toString()
    }
  }

  fun setChannels(channels: Int) {
    val old = notes
    notes = Array(channels, { Array(song.entriesPerPattern, { Note.NONE }) })

    for (index in 0..old.size - 1) {
      if (index < notes.size) {
        notes[index] = old[index]
      }
    }
  }

  fun equals(trackData: TrackData): Boolean {
    //println("Comparing trackData with track ${number}")

    if (trackData.notes.size == 0) {
      //println("Notes have size 0!")
      return false
    }

    if (trackData.notes[0].size != song.entriesPerPattern) {
      //println("Different amount of entries in pattern!")
      return false
    }

    for (entry in 0..song.entriesPerPattern - 1) {
      val notesToFind = ArrayList<String>()
      for (channel in 0..notes.size - 1) {
        //println("Note to find: ${notes[sample][entry].name}")
        notesToFind.add(notes[channel][entry].name)
      }

      for (trackChannel in 0..trackData.notes.size - 1) {
        if (trackData.notes[trackChannel][entry] != "NONE") {
          if (!notesToFind.contains(trackData.notes[trackChannel][entry])) {
            //println("Not all notes found: ${trackData.notes[trackChannel][entry]}!")
            return false
          } else {
            notesToFind.remove(trackData.notes[trackChannel][entry])
          }
        }
      }

      if (notesToFind.size > 0) {
        for (note in notesToFind) {
          if (note != "NONE") {
            //println("Note remaining: ${note}!")
            return false
          }
        }
      }
    }

    // TODO: Fix for ArrayList<Effect>
/*
        for (entry in 0..song.entriesPerPattern-1) {
            var effect = decodeEffect(trackData.effects[entry])
            if (effects[entry].toString() != effect.toString()) {
                println("Effect ${effects[entry]} != ${effect}")
                return false
            }
        }
*/

    return true
  }

  fun updateEntriesPerPattern(epp: Int) {
    for (channel in 0..notes.size - 1) {
      if (notes[channel].size < epp) {
        val newNotes = Array(epp, { Note.NONE })

        for (entry in 0..notes[channel].size - 1) {
          newNotes[entry] = notes[channel][entry]
        }

        notes[channel] = newNotes
      }
    }
    if (effects.size < epp) {
      val newEffects = Array(epp, { Effects() })

      for (entry in 0..effects.size - 1) {
        newEffects[entry] = effects[entry]
      }

      effects = newEffects
    }
  }

  fun hasNotes(): Boolean {
    for (noteArray in notes) {
      for (note in noteArray) {
        if (note != Note.NONE && note != Note.END && note != Note.UP) {
          return true
        }
      }
    }

    return false
  }
}
