package daw.processor

import daw.AudioHandler
import daw.Daw

class LowPassProcessor(
    val frequencyInput: () -> Float
) : PostProcessor {
  var samples: Int = 0
  var lastYKLeft: Float = 0f
  var lastYKRight: Float = 0f

  override fun process(left: FloatArray, right: FloatArray, count: Int) {
    val deltaT = 1f / AudioHandler.sampleRate.toFloat()
    val tau = 1f / frequencyInput()
    val alpha = deltaT / tau
    var ykLeft = lastYKLeft
    var ykRight = lastYKRight

    for (index in 0 until count) {
      ykLeft += (left[index] - ykLeft) * alpha
      ykRight += (right[index] - ykRight) * alpha

      left[index] = ykLeft
      right[index] = ykRight
    }

    lastYKLeft = ykLeft
    lastYKRight = ykRight
  }

}
