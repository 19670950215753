package daw.component

import daw.html.formatFloat
import kotlinx.html.div
import kotlinx.html.id
import kotlinx.html.input
import kotlinx.html.js.onBlurFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onInputFunction
import kotlinx.html.js.onKeyUpFunction
import kotlinx.html.span
import nl.astraeus.komp.KompConsumer
import nl.astraeus.komp.Komponent
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.EventTarget

/**
 * User: rnentjes
 * Date: 25-3-17
 * Time: 16:44
 */

class NumberInput(val id: String,
                  val name: String = id,
                  var value: Int = 0,
                  var min: Int = 0,
                  var max: Int = 10,
                  var step: Int = 1,
                  var width: Int = 40,
                  var readOnly: Boolean = false,
                  var changeHandler: (Int) -> Unit = {},
                  var valueMapper: (Int) -> String = { "$it" }
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "spin") {

    fun onchange(target: EventTarget?) {
      if (target is HTMLInputElement) {
        if (target.value.toInt() != value) {
          value = target.value.toInt()

          update()
          changeHandler(value)
        }
      } else {
        throw IllegalStateException("Wrong type! $target")
      }
    }

    span {
      +"-"
      onClickFunction = {
        if (value > min) {
          value -= step
        }

        update()
        changeHandler(value)
      }
    }
    input {
      id = this@NumberInput.id
      name = this@NumberInput.name
      value = valueMapper(this@NumberInput.value)
      readonly = readOnly

      onInputFunction = { onchange(it.target) }
      onBlurFunction = { onchange(it.target) }
      onKeyUpFunction = { onchange(it.target) }
    }
    span {
      +"+"
      onClickFunction = {
        if (value < max.toDouble()) {
          value += step
        }

        update()
        changeHandler(value)
      }
    }
  }
}

class FractionInput(val id: String,
                  val name: String = id,
                  var value: Float = 0f,
                    var decimals: Int = 2,
                  var min: Float = 0f,
                  var max: Float = 10f,
                  var step: Float = 1f,
                  var width: Int = 40,
                  var readOnly: Boolean = false,
                  var changeHandler: (Float) -> Unit = {},
                  var valueMapper: (Float) -> String = { formatFloat(it, decimals) }
) : Komponent() {

  override fun render(consumer: KompConsumer) = consumer.div(classes = "spin") {

    fun onchange(target: EventTarget?) {
      if (target is HTMLInputElement) {
        if (target.value.toFloat() != value) {
          value = target.value.toFloat()

          update()
          changeHandler(value)
        }
      } else {
        throw IllegalStateException("Wrong type! $target")
      }
    }

    span {
      +"-"
      onClickFunction = {
        if (value > min) {
          value -= step
        }

        update()
        changeHandler(value)
      }
    }
    input {
      id = this@FractionInput.id
      name = this@FractionInput.name
      value = valueMapper(this@FractionInput.value)
      readonly = readOnly

      onInputFunction = { onchange(it.target) }
      onBlurFunction = { onchange(it.target) }
      onKeyUpFunction = { onchange(it.target) }
    }
    span {
      +"+"
      onClickFunction = {
        if (value < max.toDouble()) {
          value += step
        }

        update()
        changeHandler(value)
      }
    }
  }
}
