package daw.input

import daw.html.attr
import daw.html.elem
import daw.ws.WebsocketConnection
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.WheelEvent

/**
 * User: rnentjes
 * Date: 27-12-15
 * Time: 12:28
 */

object SongBox : InputHandler {
  override fun mouseWheel(event: WheelEvent) {
  }

  override fun focus() {
  }

  override fun unfocus() {
  }

  override fun keyDown(key: Event) {
  }

  override fun keyUp(key: Event) {
  }

  fun octaveUp() {
    if (TrackListInput.octave < 8) {
      TrackListInput.octave++
      TrackListInput.updateKeyMap()

      val songOctave = elem("song_octave") as HTMLInputElement
      songOctave.attr("value", "${TrackListInput.octave}")

      WebsocketConnection.setSongIsDirty()
    }
  }

  fun octaveDown() {
    if (TrackListInput.octave > 0) {
      TrackListInput.octave--
      TrackListInput.updateKeyMap()

      val songOctave = elem("song_octave") as HTMLInputElement
      songOctave.attr("value", "${TrackListInput.octave}")

      WebsocketConnection.setSongIsDirty()
    }
  }

}
