package daw.html

import org.w3c.dom.Element
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.Event
import kotlin.browser.document
import kotlin.dom.addClass

/**
 * User: rnentjes
 * Date: 14-11-15
 * Time: 12:37
 */

fun getCookie(name: String): String {
  val nameEq = "$name="
  val documentCookie = document.cookie

  var result = ""
  var match = 0
  var matched = false

  //println("Get cookie: $name from $documentCookie")
  for (index in 0 until documentCookie.length) {
    if (documentCookie[index] == nameEq[match]) {
      match++
    } else {
      match = 0
    }

    //println("matching [${documentCookie[index]}] match $match" )

    if (matched) {
      if (documentCookie[index] == ';') {
        //matched = false
        //println("Get cookie: $name found $result")
        break;
      } else {
        result = result + documentCookie[index]
      }
    }

    if (match == nameEq.length) {
      matched = true
    }
  }

  //println("getCookie $name=$result")
  return result
}

fun parseParameters(search: String): Map<String, String> {
  val result = HashMap<String, String>();

  var parsingName = true;
  var current = StringBuilder();
  var name: String = ""

  for (index in 0..search.length - 1) {
    when (search[index]) {
      '?' -> {
        // skip
      }
      '&' -> {
        if (parsingName) {
          throw IllegalStateException("Wasn't expecting '&' at $index -> '$search'")
        }

        //println("Parameters in '$search' => $name=$current")
        result.put(name, current.toString())
        current = StringBuilder()
        parsingName = true
      }
      '=' -> {
        if (!parsingName) {
          throw IllegalStateException("Wasn't expecting '=' at $index -> '$search'")
        }

        name = current.toString()
        current = StringBuilder()
        parsingName = false
      }
      else -> {
        current.append(search[index])
      }
    }
  }

  if (!parsingName) {
    //println("Parameters in '$search' => $name, $current")
    result.put(name, current.toString())
  } else {
    //println("whut?")
  }

  return result
}

fun setCookie(name: String, value: String, days: Int = 365) {
  //println("Set cookie $name=$value")
  document.cookie = "$name=$value; expires=Fri, 31-Dec-9999 23:59:59 GMT"
}

fun main() = elem("main")

fun create(tag: String) = document.createElement(tag)

fun clear(elemId: String) {
  val outerDiv = elem(elemId)

  while (outerDiv.children.length > 0) {
    outerDiv.removeChild(outerDiv.firstChild!!)
  }
}

fun hasElem(id: String) = document.getElementById(id) != null
fun elem(id: String) = (document.getElementById(id) ?: throw IllegalArgumentException("Element with id: '$id' not found!")) as HTMLElement

fun html(html: String): Element {
  val result = create("div")

  result.innerHTML = html

  return result.firstElementChild ?: throw IllegalStateException("Child element not found!")
}

fun span(): Element = create("span")
fun button(): Element = create("button").attr("type", "button").cls("button") as HTMLButtonElement
fun br(): Element = create("br")
fun h4(txt: String): Element = create("h4").txt(txt)


/*
    table(
      row(
        cell(
          label(),
          input()
        ).cell().cell()
      ),
      row()
    )
 */
fun children(child: Element, vararg children: Element): Element {
  for (ch in children) {
    child.with(ch)
  }

  return child
}

fun Element.children(child: Element, vararg children: Element): Element {
  for (ch in children) {
    child.with(ch)
  }

  this.with(child)
  return this
}

fun i(vararg children: Element): HTMLElement = children(create("i"), *children) as HTMLElement
fun div(vararg children: Element): HTMLDivElement = children(create("div"), *children) as HTMLDivElement
fun table(vararg children: Element): Element = children(div().attr("class", "table"), *children)
fun row(vararg children: Element): Element = children(div().attr("class", "row"), *children)
fun trow(vararg children: Element): Element = children(div().attr("class", "trow"), *children)
fun header(vararg children: Element): Element = children(div().attr("class", "cell info"), *children)
fun cell(vararg children: Element): HTMLDivElement = children(div().attr("class", "cell"), *children) as HTMLDivElement
fun topcell(vararg children: Element): Element = children(div().attr("class", "topcell"), *children)
fun bottomcell(vararg children: Element): Element = children(div().attr("class", "bottomcell"), *children)
fun h1(txt: String): Element = create("h1").txt(txt)

fun Element.table(vararg children: Element): Element = this.children(div().attr("class", "table"), *children)
fun Element.row(vararg children: Element): Element = this.children(div().attr("class", "row"), *children)
fun Element.cell(vararg children: Element): Element = this.children(div().attr("class", "cell"), *children)
fun Element.div(vararg children: Element): Element = this.children(div(), *children)

fun Element.header(txt: String): Element = this.with(div().attr("class", "cell info").txt(txt))

fun Element.attr(name: String, value: String): Element {
  this.setAttribute(name, value)

  return this
}

fun Element.with(elem: Element): Element {
  this.appendChild(elem)

  return this
}

fun Element.id(id: String) = this.attr("id", id)
fun Element.name(name: String) = this.attr("name", name)
fun Element.value(name: String) = this.attr("value", name)

fun Element.cls(cls: String): Element {
  this.addClass(cls)

  return this
}

fun Element.html(txt: String): Element {
  this.innerHTML = txt

  return this
}

fun Element.txt(txt: String): Element {
  this.textContent = txt

  return this
}

fun Element.tooltip(txt: String): Element {
  this.attr("title", txt)

  return this
}

fun label(id: String, text: String): HTMLLabelElement {
  val label = create("label")

  label.attr("for", id)
  label.txt(text)

  return label as HTMLLabelElement
}

fun input(id: String) = create("input").id(id) as HTMLInputElement

fun textinput(id: String, value: String): HTMLInputElement {
  val input = input(id)

  input.attr("type", "text")
  input.attr("id", id)
  input.attr("name", id)
  input.attr("value", value)

  return input
}

fun colorInput(id: String, value: String, action: (Event) -> Unit): Element {
  val input = input(id)

  input.attr("type", "color")
  input.attr("id", id)
  input.attr("name", id)
  input.attr("value", value)
  input.attr("style", "padding: 0; margin: 2; height: 20px; width: 30px;")

  input.onchange = action

  return input
}

class Select(var key: String, var value: String)

fun select(name: String, options: Array<Select>, selected: String): HTMLSelectElement {
  val select = create("select") as HTMLSelectElement

  select.attr("name", name)
  select.attr("id", name)

  for (opt in options) {
    val option = create("option")

    option.attr("value", opt.key)
    option.innerHTML = opt.value

    if (opt.key == selected) {
      option.attr("selected", "selected")
    }

    select.with(option)
  }

  return select
}
